import {FontAwesomeIcon as FaIcon} from "@fortawesome/react-fontawesome";
import EntityId from "../../Table/EntityId";
import {Badge} from 'reactstrap';
import {Utils} from "@thedmsgroup/mastodon-ui-components";
import React from "react";

type SourceOptionType = {
  value:number|string,
  label:string,
  status?:string,
  product?:string,
  vertical?: {
    id?:number;
    name:string;
    display_name:string;
  }
}

/*
 * A function that can be provided as react-select's formatOptionLabel function
 * on a source selector, to show more source info than just the name.
 *
 * Value is assumed to be the source id, and label == name
 * Status has to be programmatically added to the option values,
 * if you want to show it
 */
//TODO: not sure how to get meta type from 'react-select'. Object that has a 'context' string
export const SourceOptionLabel = (
  option:SourceOptionType, meta:any,  selected:boolean
) => (

  <div className="d-flex justify-content-between">
    <div className="d-flex">
      {meta.context === 'menu' && <div>{selected && <FaIcon icon="check" size="sm" className="me-1" color="green"/>}</div>}
      <div><EntityId id={option.value} className="me-1"/></div>
      <div>
        <div>{option.label}</div>
        {option.vertical && option.product && meta.context === 'menu' && <div>
          <small>{Utils.titleCase(option.product)} |  {option.vertical.display_name}</small>
        </div>}
      </div>
    </div>

      <div>
        {/* Just indicating paused or archived (active assumed) */}
        {option.status && option.status !== 'active' &&
          <Badge size="xs" className="ms-1" color={option.status === 'archived' ? 'danger' : 'warning'}>{Utils.titleCase(option.status)}</Badge>}
      </div>

  </div>
);

