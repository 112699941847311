import React, {FC, useContext} from 'react'
import {Button} from "reactstrap"
import {FontAwesomeIcon as FaIcon} from "@fortawesome/react-fontawesome";
import classnames from "classnames"
import {RoutingFlowContext} from "./index";


type FlowHeaderNodeProps = {
  data: {
    title:string,
    helpText?:string,
    active?:boolean,
    entity?:string,
    allowAdd?:boolean,
    filters?:string[],
    flowView?:string,
  },
  onAdd?:(entity:string)=>void
  onClearFilters?:(flowView:string, id:number) => void;
}

export const FlowHeaderNode:FC<FlowHeaderNodeProps> = ({data, onAdd, onClearFilters}) => {
  const context = useContext(RoutingFlowContext)
  const {
    title,
    helpText,
    filters,
    active,
    entity,
    flowView,
    allowAdd
  } = data

  const handleClearFilters = (e: any) => {
    e.stopPropagation()
    if (onClearFilters && flowView === 'vendor' && context.selectedVendor) {
      const vendor = context.selectedVendor as any;
      onClearFilters(flowView, vendor.id as number)
    }

  }

  return (
    <>
      <div className={classnames("node flow-header", {active})}>
        <div className="d-flex align-items-center justify-content-between">
          <div className="title">{title}</div>
          {allowAdd && entity && onAdd && (
            <Button
              className="inline"
              size="xxs"
              outline
              onClick={()=>onAdd(entity)}
            >
              <FaIcon icon="plus" size="sm" />
            </Button>
          )}
        </div>


      </div>
      {filters && filters.length > 0 && (
        <div className="applied-filters d-flex justify-content-between align-items-center mt-1">
          <div>
            <FaIcon icon="filter" size="1x" /> <b>FILTERS:</b> {filters.join(', ')}
          </div>

          <div><Button size="xs" onClick={handleClearFilters} color="link" className="inline">
            <FaIcon icon="times" size="sm" />
          </Button> </div>
        </div>
      )}
    </>

  )
}
