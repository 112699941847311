import React, { useMemo } from 'react';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import FlexTable from '../../../../Components/Table/FlexTable';
import { titleCaseWord } from '../../../../utils/string';
import useTableGlobalFuzzyFilter from '../../../../Hooks/useTableGlobalFuzzyFilter';
import { DateFormatter, AnyCell } from '../../../../Components/Table/Table';

const AttributesTable = ({ data, onSelect, loading }) => {
  const globalFilterType = useTableGlobalFuzzyFilter(['original.name', 'original.alias']);

  const initialTableState = useMemo(() => ({
    gridLayout: { columnWidths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'] },
    sortBy: [{ id: 'name', desc: false }],
  }), []);

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Alias',
        accessor: 'alias',
      },
      {
        Header: 'Vertical',
        //Because the filters work by vertical.id, we have to match up this column id to vertical.id,
        //instead of using accessor
        //accessor: 'vertical.display_name',
        id: 'vertical.id',
        disableGlobalFilter: true,
        Cell: ({row}) => row.original.vertical ? row.original.vertical.display_name : <AnyCell />,
        filter: (rows, colIds, filter) => {
          if (filter.length) {
            // vertical may be null!
            return rows.filter((r) => filter.includes(r.original.vertical?.id ? r.original.vertical.id.toString() : ""  ));
          }
          return rows;
        },
      },
      {
        Header: 'Product',
        accessor: 'product',
        disableGlobalFilter: true,
        Cell: (c) => c.value ? titleCaseWord(c.value) : <AnyCell />,
        defaultCanSort: true,
      },
      {
        Header: 'Input Type',
        accessor: 'input_type',
        disableGlobalFilter: true,
        Cell: ({ value }) => titleCaseWord(value),
        defaultCanSort: true,
      },
      {
        Header: 'Group',
        accessor: 'group.name',
        disableGlobalFilter: true,
        filter: (rows, colIds, filter) => {
          if (filter.length) {
            return rows.filter((r) => filter.includes(r.original.group.id));
          }
          return rows;
        },
      },
      {
        Header: 'Modified',
        id: 'modified_at',
        accessor: 'modified_at',
        defaultCanSort: true,
        maxWidth: 200,
        Cell: ({ value }) => <DateFormatter value={value} format="yyyy-MM-d h:mm a" />,
      },
      {
        maxWidth: 50,
        id: 'actions',
        disableGlobalFilter: true,
        disableSortBy: true,
        Cell: ({ row }) => <ActionCell attribute={row.original} onEditClick={onSelect} />,
      },
    ],
    [],
  );

  return (
    <FlexTable
      columns={columns}
      initialState={initialTableState}
      className="users-table striped"
      data={data}
      loading={loading}
      noDataMessage="No attributes were found"
      filterTypes={globalFilterType}
      globalFilter="globalType"
    />
  );
};

const ActionCell = ({ attribute, onEditClick }) => (
  <div className="list-actions">
    <Button color="link" className="action-item inline" title="Edit" onClick={() => onEditClick(attribute)}>
      <FaIcon icon="pencil-alt" size="1x" />
    </Button>
    {/* <span className="action-item">
            <Button size="sm" color="transparent"  onClick={()=>onPermissionsClick(user)} >
              <FaIcon icon="lock" size="sm"/>Permissions
            </Button>
          </span> */}

  </div>
);

AttributesTable.propTypes = {
  data: PropTypes.array,
  allowEdit: PropTypes.bool,
  loading: PropTypes.bool,
  handleEditClick: PropTypes.func,
  handlePermissionsClick: PropTypes.func,
};

export default AttributesTable;
