import {createElement} from "react";

/*
 * Adds additional properties to an existing component.
 * const MyNewComponent = withProps(ExistingComponent, {someProp})
 */
export const withProps = (WrappedComponent, addedProps = {}) =>
  (props) =>
    createElement(WrappedComponent, {
      ...props,
      ...addedProps
    });
