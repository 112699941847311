import React, {FC, useMemo} from 'react';
import {Button} from "reactstrap";
import FlexTable from "../../../Components/Table/FlexTable";
import {PercentFormatter, MoneyFormatter} from "../../../Components/Table/Table";
import {Utils} from "@thedmsgroup/mastodon-ui-components";

type RuleCapStat = {
  rule_id: string;
  order_id: string;
  timezone: string;
  type: string;
  start_date: string;
  budget: string;
  budget_used: string;
  fill_rate: string;
  order_name: string;
  rule_path: string;
};

type CapStatsProps = {
  stats: RuleCapStat[],
  onSelectRule: (rule_id: number|string) => void,
  isLoading?: boolean,
};

export const CapStats:FC<CapStatsProps> = ({stats, onSelectRule, isLoading}) => {
  const columns = useMemo(() => [
    {
      Header: 'Rule',
      accessor:'rule_path',
      disableSortBy:true,
      Cell:({value, row}:any) => <RulePathCell rulePath={value} ruleId={row.original.rule_id} onSelect={onSelectRule} />
    },
    {
      Header: 'Type',
      accessor:'type',
      Cell: ({ value }:any) => Utils.titleCase(value)
    },
    {
      Header: 'Budget',
      accessor:'budget',
      Cell: ({ value }:any) => <MoneyFormatter value={value} />
    },
    {
      Header: 'Budget Used',
      accessor:'budget_used',
      Cell: ({ value }:any) => <MoneyFormatter value={value} />
    },
    {
      Header: 'Fill Rate',
      accessor:'fill_rate',
      Cell: ({ value }:any) => <PercentFormatter value={value} />
    }
  ], [])
    return (
        <div>
          <FlexTable
            columns={columns}
            //initialState={initialTableState}
            className="rule-cap-stats"
            data={stats}
            noDataMessage="No cap usage recorded"
            loading={isLoading}
          />
        </div>
    )
}

type RulePathProps = {
  rulePath: string;
  onSelect: (rule_id: number) => void;
  ruleId: number;
};

const RulePathCell: React.FC<RulePathProps> = ({ rulePath, ruleId, onSelect }) => {
  const segments = rulePath.split(' > ');

  return (
    <div>
      {segments.length > 1 ? (
        <>
          {segments.slice(0, -1).join(' > ')}{' > '}
          <Button color="link" className="inline" onClick={() => onSelect(ruleId)}>
            {segments[segments.length - 1]}
          </Button>
        </>
      ) : (
        <button color="link" className="inline" onClick={() => onSelect(ruleId)}>
          {rulePath}
        </button>
      )}
    </div>
  );
};
