import React, {
  useContext, useState, useEffect, useRef,
} from 'react';
import {
  Button,
  Form, FormFeedback,
  FormGroup, Input,
  Spinner,
} from 'reactstrap';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import { useDebouncedCallback } from 'use-debounce';
import PropTypes from 'prop-types';
import RulesContext from '../../../../Providers/RulesContext';
import usePermission from "../../../../Hooks/usePermission";

/*
 * Header area at top of rule config section, has rule name and operations like copy & move
 */
const Header = ({ rule, onChange, errors, isUpdating }) => {
  const rulesContext = useContext(RulesContext)
  const [label, setLabel] = useState(rule.label)
  const [isLabelUpdating, setIsLabelUpdating] = useState(false)
  const [labelEditMode, setLabelEditMode] = useState(!!rule.isNew)
  const labelInputRef = useRef(null)
  const allowEdit = usePermission('orders.edit')

  const handleUpdateLabelClick = async () => {
    setIsLabelUpdating(true)
    // await updateLabel(label); //updates label and saves rule independent of other rule values
    const result = await rulesContext.api.updateRule({ label }); // updates label and saves rule independent of other rule values
    if (result) {
      onChange('label', label); // update form state
      rulesContext.api.refreshRules()
      setIsLabelUpdating(false)
      setLabelEditMode(false)
    }
  };

  const handleChangeLabel = useDebouncedCallback((value) => {
    onChange('label', value);
  }, 400);

  // Focus on label input when in label edit mode
  useEffect(() => {
    if (labelEditMode === true && labelInputRef.current) {
      labelInputRef.current.select()
    }
  }, [labelEditMode]);

  // For when we use the paste function to paste settings into a new rule, update label
  useEffect(() => {
    if (rule.isNew && labelInputRef.current) {
      labelInputRef.current.value = rule.label
    }
  }, [rule.label])

  if (!rule) return (null);

  return (
    <div className="rule-header">
      {labelEditMode === false && (
        <div className="rule-name">
          <h5>
            <span className="card-title">RULE:</span>
            {' '}
            <span className="subtitle">
              {rule.label}
              {' '}
            </span>

            {allowEdit && (
              <Button
                color="link"
                className="inline ms-1"
                onClick={() => setLabelEditMode(true)}
                size="sm"
                title="Edit rule label"
                disabled={isUpdating}
              >
                <FaIcon icon="pencil-alt" size="sm" />
              </Button>
            )}

          </h5>
        </div>
      )}

      {/* If rule is new, show validated name field */}
      {rule.isNew === true && (
        <div className="rule-name label-edit">
          <FormGroup inline>
            {/* <Label>Rule Name <Required/> </Label> */}

            <Input
              type="text"
              name="label"
              size="25"
              defaultValue={rule.label}
              onChange={(e) => handleChangeLabel(e.target.value)}
              innerRef={labelInputRef}
              invalid={!!errors.label}
            />
            <FormFeedback>{errors.label}</FormFeedback>

          </FormGroup>

        </div>
      )}

      {/* Synchronous editing of name (saves the new name on clicking check button, independent of other edits). Toolbar is hidden. */}
      {labelEditMode === true && allowEdit && !rule.isNew && (
        <div className="rule-name label-edit mb-2">
          <Form >
            <div className="d-flex align-items-center">
              <Input type="text" className="w-25" value={label} onChange={(e) => setLabel(e.target.value)} innerRef={labelInputRef} />
              {isLabelUpdating === false ? (
                <div>
                  <Button
                    color="primary"
                    size="xs"
                    className="ms-1"
                    disabled={label.trim().length === 0}
                    onClick={handleUpdateLabelClick}
                  >
                    <FaIcon icon="check" size="sm" />
                  </Button>
                  <Button color="secondary" size="xs" outline className="ms-1" onClick={() => setLabelEditMode(false)}>
                    <FaIcon icon="times" size="sm" />
                  </Button>
                </div>
              ) : (
                <div><Spinner size="sm" color="secondary" className="ms-2" /></div>
              )}
            </div>


          </Form>

        </div>
      )}

      {allowEdit && (
        <Button
          color="link"
          size="sm"
          onClick={rulesContext.api.toggleActionsPane}
          title="Rule Actions"
          className="py-0"
          disabled={isUpdating}
        >
          <FaIcon icon="ellipsis-v" />
        </Button>
      )}

    </div>
  );
};

Header.propTypes = {
  rule: PropTypes.object,
  onChange: PropTypes.func,
  isUpdating: PropTypes.bool,
  errors: PropTypes.object,
};

Header.defaultPros = {
  errors: {},
};

export default Header;
