import React from 'react';
import classnames from "classnames";
import {Button} from "reactstrap";
import {FontAwesomeIcon as FaIcon} from "@fortawesome/react-fontawesome";
import {RelativeTime, Utils} from '@thedmsgroup/mastodon-ui-components';
import EntityId from "../../../Components/Table/EntityId";
import { Handle } from '@xyflow/react';
import {DataRow, DataRows} from "../../../Components";
import {StatusBadge} from "./StatusBadge";
import type {FlowNodeProps} from "./index";


type SourceNodeProps = FlowNodeProps & {
  onEdit:(source:any) =>void,
  onExamine:(sourceId:number) =>void,
  onOpenRules:(source:any) =>void,
}


export const SourceNode = (
  {
    //Passed by react-flow
    targetPosition,
    sourcePosition,
    data,
    //Additional properties
    onEdit,
    onExamine,
    onOpenRules,

  }:SourceNodeProps
) => {
  const {source, dataView, flowView} = data;

  const handleEdit = (e: any) => {
    e.stopPropagation();
    onEdit(source);
  }

  const handleExamine = (e: any) => {
    e.stopPropagation();
    onExamine(source.id);
  }

  const handleOpenRules = (e: any) => {
    e.stopPropagation();
    onOpenRules(source);
  }

  const  handleGotoAuction = (e: any) => {
    e.stopPropagation()
    window.open(`#/auctions?page=1&source=${source.id}`, '_blank')
  }

  return (

      <div className={classnames("node source", source.status, {'route-focus':flowView === 'source'})} >
        <StatusBadge status={source.status} />
        <Handle id="target-handle" type="target" position={targetPosition} />
        <Handle id="source-handle" type="source" position={sourcePosition} />
        <div className="node-header">
          <div className="icon-col">
            <EntityId id={source.id} title="Source ID" className="me-1"/>
          </div>
          <div className="node-title flex-fill">
              <div>
                  {source.name}
              </div>
              <div className="actions">
                <Button color="link" size="sm" onClick={handleExamine} title="Examine source routes" disabled={flowView === 'source'}>
                  <FaIcon icon="eye" size="sm"/>
                </Button>
                <Button color="link" size="sm" onClick={handleEdit} title="Edit source">
                  <FaIcon icon="cog" size="sm"/>
                </Button>
                <Button color="link" size="sm" onClick={handleOpenRules} title="View/Edit routing rule">
                  <FaIcon icon="clipboard-list" size="sm"/>
                </Button>
                <Button
                  color="link"
                  size="sm"
                  title="View auctions"
                  onClick={handleGotoAuction}
                >
                  <FaIcon icon="comment-dollar" size="sm"/>
                </Button>
              </div>
            </div>

          </div>

        {/* If mapper is focused on one source, show product/vertical */}
        {flowView === 'source' && (
          <div className="node-detail d-flex">

              <div className="icon-col">
                <FaIcon icon="info-circle" size="sm"/>
              </div>
              <DataRows labelWidth="60px" noDivider gap="0.1em" className="flex-fill">
                <DataRow label="Product">{Utils.titleCase(source.product)}</DataRow>
                <DataRow label="Vertical">{Utils.titleCase(source.vertical.display_name)}</DataRow>
              </DataRows>

          </div>
        )}

          {dataView.includes("sales") && (
            <div className="node-detail d-flex">
                <div className="icon-col">
                  <FaIcon icon="calendar" size="sm"/>
                </div>
                <div className="flex-fill">
                  <div>
                    <label>Match:</label> <RelativeTime date={source.last_matched_at} uniqueId={`src-${source.id}-match`} showTimezone={true} />
                  </div>
                  <div>
                    <label>Sale:</label> <RelativeTime date={source.last_sold_at} uniqueId={`src-${source.id}-sale`} showTimezone={true} />
                  </div>
                </div>

            </div>
          )}


      </div>


  )
}

