import React, {useEffect, useState} from "react";
import {
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from 'reactstrap';
import {FilterControls, StackedFilter, TextSearch} from "../../../Components/Table/Filter";
import {FontAwesomeIcon as FaIcon} from "@fortawesome/react-fontawesome";
import { StatusChoices} from "../../../Components/Table/FilterChoices";
import FilterSummaryTags from "../../../Components/Table/FilterSummaryTags";
import {useFilters} from "../../../Hooks/useFilters";
import useApi from "../../../Hooks/useApi";
import classnames from "classnames";

/*
 * Filtering for SourceTokens list
 * This list is displayed stand-alone as well as in modals that open from the
 * sources and vendors list.
 * When in modals, we hide the source or vendor filter depending on which page,
 * because the modal is detail for only one vendor or one source
 */
export const Filters = ({
                          filters,
                          onChange,
                          onRefresh,
                          loading = false,
                          showVendors = true,
                          showSources = true,
                 }) => {
  const {api} = useApi();
  const [choices, setChoices] = useState({});
  const {onChangeFilter, reset, clearFilter, clearSearch} = useFilters(filters, onChange)

  // load choices
  useEffect(() => {
    const getChoices = async () => {

      const vendors = await api.endpoints.vendors.list();
      const sources = await api.endpoints.sources.list({options:true});
      if (vendors && sources) {
        setChoices({
          vendor: vendors.map((item) => ({ value: item.id, label: item.name })),
          source: sources.map((item) => ({ value: item.id, label: item.name })),
          status: StatusChoices,
        });
      }
    };

    if (Object.keys(choices).length === 0) getChoices();
  }, []);


  return (
    <>


    <div className="position-relative">
      <div className={classnames(['loading-overlay', { show: loading }])} />

      <div className="d-flex align-items-center">
        <div className="d-flex">
          <div className="boxed-filter new-boxed-filter search-filter me-2">
            <TextSearch
              value={filters.search}
              placeholder={'Search...'}
              onUpdate={(val) => onChangeFilter("search", val)}
              onClear={clearSearch}
              autofocus
            />
          </div>

          <UncontrolledDropdown  className="simple-filters-dropdown me-2">

            <DropdownToggle  caret className="boxed-filter new-boxed-filter filter-group-dropdown">
              <FaIcon icon="filter" size="1x" className="fa-icons" />
            </DropdownToggle>

            <DropdownMenu className="select-filters-dropdown animate dropDown">
              {showSources && (
                <StackedFilter
                  label="Source"
                  placeholder="Any Source"
                  param="source"
                  options={choices.source ?? []}
                  value={filters.source || ''}
                  onChange={onChangeFilter}
                  isMulti={false}
                />
              )}

              {showVendors && (
                <StackedFilter
                  label="Vendor"
                  placeholder="Any Vendor"
                  param="vendor"
                  options={choices.vendor ?? []}
                  value={filters.vendor || ''}
                  onChange={onChangeFilter}
                  isMulti={false}
                />
              )}


              <StackedFilter
                placeholder="Active/Paused"
                label="Status"
                param="status"
                options={choices.status ?? []}
                value={filters.status ?? []}
                onChange={onChangeFilter}
                isMulti={true}
              />
            </DropdownMenu>

          </UncontrolledDropdown>

          <FilterControls
            onRefresh={onRefresh}
          />

        </div>

      </div>

    </div>

      <FilterSummaryTags
        choices={choices}
        filters={filters}
        filterKeys={['source', 'vendor',  'status']}
        onRemoveFilter={clearFilter}
        onClear={reset}
        isLoading={loading}
        isPendingApply={false}
      />

    </>
  )
}

