import React, {useMemo} from 'react';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import {Utils} from "@thedmsgroup/mastodon-ui-components";
import {Button} from 'reactstrap';
import PropTypes from 'prop-types';


//
// const FilterLabelMap = {
//   account: 'ACCOUNT',
//   accounts: 'ACCOUNT',
//   account_ids:'ACCOUNT',
//   billable: 'BILLABLE',
//   channel: 'CHANNEL',
//   channel_id: 'CHANNEL',
//   change_action: 'CHANGE ACTION',
//   change_group: 'CHANGE TYPE',
//   currentlyInsured: 'INSURED',
//   device: 'DEVICE',
//   disposition: 'DISPOSITION',
//   entity_type: 'OBJECT',
//   order: 'ORDER',
//   orders: 'ORDER',
//   // order_ids: 'ORDER',
//   phase: 'PHASE',
//   product: 'PRODUCT',
//   reject_category:'REJECT CATEGORY',
//   script: 'SCRIPT',
//   sold: 'SOLD',
//   source: 'SOURCE',
//   sources: 'SOURCE',
//   state: 'STATE',
//   status: 'STATUS',
//   tag: 'TAG',
//   vendor: 'VENDOR',
//   vendors: 'VENDOR',
//   vertical: 'VERTICAL',
//   verticals: 'VERTICAL',
//   vertical_id: 'VERTICAL',
//   version: 'API VERSION',
//   integration: 'INTEGRATION',
//   integrations: 'INTEGRATIONS',
//   'vertical.name': 'VERTICAL',
//   'include_buyers': 'INCLUDE_BUYERS',
//   'match_rejection': 'MATCH REJECTION',
//   'sale_rejection': 'SALE REJECTION',
// };

// Map of filter keys to choices keys
const FilterChoiceMap = {
  'vertical.name': 'vertical',
  vertical_id: 'vertical',
  channel_id: 'channel',
  vendor_id: 'vendor',
  source_id: 'source',
  order_id: 'order',
  // order_ids: 'order',
  //account_ids: 'account',
};

/*
 * Present active filters as row of tags with label and value
 * Each tag has a clear button.
 * There is a 'clear all' button and an optional 'apply' button.
 */
const FilterSummaryTags = ({
                             filters,
                             filterKeys = [],
                             normalizedKeys = {},
                             choices,
                             isLoading,
                             fnGetLabel,
                             fnCustomTags,
                             onRemoveFilter,
                             onClear,
                             onApply,
                             isPendingApply
                           }) => {


  // Reduce the filter set: Only make tags for filters named in filterKeys
  const targetFilters = keepKeys(filters, filterKeys)

  if (Object.keys(targetFilters).length === 0 && !fnCustomTags) {
    return null;
  }
  const summarizeFilterSettings = (filters, choices) => {

    let filterSummary = {};
    Object.entries(filters).map(([filter, val]) => {
      if (fnGetLabel && typeof fnGetLabel[filter] === 'function' ) {
        //we can provide getter functions for specific filters
        const label = fnGetLabel[filter](val, choices[filter]);
        filterSummary[filter] = {name:getDisplayFilterName(filter), value:label};
      } else {
        //Get labels by mapping value to choices, or default to the current value
        // Map: param could be vertical.name, but choices prop is 'vertical'
        let name = normalizedKeys[filter];
        if (!name) {
          name = FilterChoiceMap[filter] ?? filter;
        }

        if (choices.hasOwnProperty(name)) {
          const label = getValueLabels(choices[name], val);
          filterSummary[filter] = {name:getDisplayFilterName(name), value:(label||val)};

        } else {
          // todo guard against non-strings
          filterSummary[filter] = {name:getDisplayFilterName(name), value:val};
        }
      }

    })

    if (fnCustomTags && typeof fnCustomTags === 'function') {
      const tags = fnCustomTags();
      if (tags) {
        filterSummary = {...filterSummary, ...tags}
      }

    }

    return filterSummary;
  };

  const getDisplayFilterName = (name) => Utils.tokenToWords(Utils.titleCase(name))

  const getValueLabels = (options, values) => {
    //console.log('ReportsFilter.js:summarize getLabels:', options, values);

    if (values && Array.isArray(options)) {
      // options.filter((opt) => values.includes(opt.value))
      const selected = Array.isArray(values)
        ? options.filter((opt) => values.some(val => val.toString() === opt.value.toString()))
        : options.filter((opt) => opt.value.toString() === values.toString());

      const labels = selected.length ? selected.map((opt) => opt.label) : null;

      //console.log('FilterSummary.js:summarize, getLabels: selected, labels:', selected, labels);
      return labels;
    }
    return null;
  };

  // Keys in the summary object are the filter keys
  const summary = useMemo(() => summarizeFilterSettings(targetFilters, choices),
    [filters, choices]);

  if (!Object.keys(summary).length) return null;

  // clear only the filters that are displayed in the summary
  const handleClear = () => {
    if (onClear) {
      onClear(Object.keys(filters).reduce((acc, key) => {
        if (!filterKeys.includes(key)) {
          acc[key] = filters[key]
        }
        return acc
      }, {}));
    }
  }

  return (
    <div className="filter-summary d-flex">
      {Object.entries(summary).map(([filter, item], i) => (
        <div className="active-filter me-1 d-flex align-items-center" key={i}>
          <div className="filter-value">
            <strong>{item.name}: </strong>
            {Array.isArray(item.value) && item.value.length > 0  ? (
              <span >
              {item.value.join(', ')}
              </span>
            ) : (
              <span >
              {item.value}
              </span>
            )}
          </div>
          <Button color="link" className="" size="sm" onClick={()=>onRemoveFilter(filter)}>
            <FaIcon icon="times" size="sm" />
          </Button>
        </div>
      ))}

      {!isLoading && (
        <>
          {onApply && isPendingApply && (
            <Button color="link" size="sm" className="btn-apply inline ms-1" onClick={onApply}>
              Apply
            </Button>
          )}

          {!isPendingApply && onClear && <Button color="link" size="sm" className="inline ms-1" onClick={handleClear}>
            Clear
          </Button>}
        </>
      )}

    </div>
  );
};

const keepKeys = (obj, keys) => {
  obj = { ... obj }
  Object.keys(obj).map(key => {
    if (!keys.includes(key)) {
      delete obj[key]
    }
  })
  return obj
}

FilterSummaryTags.propTypes = {
  filters: PropTypes.object,
  filterKeys: PropTypes.array,
  choices: PropTypes.object,
  onRemoveFilter:PropTypes.func,
  onClear:PropTypes.func,
  onApply:PropTypes.func,
  fnGetLabel:PropTypes.func,
  fnCustomTags:PropTypes.func,
  isPendingApply: PropTypes.bool,
  isLoading: PropTypes.bool
};

export default FilterSummaryTags;


// unitLabel should be plural if not abbreviated, typically seconds or milliseconds
export const makeDurationTag = (min, max, unitLabel) => {
  if (min || max){
    const tag = {name:'Duration', value:''}
    if (min && max) {
      tag.value = `${min} to ${max} ${unitLabel}`;
    } else if (min && !max) {
      tag.value = `${min} or more ${unitLabel}`;
    } else if (!min && max) {
      tag.value = `Up to ${max} ${unitLabel}`;
    }
    return {duration:tag};
  }
  return null;

}
