import {FontAwesomeIcon as FaIcon} from "@fortawesome/react-fontawesome";
import EntityId from "../../Table/EntityId";
import {Badge} from 'reactstrap';
import {Utils} from "@thedmsgroup/mastodon-ui-components";
import React from "react";

type SourceTokenOptionType = {
  id:number|string,
  label:string,
  token:string,
  status?:string,
  source: {
    id:number;
    name:string;
    product:string;
    vertical: {
      id:number;
      name:string;
      display_name:string;
    }
  }
}

/*
 * A function that can be provided as react-select's formatOptionLabel function
 * on a source-token selector, to show more  info than just the token.
 *
 *
 */

export const SourceTokenOptionLabel = (
  option:SourceTokenOptionType, meta:any,  selected:boolean
) => (

  <div className="d-flex justify-content-between">
    <div className="d-flex">
      {meta.context === 'menu' && <div>{selected && <FaIcon icon="check" size="sm" className="me-1" color="green"/>}</div>}
      <div></div>
      <div>
        <div>{option.label}</div>
        {option.source &&  meta.context === 'menu' && <div>
          <div><EntityId id={option.source.id} className="me-1"/> {option.source.name}</div>
          <div><small>{Utils.titleCase(option.source.product)} |  {option.source.vertical.display_name}</small></div>
          <div><small>{option.token}</small></div>
        </div>}
      </div>
    </div>

      <div>
        {/* Just indicating paused or archived (active assumed) */}
        {option.status && option.status !== 'active' &&
          <Badge size="xs" className="ms-1" color={option.status === 'archived' ? 'danger' : 'warning'}>{Utils.titleCase(option.status)}</Badge>}
      </div>

  </div>
);

