export const IconData = {
  ads: { icon: 'ad', name: 'ads', tab: 'ads', title: 'Ads' },
  bid_blocked: { icon: 'ban', name: 'bid_blocked', tab: 'bidding', title: 'Blocked or zero bid' },
  bid_schedule: { icon: 'clock', name: 'bid_schedule', tab: 'bidding', title: 'Scheduled Bid Modifiers' },
  budget: { icon: 'dollar-sign', name: 'budget', tab: 'budget', title: 'Budget or caps' },
  channel_modifiers: { icon: 'sliders-h', name: 'channel_modifiers', tab: 'channels', title: 'Channel modifiers' },
  datasheets: { icon: 'paperclip', name: 'datasheets', tab: 'datasheets', title: 'Datasheets' },
  destinations: { icon: 'paper-plane', name: 'destinations', tab: 'destinations', title: 'URL or Phone number destination' },
  post_sale: { icon: 'envelope', name: 'post_sale', tab: 'destinations', routing_tab:'integrations',title: 'Post-sale integration' },
  prefill: { icon: 'fill', name: 'prefill', tab: 'destinations', title: 'Prefill integration' },
  match_conditions: { icon: 'bullseye', name: 'match_conditions', tab: 'targeting', title: 'Match Conditions' },
  sale_conditions: { icon: 'cloud-download-alt', name: 'sale_conditions', tab: 'enrichments', routing_tab:'integrations', title: 'Enrichments' },
  sale_confirmation: { icon: 'thumbs-up', name: 'sale_confirmation', tab: 'sale_confirmation', routing_tab:'integrations', title: 'Sale Confirmation' },
  schedule: { icon: 'calendar-times', name: 'schedule', tab: 'activity', title: 'Scheduled pause dates' },
  tags: { icon: 'tags', tab: 'tags', name: 'tags', title: 'Tags' },
  tracking: { icon: 'shoe-prints', name: 'tracking', tab: 'tracking', title: 'Tracking pixels' },
  proxy_bidding: { icon: 'project-diagram', name: 'proxy_bidding', tab: 'bidding', title: 'Proxy Bidding' },
  advanced: { icon: 'cog', tab: 'advanced', name: 'advanced', title: 'Advanced Settings' },
  // routing rules
  sale_match_caps: { icon: 'hand-paper', name: 'sale_match_caps', tab: 'caps', title: 'Sale & Match caps' },
  flow_control: { icon: 'filter', name: 'flow_control', tab: 'bidding', title: 'Flow Control' },
  payout: { icon: 'dollar-sign', name: 'payout', tab: 'bidding', title: 'Payout' },
  margin: { icon: 'percent', name: 'margin', tab: 'bidding', title: 'Margin' },
  sell_strategy: { icon: 'balance-scale', name: 'sell_strategy', tab: 'bidding', title: 'Sell Strategy' },
  match_limit: { icon: 'comment-slash', name: 'match_limit', tab: 'advanced', title: 'Match Limit' },
  match_timeout: { icon: 'hourglass-half', name: 'match_timeout', tab: 'advanced', title: 'Match Timeout' },
};

export const RuleProps = [
  'flow_control',
  'billable_duration',
  'datasheets',
  'dba',
  'disabled',
  'bid_blocked',
  'bid_exclusivity',
  'match_conditions',
  'sale_conditions',
  'sale_confirmation',
  'prefill',
  'destinations',
  'destination_number',
  'ads',
  'budget', // advertiser rules, budget & caps
  'sale_match_caps', // routing rules, sale_caps and match_caps
  'bid_schedule',
  'schedule',
  'channel_modifiers',
  'is_standalone',
  'match_is_required',
  'tracking',
  'tags',
  'proxy_bidding',
  'post_sale',
  'sell_strategy',
  'advanced',
];

// In rule tree this determines which icons may show, in this order.
// Values are same as RuleProps because we check the rule's props array
export const AdvertiserIconSet = [

  'datasheets',
  // 'bid_blocked',
  'match_conditions',
  //'match_is_required',
  'sale_conditions',
  'sale_confirmation',
  'prefill',
  'destinations',
  'post_sale',
  'ads',
  'budget', // budget & caps
  'bid_schedule',
  'schedule',
  'channel_modifiers',
  'tracking',
  'tags',
  'proxy_bidding',
  'advanced',
];

export const RoutingIconSet = [
  'match_conditions',
  'sale_match_caps', // routing rules, sale_caps and match_caps,
  'flow_control',
  'sell_strategy',
  'post_sale',  //post sale integrations
  'sale_conditions',
  'match_limit',
  'match_timeout'
  // TODO: absolute and modifier?
];

// On server side
// does not have 'sale_conditions' (enrichments), 'post_sale'
// absolute and modifier we don't have icons for
// sale & match caps are separate
// no payout or margin  but looks like we did not have logic for this
/*
'flow_control',
'absolute',
'modifier',
'match_conditions',
'sale_caps',
'match_caps',
'sell_strategy',
'match_limit',
'match_timeout',
 */
