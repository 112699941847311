import React, {FC, useEffect, useMemo, useState, useCallback} from 'react'
import ReactDOM from "react-dom"
import RoutingApi from "./RoutingApi"
import useQueryState from "../../../Hooks/useQueryState"
import {useVerticals} from "../../../Hooks/useVerticals";
import {BlockUI} from 'ns-react-block-ui'
import {Button, Spinner} from "reactstrap"
import Filters from "./Filters"
import {SourceNode} from "./SourceNode"
import {EligibilityNode} from "./EligibilityNode"
import {InfoNode} from "./InfoNode"
import {LaneFilterNode} from "./LaneFilterNode"
import {VendorNode} from "./VendorNode"
import ChannelNode from "./ChannelNode"
import {SourceTokenNode} from "./SourceTokenNode"
import {OrderNode} from "./OrderNode"
import {AlertNode} from "./AlertNode"
import {FlowHeaderNode} from "./FlowHeaderNode"
import {withProps} from "../../../Components/WithProps"
import {
  //Background,
  //BackgroundVariant,
  //Controls,
  //MiniMap,
  type Edge,
  type Node,
  Position,
  ReactFlow,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
  useNodesInitialized,
  useReactFlow,
  useStoreApi,
  getConnectedEdges,
  getIncomers,
  getOutgoers,
  isNode, NodeTypes, NodeProps
} from '@xyflow/react'
import type {Channel, SourceToken} from "../../../types"
import {ChannelSourceRoute, Modal as RoutingRulesModal} from "../Rules"
import {SourceModal} from "../../PublisherManager/Sources/Source/SourceModal";
import {LinkChannelSourceModal} from "../../PublisherManager/Sources/Source/LinkChannelSourceModal";
import {SourceTokenModal} from "../../PublisherManager/SourceToken/SourceTokenModal"
import {ChannelModal} from "../../PublisherManager/Channels/Channel/ChannelModal"
import {TokenOverflow} from "./TokenOverflow";
import {default as VendorModal} from "../../PublisherManager/Vendors/Vendor"
import {Utils} from "@thedmsgroup/mastodon-ui-components";
import "./styles.scss"
import '@xyflow/react/dist/style.css'
import {faFileInvoiceDollar} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon as FaIcon} from "@fortawesome/react-fontawesome";



const routingApi = new RoutingApi()
const OriginX = 20
const OriginY = 80
const NodeVerticalGap = 20
const NodeColumnGap = 70
const NodeDefaultWidth = 320

export type FlowNodeProps<NodeDataType=Record<string, any>> = NodeProps & {
  targetPosition:Position,
  sourcePosition:Position,
  data: NodeDataType
}
type RoutingMapPropertiesType = {
  useQuery?: boolean,
  sourceId?: number,
  channelId?: number,
  vendorId?: number,
  orderId?: number,
  accountId?: number,
};

export const RoutingMap:FC<RoutingMapPropertiesType>= (props) => {

  return (
    <ReactFlowProvider>
      <RoutingMapper {...props} />
    </ReactFlowProvider>
  )
}

// A context where app state can be stored and nodes can access it.
// Useful because nodeTypes are memoized and callbacks provided to nodes may not have current state.
// Within the node, import this context, implement the useContext hook.
export const RoutingFlowContext = React.createContext({
  selectedVendor: null,
  selectedSource: null,
  selectedChannel: null,
});

/*
 * Routing Map
 * Show a route map from a selected source, channel, or order.
 */
const RoutingMapper: FC<RoutingMapPropertiesType> = ({
   useQuery = true,
   sourceId,
   channelId,
   vendorId,
   accountId,
   orderId
 }) => {

  // If this component is its own page we will use the query string for state,
  // but if used in a modal on some other page, we use props instead of query string
  const [query, setQuery] = useQuery
    ? useQueryState({}, {parseOptions: {parseNumbers: false}})
    : useState<any>(() => {
      if (sourceId) {
        return {source: sourceId.toString()}
      } else if (channelId) {
        return {channel: channelId.toString()}

      }  else if (vendorId) {
        return {vendor: vendorId.toString()}
      } else if (accountId && orderId) {
        return {account: accountId.toString(), order: orderId.toString()}
      }
    });
  const [filterRefreshKey, setFilterRefreshKey] = useState(999)

  const [loadingOrders, setLoadingOrders] = useState(false)
  const [dataView, setDataView] = useState<string[]>(["sales"])
  const [flowView, setFlowView] = useState(()=> {
    if (query.source){
      return "source"
    }
    if (query.channel) {
      return "channel"
    }
    if (query.vendor) {
      return "vendor"
    }
    return "";
  });
  const [loading, setLoading] = useState(Boolean(flowView))
  const {loaded: verticalsLoaded, getDisplayName:getVerticalDisplayName} = useVerticals()

  //Sources
  const [selectedSource, setSelectedSource] = useState<any | null>() //the source selected by the filters
  const [editSource, setEditSource] = useState<any | null>(null) //the source we are editing
  const [sourcePanelOpen, setSourcePanelOpen] = useState(false)
  const [candidateSources, setCandidateSources] = useState<any[]>([]) // in-view sources that may be applied to a new source token
  const [linkSource, setLinkSource] = useState<any | null>(null) //the source we want to link to a channel
  const [linkChannelSourcePanelOpen, setLinkChannelSourcePanelOpen] = useState(false)

  const [rulesPanelOpen, setRulesPanelOpen] = useState(false)
  const [routingRule, setRoutingRule] = useState<any>(null)

  const [tokenPanelOpen, setTokenPanelOpen] = useState(false)
  const [editToken, setEditToken] = useState<any|null>(null)
  const [candidateVendors, setCandidateVendors] = useState<any[]>([]) // in-view vendors that may be applied to a new source (via token)


  // Channel and Orders
  const [selectedChannel, setSelectedChannel] = useState<any | null>() // The channel selected by the filters
  const [editChannel, setEditChannel] = useState<any | null>(null) //the channel we are editing (details or eligibilities)
  const [channelPanelOpen, setChannelPanelOpen] = useState(false)
  const [channelSourcePanelOpen, setChannelSourcePanelOpen] = useState(false)
  const [selectedOrder, setSelectedOrder] = useState<any | null>()
  const [orderChannel, setOrderChannel] = useState<any | null>(null) //the channel we are showing orders for
  const [channelOrders, setChannelOrders] = useState<any>([]); //orders that use a channel as a channel modifier

  //Vendors
  const [selectedVendor, setSelectedVendor] = useState<any | null>() //the vendor selected by the filters
  const [editVendor, setEditVendor] = useState<any | null>(null)
  const [vendorModalOpen, setVendorModalOpen] = useState(false)
  const [overflowTokens, setOverflowTokens] = useState([]) //for messaging if vendor has too many tokens to show in the flow

  // React-flow states and store
  const [nodes, setNodes, onNodesChange] = useNodesState<Node>([])
  const [edges, setEdges, onEdgesChange] = useEdgesState<Edge>([])
  const nodesInitialized = useNodesInitialized({includeHiddenNodes:true})
  const { setViewport } = useReactFlow()
  const store = useStoreApi()

  const handleChangeFilters = (filters: any) => setQuery(filters || {})

  //Reflow after initialization (when nodes receive a measurement)
  useEffect(() => {
    if (nodesInitialized){
      window.requestAnimationFrame(()=>reflow())
    }
  }, [nodesInitialized])

  // Adjust element position based on content height.
  // React-flow does not automatically adjust the flow based on element size.
  // Elements can change size after render due to content wrapping
  const reflow = () => {

    const {nodes} = store.getState();
    const previousOfType:Record<string, Node|null> = {
      vendorNode:null,
      sourceTokenNode:null,
      sourceNode:null,
      eligibilityNode:null,
      channelNode:null,
      orderNode:null
    };
    //Nodes will be found in the order they were added, with headers added first
    const newNodes= nodes.map((node:Node) => {
      node.hidden=false; // ? some trouble with nodes hiding after reflow
      //some headers may vary in height due to filter display. Add them to 'previous' so next node can be placed correctly.
      if (node.type === 'headerNode') {
        switch(node.id) {
          case 'header-source-token':
            previousOfType['sourceTokenNode'] = node
            break
        }
      }
      if (node.type && typeof previousOfType[node.type] !== 'undefined'  ) {
        const previous = previousOfType[node.type]
        if (previous && previous.measured?.height) {
          node.position.y = previous.position.y +  previous.measured.height + NodeVerticalGap
        }
        previousOfType[node.type] = node

      }
      return {...node}
    })

    setNodes(newNodes)
    //Sometimes edges don't get rendered so force it by renewing node objects
    setEdges((prevEdges) => prevEdges.map((edge) => ({...edge})))
  }


  // Note: on dev we get a ResizeObserver error when switching the view
  // https://github.com/xyflow/xyflow/issues/4792
  // The devs say you can safely ignore it, but it is annoying due to the dev-server error overlay
  // See webpack.dev.js for how we block the overlay for this error specifically
  // Generally the error likely caused by something do a resize while react-flow is resizing, leading to a loop
  // Solutions or error handling: https://stackoverflow.com/questions/76187282/how-to-fix-resizeobserver-loop-completed-with-undelivered-notifications
  // https://stackoverflow.com/questions/75774800/how-to-stop-resizeobserver-loop-limit-exceeded-error-from-appearing-in-react-a  (webpack config)
  const handleSelectDataView = (view:string="") => {
    console.log("index.tsx:select data view", view, selectedOrder );
    const newView = dataView.includes(view) ? dataView.filter((v) => v!== view) : [...dataView, view]
    if (selectedSource) {
      initSourceView(selectedSource, newView)
    } else if (selectedChannel) {
      initChannelView(selectedChannel, channelOrders, newView)
    } else if (selectedVendor) {
      initVendorView(selectedVendor, newView)
    } else if (selectedOrder) {
      initOrderView(selectedOrder, newView)
    }
  }

  const handleGetChannelOrders = (channel: Channel) => {
    if (channel) {
      if (!orderChannel || orderChannel.id !== channel.id) {
        setOrderChannel(channel)
        loadChannelOrders(channel.id)
      }
    }
  }

  //switch to channel view of specific channel node
  const handleExamineChannel = (channelId: number|string) => {
    if ( channelId) {
      setQuery({channel: channelId})
    }
  }

  // switch to source view of specific source node
  const handleExamineSource = (sourceId: string|number) => {
    if ( sourceId) {
      setQuery({source: sourceId})
    }
  }

  const openSourceRules = (s:any) => {
    setRoutingRule({
      // @ts-ignore
      entity: s,
      type: 'source',
    })
    setRulesPanelOpen(true)
  };

  const openChannelRules = (c:any) => {
    setRoutingRule({
      entity: c,
      type: 'channel',
    })
    setRulesPanelOpen(true)
  };

  // switch to vendor view of specific vendor node
  const handleExamineVendor = (vendorId: any) => {
    if ( vendorId) {
      setQuery({vendor: vendorId})
    }
  }

  const openVendorRules = (v:any) => {
    setRoutingRule({
      entity: v,
      type: 'vendor',
    })
    setRulesPanelOpen(true)
  };

  const openVendorModal = (v:any) => {
    setEditVendor(v)
    setVendorModalOpen(true)
  };

  const closeVendorModal = () => {
    setVendorModalOpen(false)
    setEditVendor(null)
  };

  const openChannelSourceRules = (route:ChannelSourceRoute) => {

    if (route) {
      setRoutingRule({
        entity: route,
        type: 'channelsource',
      });
      setRulesPanelOpen(true)
    }

  };

  const openTokenPanel = (t:any) => {
    setEditToken(t)
    setTokenPanelOpen(true)
  };

  const closeTokenPanel = () => {
    setTokenPanelOpen(false)
    setEditToken(null)
    setCandidateSources([])
  };

  const closeRulesPanel = () => {
    setRulesPanelOpen(false)
    setRoutingRule(null)
  }

  const openSourcePanel = (s:any) => {
    setEditSource(s)
    setSourcePanelOpen(true)
  };

  const closeSourcePanel = () => {
    setSourcePanelOpen(false)
    setEditSource(null)
  }

  const openChannelPanel = (c:any) => {
    setEditChannel(c)
    setChannelPanelOpen(true)
  };

  const closeChannelPanel = () => {
    setChannelPanelOpen(false)
    setChannelSourcePanelOpen(false)
    setEditChannel(null)
  }


  const openLinkChannelSourcePanel = (s:any) => {
    setLinkSource(s)
    setLinkChannelSourcePanelOpen(true)
  };

  const closeLinkChannelSourcePanel = () => {
    setLinkChannelSourcePanelOpen(false)
    setLinkSource(null)
  }

  const openChannelSourcePanelFromChannel = (c:any) => {
    setEditChannel(c)
    //same panel as Edit Channel, but sources panel is active
    setChannelSourcePanelOpen(true)
  };

  const handleAdd = useCallback((entityType:string) => {

    if (entityType === 'source-token') {
      // The panel will have to know currently mapped source(s)
      // This would be selectedSource for source view
      // For channel view it would be selectedChannel.sources
      setCandidateSources(() => {
        if (selectedSource) {
          return [selectedSource]
        } else if (selectedChannel) {
          return selectedChannel.sources
        }
        return []
      })
      setEditToken({
        id: 0,
        token: "",
        seed_data: null,
        status: 'active',
        source: {id:0, name:''},
        vendor: {id:0, name:''},
      })
      setTokenPanelOpen(true);

    } else if (entityType === 'channel') {

      setEditChannel({
        id: 0,
        status:'active',
        sources:[]
      })
      setChannelPanelOpen(true)

    } else if (entityType === 'source') {
      setCandidateVendors(() => {
        if (selectedSource) {
          return getUniqueVendorsFromTokens(selectedSource.tokens)
        } else if (selectedChannel) {
          return getUniqueVendorsFromTokens(selectedChannel.sources.reduce((acc:any[], source:any) => [...acc, ...source.tokens], []))
        }
        return []
      })
      setEditSource({
        id: 0,
        status:'active',
      })
      //TODO: candidate vendors
      // channel view: channel has sources => tokens => vendor (id, name, rule_id)
      // Source view: source => tokens => vendor (id, name, rule_id)
      setSourcePanelOpen(true)
    } else if (entityType === 'vendor') {
      setEditVendor({
        id: 0,
      })

      setVendorModalOpen(true)
    }
  }, [selectedChannel, selectedSource, selectedVendor]);


  /*
   * In vendor view, vendor has no source-tokens assigned
   * User can add a token for an existing source, or create a new source
   * See: SourceTokenNode
   */
  const handleAddTokenForCurrentVendor = (vendor:any, newSource?:boolean) => {
    //should only execute during vendor view
    if (vendor) {
      setCandidateVendors([vendor]);
      if (newSource) {
        //Open source form. After creating a new source it will prompt for adding a token for this vendor
        setEditSource({
          id: 0,
          status:'active',
        })
        //TODO: candidate vendors
        // channel view: channel has sources => tokens => vendor (id, name, rule_id)
        // Source view: source => tokens => vendor (id, name, rule_id)
        setSourcePanelOpen(true)
      } else {
        //Open SourceToken form, vendor will be populated
        setEditToken({
          id: 0,
          token: "",
          seed_data: null,
          status: 'active',
          source: {id:0, name:''},
          vendor: {id:vendor.id, name:vendor.name},
        })
        setTokenPanelOpen(true);
      }

    }
  }

  const handleAddTokenForCurrentSource = (source:any) => {
    //should only execute during vendor view
    if (source) {
      //setCandidateVendors([vendor]);
      setEditToken({
        id: 0,
        token: "",
        seed_data: null,
        status: 'active',
        source,
        vendor: {id:0, name:''},
      })
      setTokenPanelOpen(true);
    }
  }

  const handleClearFilters = (flowView:any, id:number) => {
    if (flowView === 'vendor') {
      setQuery({vendor:id})
    }

  }

  const getUniqueVendorsFromTokens= (tokens:SourceToken[]) => {
    const vendors =  tokens.reduce((acc, token) => {
      if (!acc[token.vendor.id]) {
        acc[token.vendor.id] = token.vendor;
      }
      return acc;
    }, {} as Record<number,any>)
    return Object.values(vendors);
  }


  const handleExamineOrder = (order: any) => {
    if ( order ) {
      setQuery({account:order.account.id, order: order.id})
      setSelectedOrder(order);
    }
  }

  const handleSaveSourceSuccess = (source:any, isNew:boolean=false) => {
    closeSourcePanel()
    // focus on new source with requery, else refresh current view
    if (source && isNew) {
      setQuery({vendor:source.id})
      setFilterRefreshKey(filterRefreshKey + 1) //update filter choices
    } else {
      refreshView()
    }
  }
  const handleSaveVendorSuccess = (vendor:any, isNew:boolean=false) => {
    closeVendorModal()
    // focus on new vendor with requery, else refresh current view
    if (vendor && isNew) {
      setQuery({vendor:vendor.id})
      setFilterRefreshKey(filterRefreshKey + 1) //update filter choices
    } else {
      refreshView()
    }
  }

  // CUSTOM NODES
  // Note: callbacks provided to the custom node will NOT always have the current state of this component
  // (the app state will no longer be in scope)
  // https://github.com/xyflow/xyflow/issues/1610
  // The 'useCallback' hook does not fix this.
  // One solution is to use relevant state values as a dependency for this nodeTypes memo.
  // (React-flow does not like this and throws warnings about re-defining nodes, you are not supposed to update nodeTypes)
  // Another solution is pass current state values into the nodes as node data during flow creation (initSourceView, etc)
  // We can also use our RoutingFlowContext values within the nodes, which will have the most recent state.
  const nodeTypes = useMemo(() => (
    {
      //Using withProps to add additional props beyond what react-flow provides to its nodes
      vendorNode: withProps(VendorNode, {
        onOpenRules: openVendorRules,
        onExamine: handleExamineVendor,
        onEdit: openVendorModal
      }),
      sourceTokenNode: withProps(SourceTokenNode, {
        onEdit: openTokenPanel,
        onAddForCurrentVendor: handleAddTokenForCurrentVendor,
        onAddForCurrentSource: handleAddTokenForCurrentSource
      }),
      sourceNode: withProps(SourceNode, {
        onExamine: handleExamineSource,
        onEdit: openSourcePanel,
        onOpenRules: openSourceRules,
      }),
      eligibilityNode: withProps(EligibilityNode, {
        onOpenRules: openChannelSourceRules,
        onLinkSourceToChannel: openLinkChannelSourcePanel,
        onOpenChannelSources: openChannelSourcePanelFromChannel
      }),
      channelNode: withProps(ChannelNode, {
        onGetOrders:handleGetChannelOrders,
        onExamine: handleExamineChannel,
        onEdit: openChannelPanel,
        onOpenRules: openChannelRules,
        onOpenChannelSources: openChannelSourcePanelFromChannel
      }),

      orderNode: withProps(OrderNode, {
        onResize: reflow,
        onExamine: handleExamineOrder,
      }),

      headerNode:withProps(FlowHeaderNode, {
        onAdd: handleAdd, //for any entity, where allowed (see getHeaderNodes)
        onClearFilters: handleClearFilters
      }),
      alertNode: AlertNode,
      infoNode: InfoNode,
      laneFilterNode: LaneFilterNode
    } as NodeTypes
  ), [])

  // Switch/adjust map view based on query
  useEffect(() => {
    // load views if source, query, or order param changes
    if (verticalsLoaded) {
      if (query.source) {
        if (flowView !== "source" || !selectedSource || (selectedSource && selectedSource.id !== query.source)) {
          loadSourceView(query.source)
        }
      }

      if (query.channel) {
        if (flowView !== "channel" || !selectedChannel || (selectedChannel && selectedChannel.id !== query.channel)) {
          loadChannelView(query.channel)
        }

      }
      if (query.vendor) {
        if (flowView !== "vendor" || !selectedVendor || (selectedVendor && selectedVendor.id !== query.vendor)) {
          loadVendorView(query.vendor)
        }

      }
      if (query.order) {
        if (flowView !== "order" || !selectedOrder || (selectedOrder && selectedOrder.id !== query.order)) {
          loadOrderView(query.order)
        }
      }
    }
  }, [query, verticalsLoaded])

  //TODO: on creation of source/channel/vendor, change flowView to that specific entity
  const refreshView = () => {
    if (flowView === "source") {
      loadSourceView(query.source)
    } else if (flowView === "channel") {
      loadChannelView(query.channel)
    } else if (flowView === "vendor") {
      loadVendorView(query.vendor)
    } else if (flowView === "order") {
      loadOrderView(query.order)
    }
  }

  //Load routing map from the perspective of one specified source
  const loadSourceView = async (sourceId:any) => {
    setViewport({ x: 0, y: 0, zoom: 1 });
    setLoading(true);
    const result = await routingApi.sourceRoutes(sourceId);
    if (result) {
      initSourceView(result, dataView)
    }

    setLoading(false)
  }

  // Load routing map from the perspective of one specified channel
  const loadChannelView = async (channelId:number) => {
    //viewport shifted so channel will be in view on load
    setViewport({ x: -(NodeDefaultWidth + NodeColumnGap), y: 0, zoom: 1 });
    setLoading(true)
    const channelRoutes = await routingApi.channelRoutes(channelId)
    const channelOrders = await routingApi.ordersForChannel(channelId) as any
    if (channelRoutes) {
      initChannelView(channelRoutes, channelOrders, dataView)
    }
    setLoading(false)
  }

  // Load routing map from the perspective of one specified vendor
  // The number of sources per vendor can be huge, so we request a limited number
  const loadVendorView = async (vendorId:number) => {
    setViewport({ x: 0, y: 0, zoom: 1 });
    setLoading(true)
    const params:any = {source_limit:20};
    if (query.product) {
      params.product = query.product
    }
    if (query.vertical) {
      params.vertical_id = query.vertical
    }
    const result = await routingApi.vendorRoutes(
      vendorId,
      params
    )
    if (result) {
      initVendorView(result, dataView)
    }
    setLoading(false)
  }

  const loadOrderView = async (orderId:any) => {
    //viewport shifted so order will be in view on load
    setViewport({ x: -2 * (NodeDefaultWidth ), y: 0, zoom: 1 });
    setLoading(true);
    const result = await routingApi.channelsForOrder(orderId);
    if (result) {
      initOrderView(result, dataView);
    }

    setLoading(false)
  }


  // Load the orders that use the specified channel in the rule's channel modifiers
  const loadChannelOrders = async (channelId:number) => {
    setLoadingOrders(true);
    const result = await routingApi.ordersForChannel(channelId) as any
    if (result) {
      // TODO: group by account?
      setChannelOrders(result)
      const {nodes: orderNodes, edges:orderEdges} = getOrderNodes(result, channelId, true)
      updateFlowOrders(orderNodes, orderEdges)
    }
    setLoadingOrders(false)
  }

  const getHeaderNodes = (flowView:string, filters:Record<string, string[]>={}) => {

    const baseY = 20

    const nodes:Node[] = [
      {
        id:`header-vendor`,
        data: {
          title:flowView === 'vendor' ? 'Vendor' : 'Vendors',
          entity:'vendor',
          active:flowView==='vendor',
          flowView,
          allowAdd: true,
        },
        position: {x:OriginX, y:baseY},
        type:'headerNode',
        hidden:false
      },
      {
        id:`header-source-token`,
        data: {
          title:'Source Tokens',
          entity:'source-token',
          flowView,
          filters:filters.sourceToken,
          allowAdd: true,
        },
        position: {x:OriginX + NodeDefaultWidth + NodeColumnGap , y:baseY},
        type:'headerNode',
        hidden:false
      },
      {
        id:`header-source`,
        data: {
          title:flowView === 'source' ? 'Source' : 'Sources',
          entity:'source',
          active:flowView==='source',
          flowView,
          allowAdd: true
        },
        position: {x:OriginX +( 2* (NodeDefaultWidth + NodeColumnGap)), y:baseY},
        type:'headerNode',
        hidden:false,
      },
      {
        id:`header-eligibility`,
        data: {
          title:'Eligibilities',
          entity:'eligibility',
          flowView,
          allowAdd:false
        },
        position: {x:OriginX +( 3* (NodeDefaultWidth + NodeColumnGap)), y:baseY},
        type:'headerNode'
      },
      {
        id:`header-channel`,
        data: {
          title:flowView === 'channel' ? 'Channel' : 'Channels',
          active:flowView==='channel',
          flowView,
          entity: 'channel',
          allowAdd:true
        },
        position: {x:OriginX +( 4* (NodeDefaultWidth + NodeColumnGap)), y:baseY},
        type:'headerNode'
      },{
        id:`header-order`,
        data: {
          title: flowView === 'orders' ? 'Order' : 'Orders',
          active: flowView === 'orders',
          entity: 'orders',
          allowAdd: false,
          flowView
        },
        position: {x:OriginX +( 5* (NodeDefaultWidth + NodeColumnGap)), y:baseY},
        type:'headerNode'
      },
    ]

    return nodes
  }

  const columnsX = useMemo(() => {
    const vendorX = OriginX
    const sourceTokenX = OriginX + NodeDefaultWidth + NodeColumnGap
    const sourceX = OriginX + (2 * (NodeDefaultWidth + NodeColumnGap))
    const eligibilityX = OriginX + (3 * (NodeDefaultWidth + NodeColumnGap))
    const channelX = OriginX + (4 * (NodeDefaultWidth + NodeColumnGap))
    const orderX = OriginX + (5 * (NodeDefaultWidth + NodeColumnGap))
    return {vendorX, sourceTokenX, sourceX, eligibilityX, channelX, orderX}
  }, []);

  const initSourceView = (source: any, dataView:string[]=[]) => {
    const nodeHeight = dataView.includes('sales') ? 100 : 50
    let nodes:Node[] = getHeaderNodes('source')

    let edges:Edge[] = []

    if (source) {

      const {vendorX, sourceX, eligibilityX, channelX} = columnsX

      let channelY = OriginY;

      //Source Node (1)
      nodes.push({
        id:`src-${source.id}`,
        data: {label:source.name, source, dataView, flowView:'source'},
        position: {x:sourceX, y:OriginY},
        targetPosition: Position.Left,
        sourcePosition: Position.Right,
        type:'sourceNode'
      })


      //SourceToken and order nodes and edges
      const {nodes:sourceTokenNodes, edges:sourceTokenEdges} = getSourceTokenNodesFromSources([source], dataView)
      //const {nodes: orderNodes, edges:orderEdges} = getOrderNodes(channelOrders, orderChannel?.id ?? 0, dataView)
      const {nodes: orderNodes, edges:orderEdges} = getOrderNodes([], 0, source.channels.length > 0, dataView)
      nodes = [...nodes, ...sourceTokenNodes, ...orderNodes]
      edges = [...edges, ...sourceTokenEdges, ...orderEdges]


      //Channel and Eligibility nodes & edges
      if (source.channels.length) {
        source.channels.forEach((c:any) => {
          nodes.push({
            id:`elg-${source.id}-${c.id}`,
            data: {
              source: source,
              channel: c,
              eligibility: c.eligibility,
              dataView
            },
            position: {x:eligibilityX, y:channelY},
            sourcePosition: Position.Right,
            targetPosition: Position.Left,
            type: 'eligibilityNode'
          })

          edges.push({
            id:`edge-elg-src-${source.id}-${c.id}`,
            type:'simplebezier',
            source:`src-${source.id}`,
            target: `elg-${source.id}-${c.id}`,
            sourceHandle: 'source-handle',
            animated:true
          })

          // Channel nodes & edges
          nodes.push({
            id:`chn-${c.id}`,
            data: {channel:c, dataView},
            position: {x:channelX, y:channelY},
            sourcePosition: Position.Right,
            targetPosition: Position.Left,
            type:'channelNode'
          })
          edges.push({
            id:`edge-elg-chn-${c.id}`,
            type:'simplebezier',
            source:`elg-${source.id}-${c.id}`,
            target: `chn-${c.id}`,
            sourceHandle: 'source-handle',
            animated:true
          })

          channelY += nodeHeight + NodeVerticalGap
        })
      } else {
        //No channels use this source
        //Add alert node in Eligibility column
        nodes.push({
          id:`elg-${source.id}-none`,
          data: {
            source: source,
            channel: {},
            eligibility: {},
            alert:true,
            dataView:''
          },
          position: {x:eligibilityX, y:channelY},
          sourcePosition: Position.Right,
          targetPosition: Position.Left,
          type: 'eligibilityNode'
        })

        edges.push({
          id:`edge-elg-src-${source.id}-none`,
          type:'simplebezier',
          source:`src-${source.id}`,
          target: `elg-${source.id}-none`,
          sourceHandle: 'source-handle',
          animated:true
        })
      }


      //Vendor Nodes
      let vendorY = OriginY
      source.vendors.forEach((v:any) => {
        nodes.push({
          id: `ven-${v.id}`,
          data: {
            vendor: v,
            dataView
          },
          position: {x: vendorX, y: vendorY},
          sourcePosition: Position.Right,
          type: 'vendorNode'
        })


        vendorY += nodeHeight + NodeVerticalGap
      })

      //TODO: could call loadChannelOrders for first channel if we have channels
      // note: causes spinner to appear twice. We could do this in the sourceLoader function, pass the result to this function
      // if (source.channels.length) {
      //   window.setTimeout(() => loadChannelOrders(source.channels[0].id), 1000)
      // }

      // Batched state updates will be automatic in React 18
      // Hopefully we can live with "unstable"
      ReactDOM.unstable_batchedUpdates(()=>{

        setNodes(nodes)
        setEdges(edges)
        setSelectedSource(source)
        setSelectedChannel(null)
        setSelectedOrder(null)
        setOverflowTokens([])
        setFlowView("source")
        setOrderChannel(null)
        setChannelOrders([])
        setDataView(dataView)
      }) // batch state

    }
  }

  const initChannelView = (channel:any, channelOrders:any[]=[], dataView:string[]=[]) => {

    const nodeHeight = dataView.includes('sales') ? 100 : 50

    let nodes:Node[] = getHeaderNodes('channel')
    let edges:Edge[] = []

    if (channel) {

      const { vendorX, sourceX, eligibilityX, channelX} = columnsX
      let sourceY = OriginY

      //Channel Node (1)
      nodes.push({
        id: `chn-${channel.id}`,
        data: {label: channel.name, channel, dataView, flowView:'channel'},
        position: {x: channelX, y: OriginY},
        targetPosition: Position.Left,
        sourcePosition: Position.Right,
        type: 'channelNode'
      })

      //Source & Eligibility nodes & edges
      //NO CHANNEL-SOURCES: set data that causes an alert to show in the Eligibility node
      if (!channel.sources.length) {
        nodes.push({
          id:`elg-src-none-${channel.id}`,
          data: {
            source: {id:0},
            channel: channel,
            eligibility: {},
            alert:true,
            dataView:''
          },
          position: {x:eligibilityX, y:sourceY},
          sourcePosition: Position.Right,
          type: 'eligibilityNode'
        })

        //eligibility to channel
        edges.push({
          id:`edge-elg-src-none-${channel.id}`,
          type:'simplebezier',
          source: `elg-src-none-${channel.id}`,
          target:`chn-${channel.id}`,
          sourceHandle: 'source-handle',
          animated:true
        })
      }


      channel.sources.forEach((s:any) => {
        nodes.push({
          id:`elg-${s.id}-${channel.id}`,
          data: {
            source: s,
            channel: channel,
            eligibility: s.eligibility,
            dataView
          },
          position: {x:eligibilityX, y:sourceY},
          sourcePosition: Position.Right,
          targetPosition: Position.Left,
          type: 'eligibilityNode'
        })

        //eligibility to channel
        edges.push({
          id:`edge-elg-src-${s.id}-${channel.id}`,
          type:'simplebezier',
          source: `elg-${s.id}-${channel.id}`,
          target:`chn-${channel.id}`,
          sourceHandle: 'source-handle',
          animated:true
        })

        //source to eligibility
        edges.push({
          id:`edge-elg-src-${s.id}`,
          type:'simplebezier',
          source:`src-${s.id}`,
          target: `elg-${s.id}-${channel.id}`,
          sourceHandle: 'source-handle',
          animated:true
        })

        // Source nodes
        nodes.push({
          id:`src-${s.id}`,
          data: {source:s, dataView},
          position: {x:sourceX, y:sourceY},
          sourcePosition: Position.Right,
          targetPosition: Position.Left,
          type:'sourceNode'
        })


        sourceY += nodeHeight + NodeVerticalGap;
      })

      const {nodes:sourceTokenNodes, edges:sourceTokenEdges} = getSourceTokenNodesFromSources(channel.sources, dataView)
      //const {nodes: orderNodes, edges:orderEdges} = getOrderNodes(channelOrders, channel.id, true, dataView)
      const {nodes: orderNodes, edges:orderEdges} = getOrderNodes(channelOrders, channel.id, true, dataView)

      nodes = [...nodes, ...sourceTokenNodes, ...orderNodes]
      edges = [...edges, ...sourceTokenEdges, ...orderEdges]

      //Vendor Nodes
      let vendorY = OriginY
      channel.vendors.forEach((v:any) => {
        nodes.push({
          id: `ven-${v.id}`,
          data: {
            vendor: v,
            dataView
          },
          position: {x: vendorX, y: vendorY},
          sourcePosition: Position.Right,
          // targetPosition: Position.Left,
          type: 'vendorNode'
        })

        v.tokens.forEach((token:SourceToken) => {
          edges.push({
            id: `edge-src-${token.source.id}-ven-${v.id}`,
            type: 'simplebezier',
            source: `ven-${v.id}`,
            target: `token-src-${token.source.id}-ven-${v.id}`, // `token-src-${src.id}-ven-${token.vendor.id}`
            sourceHandle: 'source-handle',
            animated: true
          })
        })

        vendorY += nodeHeight + NodeVerticalGap
      })

      ReactDOM.unstable_batchedUpdates(()=>{

        setNodes(nodes)
        setEdges(edges)
        setSelectedChannel(channel)
        setSelectedSource(null)
        setSelectedOrder(null)
        setOverflowTokens([])
        setFlowView("channel")
        setDataView(dataView)
        setOrderChannel(channel)
        setChannelOrders(channelOrders)

      }) // batch state


    }
  }

  const getFilterValues = ():string[] => {
    const filterValues:string[] = []
    console.log("index.tsx:getFilterValues query", query );
    if ((query.product || query.vertical)) {
      if (query.product) {
        filterValues.push(Utils.titleCase(query.product))
      }
      if (query.vertical) {
        filterValues.push(getVerticalDisplayName(query.vertical))
      }
    }
    console.log("index.tsx: returning filterValues", filterValues );
    return filterValues
  }

  const initVendorView = (vendor:any, dataView:string[]=[]) => {
    const nodeHeight = dataView.includes('sales') ? 100 : 50

    const filterValues = {sourceToken:getFilterValues()}
    const archiveFilter = (item:any) => dataView.includes('archived') ? true : item.status !== 'archived' ;

    let nodes:Node[] = getHeaderNodes('vendor', filterValues)
    let edges:Edge[] = []

    if (vendor) {

      const { vendorX, sourceX, eligibilityX, channelX} = columnsX


      //Vendor Node (1)
      nodes.push({
        id:`ven-${vendor.id}`,
        data: {label:vendor.name, vendor, dataView, flowView:'vendor'},
        position: {x:vendorX, y:OriginY},
        sourcePosition: Position.Right,
        type:'vendorNode'
      })

      const {nodes:sourceTokenNodes, edges:sourceTokenEdges} = getSourceTokenNodesFromVendor(vendor.tokens, vendor.id, dataView)
      //const {nodes: orderNodes, edges:orderEdges} = getOrderNodes(channelOrders, orderChannel?.id ?? 0, dataView)
      const {nodes: orderNodes, edges:orderEdges} = getOrderNodes([], 0, vendor.channels.length > 0, dataView)

      nodes = [...nodes, ...sourceTokenNodes, ...orderNodes]
      edges = [...edges, ...sourceTokenEdges, ...orderEdges]

      //Source Nodes
      let sourceY = OriginY
      let eligibilityY = OriginY
      vendor.sources.filter(archiveFilter).forEach((source:any) => {

        nodes.push({
          id: `src-${source.id}`,
          data: {
            source,
            dataView
          },
          position: {x: sourceX, y: sourceY},
          sourcePosition: Position.Right,
          targetPosition: Position.Left,
          type: 'sourceNode'
        })

        //Source & Eligibility nodes & edges
        if (!source.channels.length) {
          //No channels: make a fake channel that causes an alert to show
          source.channels.push({
            id:0,
            eligibility:{}
          })
        }

        source.channels.forEach((channel:any) => {
          nodes.push({
            id: `elg-${source.id}-${channel.id}`,
            data: {
              source,
              channel,
              eligibility: channel.eligibility,
              alert: !channel.id,
              dataView
            },
            position: {x: eligibilityX, y: eligibilityY},
            sourcePosition: Position.Right,
            targetPosition: Position.Left,
            type: 'eligibilityNode'
          })

          //Edge: eligibility to channel
          if (channel.id) {
            edges.push({
              id: `edge-elg-ch-${source.id}-${channel.id}`,
              type: 'simplebezier',
              source: `elg-${source.id}-${channel.id}`,
              target: `chn-${channel.id}`,
              sourceHandle: 'source-handle',
              animated: true
            })
          }

          //Edge: source to eligibility
          edges.push({
            id: `edge-elg-src-${source.id}-${channel.id}`,
            type: 'simplebezier',
            source: `src-${source.id}`,
            target: `elg-${source.id}-${channel.id}`,
            sourceHandle: 'source-handle',
            animated: true
          })
          eligibilityY += nodeHeight + NodeVerticalGap
        })


        // edge from token to source
        // edges.push({
        //   id: `edge-token-${token.id}-src-${token.source.id}`,
        //   type: 'simplebezier',
        //   source: `token-src-${token.source.id}-ven-${token.vendor.id}`,
        //   target: `src-${token.source.id}`,
        //   sourceHandle: 'source-handle',
        //   animated: true
        // })
        sourceY += nodeHeight + NodeVerticalGap;
      })

      //Channel Nodes
      let channelY = OriginY
      vendor.channels.forEach((channel:any) => {

        nodes.push({
          id: `chn-${channel.id}`,
          data: {
            channel,
            dataView
          },
          position: {x: channelX, y: channelY},
          sourcePosition: Position.Right,
          targetPosition: Position.Left,
          type: 'channelNode'
        })

        channelY += NodeVerticalGap + nodeHeight;
      })

      ReactDOM.unstable_batchedUpdates(()=>{

        setNodes(nodes)
        setEdges(edges)
        setSelectedVendor(vendor)
        setSelectedSource(null)
        setSelectedChannel(null)
        setSelectedOrder(null)
        setOverflowTokens(vendor.token_overflow)
        setFlowView("vendor")
        setDataView(dataView)
        setOrderChannel(null)
        setChannelOrders([])

      }) // batch state
    }
  }

  const getSourceTokenNodesFromSources = (sources:any[], dataView:string[]) => {

    const {sourceTokenX} = columnsX
    let nodeY = OriginY
    const nodeHeight = dataView ? 140 : 80
    const nodes:Node[] = []
    const edges:Edge[] = []
    const archiveFilter = (item:any) => dataView.includes('archived') ? true : item.status !== 'archived'
    //when focusing on source, we are processing the tokens of one source
    //when focusing on channel, we may be processing multiple sources
    sources.filter(archiveFilter).map((src:any) => {
      if (src.tokens.length) {
        src.tokens.filter((token:any)=>token.status!=='archived').forEach((token:any) => {
          nodes.push({
            id:`token-${token.id}-src-${src.id}-ven-${token.vendor.id}`,
            data: {
              token,
              dataView
            },
            position: {x:sourceTokenX, y:nodeY},
            sourcePosition: Position.Right,
            targetPosition: Position.Left,
            type: 'sourceTokenNode'
          })

          // edge from token to source
          edges.push({
            id:`edge-token-${token.id}-src-${token.source.id}`,
            type:'simplebezier',
            source:`token-${token.id}-src-${src.id}-ven-${token.vendor.id}`,
            target: `src-${token.source.id}`,
            sourceHandle: 'source-handle',
            animated:true
          })

          //edge from token to vendor
          edges.push({
            id: `edge-token-${token.id}-ven-${token.vendor.id}`,
            type: 'simplebezier',
            source: `ven-${token.vendor.id}`,
            target: `token-${token.id}-src-${src.id}-ven-${token.vendor.id}`,
            sourceHandle: 'source-handle',
            animated: true
          })

          nodeY += nodeHeight + NodeVerticalGap;
        })



      } else {
        // show SourceTokenNode with warning
        // The source we are focusing on does not have a source-vendor token
        nodes.push({
          id:`token-none`,
          data: {
            // unidentified token with a source allows user to add a token to the source
            token:{id:0, source:src},
            alert:true,
            dataView
          },
          position: {x:sourceTokenX, y:nodeY},
          sourcePosition: Position.Right,
          type: 'sourceTokenNode'
        })

        edges.push({
          id:`edge-token-src-none`,
          type:'simplebezier',
          source:`token-none`,
          target: `src-${src.id}`,
          sourceHandle: 'source-handle',
          animated:true
        })
      }
    })

    return {nodes, edges}

  }

  const getSourceTokenNodesFromVendor = (tokens:any[], vendorId:number, dataView:string[]) => {

    const {sourceTokenX} = columnsX
    let nodeY = OriginY
    const nodeHeight = dataView ? 140 : 80
    const nodes:Node[] = []
    const edges:Edge[] = []
    const archiveFilter = (item:any) => dataView.includes('archived') ? true : item.status !== 'archived' ;
     // Show filters in their own node (now showing in header node
      // if (filters) {
      //   nodes.push({
      //     id:`filters-token`,
      //     data: {filters},
      //     position: {x:sourceTokenX, y:nodeY},
      //     type:'laneFilterNode'
      //   })
      //   nodeY += 60
      // }

      if (tokens.length) {

        // Notify user that not all items are displayed, can search/pick from overflow
        // if (token_overflow.length) {
        //   nodes.push({
        //     id:`token-overflow-${vendorId}`,
        //     data: {tokens:token_overflow},
        //     position: {x:sourceTokenX, y:nodeY},
        //     type:'tokenOverflowNode'
        //   })
        //   nodeY += 250
        // }

        tokens.filter(archiveFilter).forEach((token:any) => {
            nodes.push({
              id:`token-${token.id}-src-${token.source.id}-ven-${token.vendor.id}`,
              data: {
                token,
                dataView
              },
              position: {x:sourceTokenX, y:nodeY},
              sourcePosition: Position.Right,
              targetPosition: Position.Left,
              type: 'sourceTokenNode'
            })

            // edge from token to source
            edges.push({
              id:`edge-token-${token.id}-src-${token.source.id}`,
              type:'simplebezier',
              source:`token-${token.id}-src-${token.source.id}-ven-${token.vendor.id}`,
              target: `src-${token.source.id}`,
              sourceHandle: 'source-handle',
              animated:true
            })
            //edge from vendor to token
            edges.push({
              id:`edge-token-${token.id}-ven-${token.vendor.id}`,
              type:'smoothstep', //could be a lot in vendor view, smoothstep looks better
              source:`ven-${token.vendor.id}`,
              target: `token-${token.id}-src-${token.source.id}-ven-${token.vendor.id}`,
              sourceHandle: 'source-handle',
              animated:true
            })
            nodeY += nodeHeight + NodeVerticalGap;


        })



      } else {
        // show SourceTokenNode with warning
        // The vendor we are focusing on does not have a source-vendor token
        nodes.push({
          id:`token-none`,
          data: {
            token:{},
            alert:true,
            dataView
          },
          position: {x:sourceTokenX, y:nodeY},
          targetPosition: Position.Left,
          type: 'sourceTokenNode'
        })

        edges.push({
          id:`edge-token-src-none`,
          type:'simplebezier',
          target:`token-none`,
          source: `ven-${vendorId}`,
          sourceHandle: 'source-handle',
          animated:true
        })
      }


    return {nodes, edges}

  }

  // Creates nodes and edges from orderData (a list of orders that use one specific channel)
  const getOrderNodes = (orderData:any[], channelId:number, channelsExist:boolean, dataView:string[]=[]) => {

    const nodeHeight = dataView.includes('sales') ? 160 : 68

    const orderNodes: Node[] = []
    const orderEdges: Edge[] = []
    const {orderX} = columnsX
    let nodeY = OriginY


    if (orderData.length && channelId) {

      orderData.forEach((orderData:any) => {
        const {order, rules} = orderData;
        orderNodes.push({
          id:`ord-${order.id}`,
          data: {
            order,
            rules,
            dataView
          },
          position: {x:orderX, y:nodeY},
          targetPosition: Position.Left,
          type: 'orderNode'
        })

        orderEdges.push({
          id:`edge-ord-${order.id}-chn`,
          type:'smoothstep',
          source:`chn-${channelId}`,
          target: `ord-${order.id}`,
          sourceHandle: `source-handle`,
          targetHandle: 'target-handle',
          animated:true
        })
        nodeY += nodeHeight + NodeVerticalGap;

      })
    } else if (!orderData.length) {
      if (channelId) {
        //A channel was clicked but no orders were found, show alert node
        orderNodes.push({
          id:`ord-alert-chn-${channelId}`,
          data: {
            message:"No orders use this channel.",
            helpText:"To use this channel in an order, add the channel in the Channels tab of any rule of an advertiser order. " +
              "The order must have the same product and vertical as the channel."
          },
          //TODO: place next to the channel, not at top
          position: {x:orderX, y:nodeY},
          targetPosition: Position.Left,
          type: 'alertNode'
        })

        orderEdges.push({
          id:`edge-ord-alert-chn-${channelId}`,
          type:'smoothstep',
          source:`chn-${channelId}`,
          target: `ord-alert-chn-${channelId}`,
          sourceHandle: 'source-handle',
          targetHandle: 'target-handle',
          animated:true
        })
      } else if (channelsExist) {
        //Route has channels but no channel has been selected
        orderNodes.push({
          id:`ord-info`,
          data: {
            infoIcon:false,
            message:<span>Click &nbsp; <FaIcon icon={faFileInvoiceDollar} size="1x"/> &nbsp; on a channel to view orders that use the channel</span>
          },
          position: {x:orderX, y:nodeY},
          type: 'infoNode'
        })
      }

    }

    return {nodes:orderNodes, edges:orderEdges}

  }

  const initOrderView = (orderData:any, dataView:string[]=[]) => {

    const nodeHeight = dataView.includes('sales') ? 100 : 50

    let nodes: Node[] = getHeaderNodes('orders')
    let edges: Edge[] = []

    const {order, rules, channels, sources, vendors} = orderData

    if (order) {

      const { vendorX,  sourceX,channelX, eligibilityX, orderX} = columnsX

      //Order Node (1)
      nodes.push({
        id: `ord-${order.id}`,
        data: {
          label: order.name,
          order,
          rules,
          dataView,
          flowView: 'order'
        },
        position: {x: orderX, y: OriginY},
        targetPosition: Position.Left,
        type: 'orderNode'
      })

      //Channel and Eligibility nodes & edges
      let channelY = OriginY
      let eligibilityY = OriginY
      if (channels.length) {
        channels.forEach((channel:any) => {

          // Channel nodes & edges
          nodes.push({
            id:`chn-${channel.id}`,
            data: {channel:channel, dataView},
            position: {x:channelX, y:channelY},
            sourcePosition: Position.Right,
            targetPosition: Position.Left,
            type:'channelNode'
          })
          // order to channel
          edges.push({
            id:`edge-elg-ord-${order.id}-chn-${channel.id}`,
            type:'simplebezier',
            source:`chn-${channel.id}`,
            target: `ord-${order.id}`,
            sourceHandle: 'source-handle',
            animated:true
          })


          //Channel-Source Eligibilities
          //NO CHANNEL-SOURCES: set data that causes an alert to show in the Eligibility node
          if (!channel.sources.length) {
            nodes.push({
              id:`elg-src-none-${channel.id}`,
              data: {
                source: {id:0},
                channel: channel,
                eligibility: {},
                alert:true,
                dataView
              },
              position: {x:eligibilityX, y:eligibilityY},
              sourcePosition: Position.Right,
              type: 'eligibilityNode'
            })

            //eligibility to channel
            edges.push({
              id:`edge-elg-src-none-${channel.id}`,
              type:'simplebezier',
              source: `elg-src-none-${channel.id}`,
              target:`chn-${channel.id}`,
              sourceHandle: 'source-handle',
              animated:true
            })
          }

          channel.sources.forEach((source:any) => {
            nodes.push({
              id:`elg-${source.id}-${channel.id}`,
              data: {
                source: source,
                channel: channel,
                eligibility: source.eligibility,
                dataView
              },
              position: {x:eligibilityX, y:eligibilityY},
              sourcePosition: Position.Right,
              targetPosition: Position.Left,
              type: 'eligibilityNode'
            })
            // elg to channel
            edges.push({
              id: `edge-elg-chn-${source.id}-${channel.id}`,
              type: 'simplebezier',
              source: `elg-${source.id}-${channel.id}`,
              target: `chn-${channel.id}`,
              sourceHandle: 'source-handle',
              animated: true
            })
            // source to elg
            edges.push({
              id:`edge-elg-src-${source.id}-${channel.id}`,
              type:'simplebezier',
              source:`src-${source.id}`,
              target: `elg-${source.id}-${channel.id}`,
              sourceHandle: 'source-handle',
              animated:true
            })
            eligibilityY += nodeHeight + NodeVerticalGap
          })

          channelY += nodeHeight + NodeVerticalGap
        })
      } else {
        //TODO: handle no channels display
      }

      //SOURCE nodes
      let sourceY = OriginY
      if (sources.length) {
        sources.forEach((source:any) => {

          nodes.push({
            id: `src-${source.id}`,
            data: {source, dataView},
            position: {x: sourceX, y: sourceY},
            sourcePosition: Position.Right,
            targetPosition: Position.Left,
            type: 'sourceNode'
          })

          sourceY += nodeHeight + NodeVerticalGap
        })
      }

      const {nodes:sourceTokenNodes, edges:sourceTokenEdges} = getSourceTokenNodesFromSources(sources, dataView)
      nodes = [...nodes, ...sourceTokenNodes]
      edges = [...edges, ...sourceTokenEdges]

      // Vendors
      let vendorY = OriginY
      if (vendors.length) {
        vendors.forEach((vendor:any) => {

          nodes.push({
            id: `ven-${vendor.id}`,
            data: {vendor, dataView},
            position: {x: vendorX, y: vendorY},
            sourcePosition: Position.Right,
            targetPosition: Position.Left,
            type: 'vendorNode'
          })

          vendorY += nodeHeight + NodeVerticalGap
        })
      }

      ReactDOM.unstable_batchedUpdates(()=>{

        setNodes(nodes)
        setEdges(edges)
        setSelectedVendor(null)
        setSelectedOrder(orderData)
        setOrderChannel(null)
        setSelectedSource(null)
        setSelectedChannel(null)
        setOverflowTokens([])
        setFlowView("order")
        setDataView(dataView)
        setChannelOrders(null)

      }) // batch state
    }
  }

  const updateFlowOrders = (orderNodes:Node[], orderEdges:Edge[]) => {
    const {nodes, edges} = store.getState();

    //Remove existing order nodes & edges if any, add new
    const newNodes = nodes.filter((node:Node) => node.id.indexOf('ord') < 0 || node.id.indexOf('header') === 0)
    const newEdges = edges.filter((edge:Edge) => edge.id.indexOf('edge-ord') < 0 )

    setNodes([...newNodes, ...orderNodes])
    setEdges([...newEdges, ...orderEdges])

  }

  /*
   * Node Highlighting: highlight route by clicking on them, lower opacity of all other routes
   * Solution mostly borrowed from: https://github.com/xyflow/xyflow/issues/2418
   */

  //Recursively get all ancestor aka "incoming" nodes for a given node
  const getAllIncomers = (node:Node, nodes:Node[], edges:Edge[], prevIncomers:Node[] = []) => {
    const incomers = getIncomers(node, nodes, edges);
    const result = incomers.reduce(
      (memo:Node[], incomer) => {
        memo.push(incomer);

        if ((prevIncomers.findIndex(n => n.id == incomer.id) == -1)) {
          prevIncomers.push(incomer);

          getAllIncomers(incomer, nodes, edges, prevIncomers).forEach((foundNode:Node) => {
            memo.push(foundNode);

            if ((prevIncomers.findIndex(n => n.id == foundNode.id) == -1)) {
              prevIncomers.push(incomer);

            }
          });
        }
        return memo;
      },
      []
    );
    return result;
  }

  //Recursively get all descendent aka "outgoer" nodes for a given node
  const getAllOutgoers = (node:Node, nodes:Node[], edges:Edge[], prevOutgoers:Node[] = []) => {
    const outgoers = getOutgoers(node, nodes, edges);
    return outgoers.reduce(
      (memo:Node[], outgoer) => {
        memo.push(outgoer);

        if ((prevOutgoers.findIndex(n => n.id == outgoer.id) == -1)) {
          prevOutgoers.push(outgoer);

          getAllOutgoers(outgoer, nodes, edges, prevOutgoers).forEach((foundNode) => {
            memo.push(foundNode);

            if ((prevOutgoers.findIndex(n => n.id == foundNode.id) == -1)) {
              prevOutgoers.push(foundNode);
            }
          });
        }
        return memo;
      },
      []
    )
  }

  const highlightPath = (node:Node) => {
    if (node && [...nodes, ...edges]) {

      const allIncomers = getAllIncomers(node, nodes, edges)
      const allOutgoers = getAllOutgoers(node, nodes, edges)

      const incomerIds = allIncomers.map((i) => i.id)
      const outgoerIds = allOutgoers.map((o) => o.id)
      const allIds = [...incomerIds, ...outgoerIds, node.id];

      let connectedEdgeIds:string[] = [];

      // set styles of all edges and nodes according to whether they are on the route (except header nodes)
      const newNodes = nodes.map((elem) => {

          if (isNode(elem) && (allOutgoers.length > 0 || allIncomers.length > 0) && elem.id.indexOf('header') < 0 ) {
            const highlight = elem.id === node.id || incomerIds.includes(elem.id) || outgoerIds.includes(elem.id)

            elem.style = {
              ...elem.style,
              opacity: highlight ? 1 : 0.4,
            }
            if (highlight) {
              const nodeConnectedEdgeIds = getConnectedEdges([elem], edges)
              .filter((e:Edge) => allIds.includes(e.target) && allIds.includes(e.source))
                .map((e) => e.id)
              connectedEdgeIds = [...connectedEdgeIds, ...nodeConnectedEdgeIds]
            }
          }

          return {...elem}
        })


      const newEdges = edges.map((e) => {
        const isConnected = connectedEdgeIds.includes(e.id);
        return {
          ...e,
          animated: isConnected,
          style: {
            ...e.style,
            stroke: isConnected ? '#147CFF' : '#6C757D', // $primary : $gray-600
            strokeWidth: isConnected? 3 : 1,
            opacity: isConnected ? 1 : 0.25
          },
        }

      });

      setNodes(newNodes)
      setEdges(newEdges)

    }
  }

  // Undo the Highlight styles
  const resetNodeStyles = () => {

    setNodes((prevElements) => {
      return prevElements?.map((elem:Node) => {
        if (isNode(elem)) {
          elem.style = {
            ...elem.style,
            opacity: 1,
          }
        }

        return {...elem}
      })
    })

    setEdges((prevEdges) => {
      return prevEdges.map((edge) => ({
        ...edge,
        animated: true,
        style: {
          ...edge.style,
          stroke: '#6C757D',
          strokeWidth: 1,
          opacity:1,
        },
      }))
    })
  }

  //For use with MiniMap  https://reactflow.dev/api-reference/components/minimap
  //const nodeClassName = (node:any) => node.type;

  return (
    <RoutingFlowContext.Provider value={{
      selectedVendor,
      selectedSource,
      selectedChannel,
    }}
    >
        <div className='routing-mapper'>
            <div className="mb-2" style={{zIndex:10}}>
              <Filters filters={query} onChange={handleChangeFilters} loading={false} key={filterRefreshKey}/>
            </div>



          <div className="d-flex flex-fill justify-content-end">
            <div className="d-flex align-items-center justify-content-end pb-1">
              <div className="me-2">View:</div>
              <Button
                size="xs"
                color="rounded"
                outline
                active={dataView.includes('sales')}
                onClick={() => handleSelectDataView("sales")}
              >Sales</Button>
              <Button
                size="xs"
                color="rounded"
                outline
                active={dataView.includes('archived')}
                onClick={() => handleSelectDataView("archived")}
                className="ms-1"
              >Archived</Button>
            </div>
          </div>


          {/*{loading && <div className="loader-container">
            <LoaderDots active={loading} width={160} />
          </div>}*/}


          {/* Flow banner when visible will look like it is part of flow-container */}
          {overflowTokens.length > 0 && (
            <div className="flow-banner w-100" >
                <TokenOverflow tokens={overflowTokens} onSelect={handleExamineSource} />
            </div>
          )}
          <div className="flow-container w-100" style={{  height: '500px' }} >
            <BlockUI
              blocking={loading || loadingOrders}
              mode="stretch"
              loader={<Spinner color="success" />}
            >

              <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                nodeTypes={nodeTypes}
                nodesDraggable={false}
                nodesConnectable={false}
                selectNodesOnDrag={false}
                onNodeClick={(_, node) => highlightPath(node)}
                //elementsSelectable={true}
                //onlyRenderVisibleNodes={false}
                //zoomOnScroll={false}
                zoomOnDoubleClick={false}
                draggable

                style={{ maxHeight: "100%", overflow: "scroll" }}
                onPaneClick={resetNodeStyles}

              />

            {/*
            <MiniMap zoomable pannable nodeClassName={nodeClassName}/>
            <Controls />
            <Background variant={BackgroundVariant.Dots} gap={12} size={1} />
            */}
            </BlockUI>
          </div>


            <RoutingRulesModal
              entity={routingRule?.entity}
              entityType={routingRule?.type}
              isOpen={rulesPanelOpen}
              close={closeRulesPanel}
            />

            <SourceTokenModal
              token={editToken}
              sourcePreselections={candidateSources}
              isOpen={tokenPanelOpen}
              toggle={closeTokenPanel}
              onSuccess={refreshView}
              scrollable
            />

            <SourceModal
              source={editSource}
              isOpen={sourcePanelOpen }
              toggle={closeSourcePanel}
              onSuccess={handleSaveSourceSuccess}
              //onOpenSourceRules={openSourceRules}
              vendorPreselections={candidateVendors}
              scrollable
            />

            <ChannelModal
              channel={editChannel}
              isOpen={channelPanelOpen || channelSourcePanelOpen}
              showSources={channelSourcePanelOpen}
              toggle={closeChannelPanel}
              onSuccess={refreshView}
              onOpenChannelRules={openChannelRules}
              onOpenChannelSourceRules={openChannelSourceRules}
              scrollable
            />

          <LinkChannelSourceModal
            source={linkSource}
            isOpen={linkChannelSourcePanelOpen}
            close={closeLinkChannelSourcePanel}
            onSuccess={refreshView}
          />

          <VendorModal
            vendor={editVendor}
            isOpen={vendorModalOpen}
            close={closeVendorModal}
            onSuccess={handleSaveVendorSuccess}
          />

        </div>

    </RoutingFlowContext.Provider>
  )
}


