import React from 'react'
import {Button,Modal, ModalHeader} from "reactstrap"
import {FontAwesomeIcon as FaIcon} from "@fortawesome/react-fontawesome"
import type {Channel} from "../../../../types";
import {default as ChannelForm} from "./index";
import {ChannelSourceRoute} from "../../../Routing/Rules";

/*
 * Modal dialog that wraps the channel form for editing and creating channels
 */
type ChannelModalProps = {
  channel:Channel,
  isOpen:boolean,
  toggle:()=>void,
  size?:string,
  onSuccess?:(channel:any, isNew?:boolean)=>void,
  onOpenChannelRules?:(channel:any)=>void,
  onOpenChannelSourceRules?:(channelSource:ChannelSourceRoute)=>void,
  scrollable?:boolean
  showSources?:boolean
}

export const ChannelModal = ({
   channel,
   isOpen,
   toggle,
   onSuccess,
   onOpenChannelRules,
   onOpenChannelSourceRules,
   size='lg',
   scrollable=false,
   showSources=false
}:ChannelModalProps) => {

  if (!channel) return null;

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="channel-modal"
      size={size}
      scrollable={scrollable}
    >

      <ModalHeader toggle={toggle}>
        <div className="d-flex align-items-center">
          <Button size="sm" onClick={toggle} className="btn-close-white inline" color="link" >
            <FaIcon icon="times" className="me-2" />
          </Button>
          <div>
            {channel.id ? 'Edit' : 'Create'} Channel
            {channel.name && (
              <>
                {' '}|<span className="modal-subheader">{channel.name}</span>
              </>
            )}

          </div>
        </div>
      </ModalHeader>

          <ChannelForm
            channelId={channel.id}
            close={toggle}
            onSuccess={onSuccess}
            onOpenChannelRules={onOpenChannelRules}
            onOpenChannelSourceRules={onOpenChannelSourceRules}
            initialTab={showSources ? 'sources' : undefined}
            isModal
          />


    </Modal>
  )
}
