import React, {FC, useState, useEffect, useRef} from 'react'
import {Button} from 'reactstrap';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames'
/*
 *  A collapsible panel that hides its children when closed.
 *  Uses the scrollHeight of the children to animate the opening and closing.
 *  Shows a label and toggle button when closed.
 *
 *  There is a controlled and uncontrolled version of this component.
 *  Use the controlled version if the parent needs to control the open state.
 */
type BaseHideawayPanelProps = {
  children: React.ReactNode;
  label:string|React.ReactNode;
  className?: string;
  headerClassName?:string;
  innerStyle?: React.CSSProperties;
  disabled?:boolean;
}

type UncontrolledHideawayPanelProps = BaseHideawayPanelProps & {
  startOpen?: boolean;
}

type HideawayPanelProps = BaseHideawayPanelProps & {
  isOpen?: boolean;
  onToggle: (isOpen:boolean) => void;
}


export const UncontrolledHideawayPanel:FC<UncontrolledHideawayPanelProps> = (props) => {
  const {startOpen, children,...restProps} = props
  const [isOpen, setIsOpen] = useState(startOpen);

  return (
    <HideawayPanel isOpen={isOpen} onToggle={setIsOpen} {...restProps}>
      {children}
    </HideawayPanel>
  )

}

export const HideawayPanel:FC<HideawayPanelProps> = ({
  label,
  isOpen=false,
  onToggle,
  children,
  className='',
  headerClassName='',
  innerStyle={},
  disabled

}) => {
  const panelRef = useRef<HTMLDivElement>(null);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [height, setHeight] = useState(isOpen ? 'auto' : '0px');

  // Set content height based on isOpen prop
  useEffect(() => {

    // We may be at auto height at first, which won't animate. Set to current content height.
    if (isFirstRender) {
      setIsFirstRender(false);
      if (isOpen && panelRef.current) {
        const panel = panelRef.current;
        setTimeout(() => setHeight(panel.scrollHeight + "px"), 250);
      }
    }

    if (panelRef.current && !isFirstRender) {
      if (isOpen) {
        setHeight(panelRef.current.scrollHeight + "px")
      } else {
        setHeight('0px')
      }

    }
  }, [isOpen, children]);

  return (
    <div className={`hideaway-panel ${className}`}>
      <div className={classnames('hp-header d-flex align-items-center', headerClassName, {"mb-1":isOpen})}>
        {label}
        <Button color="link" className="ms-1 inline hp-toggle" onClick={()=>onToggle(!isOpen)} disabled={disabled}>
          <FaIcon icon={isOpen ? 'chevron-circle-up' : 'chevron-circle-down'} />
        </Button>
      </div>

      <div
        className="hp-content"
        ref={panelRef}
        style={{height}}
      >
        <div className="hp-content-inner" style={innerStyle}>
        {children}
        </div>
      </div>

    </div>
  );
}
