import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import {faKey} from "@fortawesome/free-solid-svg-icons";
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import {singularOrPlural, titleCaseWord} from "@thedmsgroup/mastodon-ui-components/lib/utils/string";
import usePermission from '../../../Hooks/usePermission';
import StatusToggler from '../../../Components/Form/StatusToggler';
import SeedDataCell from '../../../Components/Table/SeedDataCell';
import PagedTable from '../../../Components/Table/PagedTable';
import { RelativeTime } from '@thedmsgroup/mastodon-ui-components';
import EntityId from "../../../Components/Table/EntityId";
import useLocalStorage from "../../../Hooks/useLocalStorage";
import {ColumnSelect} from "../../../Components/Table/ColumnSelect";

const DefaultHiddenColumns = ['modified_at', 'inserted_at', 'seed_data'];

const NameCell = ({ name, description }) => (
    <div>
        <span>{name}</span>
        {description && (
            <small className='d-block font-weight-light ms-1 description'>
                {description}
            </small>
        )}
    </div>
);

const ActionCell = ({
    source,
    onEditClick,
    onRulesClick,
    onRoutingClick,
    onVendorClick,
    allowEditSource,
    allowViewRules,
    allowViewAuction,
}) => (
    <div className='list-actions'>
        {allowEditSource && (
          <>
            <Button
              color='link'
              className='action-item inline'
              title='Settings'
              onClick={() => {
                onEditClick(source);
              }}
            >
              <FaIcon icon='cog' size='1x' />
            </Button>
            <Button
              color='link'
              className='action-item inline'
              title='Source-Tokens'
              onClick={() => {
                onVendorClick(source);
              }}
            >
              <FaIcon icon={faKey} size='1x' />
            </Button>
          </>

        )}
        {allowViewRules && (
            <Button
                color='link'
                className='action-item inline'
                title='Source routing rules'
                onClick={() => {
                    onRulesClick(source);
                }}
            >
                <FaIcon icon='clipboard-list' size='1x' />
            </Button>
        )}
        {allowViewAuction && (
          <>
            <Link
              className='action-item'
              to={`/auctions?page=1&source=${source.id}`}
              title='Auction List'
              target="_blank"
            >
              <FaIcon icon='comment-dollar' size='1x' />
            </Link>
            <Button
              color='link'
              onClick={() => {
                onRoutingClick(source.id);
              }}
              title='Routing Map'
              className='action-item inline'
            >
              <FaIcon icon='project-diagram' size='1x' />
            </Button>
          </>

        )}
    </div>
);


const BidCell = ({ vendor_bid, vendor_margin }) => {
    // bid takes precedence over margin
    if (vendor_bid) {
        return (
            <NumberFormat
                value={vendor_bid}
                displayType='text'
                thousandSeparator
                prefix='$'
                decimalScale={2}
                fixedDecimalScale
            />
        );
    }
    if (vendor_margin) {
        return <span>{vendor_margin}%</span>;
    }
    return <span>&mdash;</span>;
};

const ChannelsCell = ({ channels, sourceId }) => {
    // channels, actually channel_eligibilities
    const [showData, setShowData] = useState(false);
    return channels?.length > 0 ? (
        <div className='source-channels-cell'>
            <Button
                color='link'
                className='fw-bolder inline'
                onClick={() => {
                    setShowData(!showData);
                }}
            >
                {channels.length}{' '}
                {singularOrPlural(channels.length, 'channel', 'channels')}
                <FaIcon
                    icon={showData ? 'chevron-down' : 'chevron-right'}
                    size='sm'
                    className='ms-1'
                />
            </Button>

            {showData &&
                channels.map((c, i) => (
                    <div className='source-channels mt-1' key={i}>
                        <EntityId id={c.id} title="Source ID" />
                        {c.name}
                      <Link
                        to={`/publishing/channels?page=1&&limit=50&sort_by=name&sort_dir=asc&source_id=${sourceId}`}
                        title='Channels List'
                        target="blank"
                      >
                         <FaIcon icon='external-link-alt' size='sm' className="ms-1" />
                      </Link>

                        {c.distribution > 0 && (
                            <span>
                                {' '}
                                - {c.distribution}%
                                {c.distribution_fixed && ' (fixed)'}
                            </span>
                        )}
                    </div>
                ))}
        </div>
    ) : (
        <span>&mdash;</span>
    );
};

const TokensCell = ({ tokens }) => {
    const [showData, setShowData] = useState(false);
    const nonArchived = tokens.filter(t => t.status !== 'archived');
    return nonArchived?.length > 0 ? (
        <div className='source-tokens-cell'>
            <Button
                color='link'
                className='fw-bolder inline'
                onClick={() => {
                    setShowData(!showData);
                }}
            >
                {nonArchived.length}{' '}
                {singularOrPlural(nonArchived.length, 'vendor', 'vendors')}
                <FaIcon
                    icon={showData ? 'chevron-down' : 'chevron-right'}
                    size='sm'
                    className='ms-1'
                />
            </Button>

            {showData &&
                nonArchived.map((st, i) => (
                    <div className='source-tokens mt-1' key={i}>
                        <EntityId id={st.vendor.id} title="Vendor ID" />
                        {st.vendor.name}
                    </div>
                ))}
        </div>
    ) : (
        <span>&mdash;</span>
    );
};

const Table = (props) => {
    // default sort by name asc, all sortable except actions
    const allowEditSource = usePermission('sources.edit');
    const allowViewRules = usePermission('routing.view');
    const allowViewAuction = usePermission('auctions.view');
    const [hiddenColumns, setHiddenColumns] = useLocalStorage('hidden_columns_sources', DefaultHiddenColumns)

    const columns = useMemo(
        () => [
            {
                Header: 'ID',
                className: 'id-cell',
                id: 'id',
                accessor: 'id',
                defaultCanSort: false,
                Cell: ({value}) => (
                  <EntityId id={value} title="Source ID" />
                ),
                maxWidth: 60
            },
            {
                Header: 'Name',
                className: 'name-cell',
                id: 'name', // used to id sort
                accessor: 'name',
                defaultCanSort: true,
                Cell: (c) => (
                    <NameCell
                        name={c.value}
                        description={c.row.original.description}
                    />
                ),
                minWidth: 200
            },
            /*     {Header:'Description', accessor: 'description', sortable:false }, */
            /* {Header:'Vendor',accessor:'vendor.name', sortable:true, maxWidth:180}, */
            {
                Header: 'Vertical',
                accessor: 'vertical.display_name',
                defaultCanSort: true,
                id: 'vertical',
                width: 120,
            },
            {
                Header: 'Product',
                accessor: 'product',
                id: 'product',
                defaultCanSort: true,
                Cell: (c) => titleCaseWord(c.value),
                width: 100,
            },
            {
                id: 'bid-or-margin',
                Header: 'Bid/Margin',
                width: 110,
                className: 'bid-margin',
                disableSortBy: true,
                Cell: (c) => (
                    <BidCell
                        vendor_bid={c.row.original.vendor_bid}
                        vendor_margin={c.row.original.vendor_margin}
                    />
                ),
            },
            {
                Header: 'Status',
                accessor: 'status',
                defaultCanSort: true,
                width: 90,
                className: 'status-cell',
                Cell: (c) => (
                  <StatusToggler
                    status={c.value}
                    target={c.row.original}
                    onChangeAsync={props.onStatusChange}
                    allowEdit={allowEditSource}
                  />

                ),
            },
            {
                Header: 'Seed Data',
                disableSortBy: true,
                accessor: 'seed_data',
                Cell: ({ value }) => <SeedDataCell seedData={value} />,
                width: 100,
            },
            {
                Header: 'Channels',
                disableSortBy: true,
                accessor: 'channels',
                Cell: ({ value, row }) => <ChannelsCell channels={value} sourceId={ row.original.id} />,
                width: 170,
            },
            {
                Header: 'Tokens',
                disableSortBy: true,
                accessor: 'tokens',
                Cell: ({ value }) => <TokensCell tokens={value} />,
                width: 170,
            },
            {
                Header: 'Created',
                accessor: 'inserted_at',
                defaultCanSort: true,

                Cell: ({ value }) => <RelativeTime date={value} />,
            },
          {
            Header: 'Updated',
            accessor: 'modified_at',
            defaultCanSort: true,

            Cell: ({ value }) => <RelativeTime date={value} />,
          },
          {
            Header: 'Last Match',
            accessor: 'last_matched_at',
            defaultCanSort: true,

            Cell: ({ value }) => <RelativeTime date={value} />,
          },
          {
            Header: 'Last Sale',
            accessor: 'last_sold_at',
            defaultCanSort: true,

            Cell: ({ value }) => <RelativeTime date={value} />,
          },
            {
                width: 150,
                disableSortBy: true,
                id: 'actions',
                Cell: (c) => (
                    <ActionCell
                        source={c.row.original}
                        onEditClick={props.openEditModal}
                        onRulesClick={props.openRulesModal}
                        onRoutingClick={props.openRoutingModal}
                        onVendorClick={props.openVendorModal}
                        allowEditSource={allowEditSource}
                        allowViewRules={allowViewRules}
                        allowViewAuction={allowViewAuction}
                    />
                ),
            },
        ],
        []
    );

  const columnPicks = useMemo(() => columns.reduce((acc, col) => {
      if (col.id !== 'actions') {
        acc.push({id:col.accessor ?? col.id, label: col.Header})
      }
      return acc
    }, []),
    [])

    const initialTableState = useMemo(
        () => ({
            sortBy: [
                {
                    id: props.sort_by,
                    desc:
                        typeof props.sort_dir !== 'undefined'
                            ? props.sort_dir === 'desc'
                            : true,
                },
            ],
            hiddenColumns
        }),
        []
    );

    return (
      <>
        <div className="mb-1">
          <ColumnSelect
            columns={columnPicks}
            hiddenColumns={hiddenColumns}
            onChange={setHiddenColumns}
            loading={props.loading}
          />
        </div>
        <PagedTable
          columns={columns}
          initialState={initialTableState}
          className='sources-table striped'
          {...props}
          noDataMessage='No sources were found'
          hiddenColumns={hiddenColumns}
        />
      </>

    );
};

Table.propTypes = {
    loading: PropTypes.bool,
    data: PropTypes.array,
    totalRows: PropTypes.number,
    pageCount: PropTypes.number,
    page: PropTypes.number,
    sort_by: PropTypes.string,
    sort_dir: PropTypes.string,
    limit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    attributes: PropTypes.object,
    setPage: PropTypes.func,
    setLimit: PropTypes.func,
    setSort: PropTypes.func,
    openEditModal: PropTypes.func,
    openRulesModal: PropTypes.func,
    openRoutingModal: PropTypes.func,
    openVendorModal: PropTypes.func,
    onStatusChange: PropTypes.func,
};

export default Table;
