import React, {FC} from 'react';
import {FontAwesomeIcon as FaIcon} from "@fortawesome/react-fontawesome";
import {FlowNodeProps} from "./index";

type InfoNodeData = {
  message?:string|React.ReactNode,
  infoIcon?:boolean
}
type InfoNodeProps = FlowNodeProps<InfoNodeData>

export const InfoNode:FC<InfoNodeProps> = ({data}) => {
  const {message, infoIcon} = data
  return (
    <div className="node info d-flex">
      {infoIcon && <div className="me-1"><FaIcon icon="info-circle"/></div>}
      <div>{message}</div>
    </div>
  )
}
