import { useState, useEffect } from 'react';
import {VerticalType} from "../types";
import useApi from "./useApi";

/*
 * Helper to translate a vertical id to name or display name
 */
export const useVerticals = () => {
  const {api} = useApi();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [verticals, setVerticals] = useState<VerticalType[]>([])

  useEffect(() => {
    load();
  }, [])

  const load = async () => {
    const result = await api.endpoints.verticals.list();
    if (result) {
      setVerticals(result);
    }
    setLoaded(true);
  }

  const getDisplayName = (verticalId:number) => {
    const vert = verticals.find((v) => v.id.toString() === verticalId.toString())
    return vert ? vert.display_name : ''
  }

  const getName = (verticalId:number) => {
    const vert = verticals.find((v) => v.id.toString() === verticalId.toString())
    return vert ? vert.name : ''
  }


  return {
    loaded,
    verticals,
    getDisplayName,
    getName
  }
}


