
type UserFiltersOptions = {
  defaultFilters?:Record<string, any>,
  keepFilters?:string[]
}

export const useFilters = (
  filters:Record<string, any>,
  onChangeCallback:(filters:Record<string, any>) => void,
  options:UserFiltersOptions={}
) => {

  const onChangeFilter = (param:string, value:any) => {
    const newValues = { ...filters };
    if (Array.isArray(value) && !value.length) value = null;

    if (value) {
      newValues[param] = value;
    } else if (param in filters) {
      delete newValues[param];
    }

    onChangeCallback(newValues);
  };

  const clearFilter = (filter:string) => {
    const newValues = { ...filters };
    if (typeof newValues[filter] !== undefined) {
      delete newValues[filter];
      onChangeCallback(newValues);
    }
  };
  const clearSearch = () => onChangeFilter('search', '');

  const reset = () => {
    let newFilters:Record<string, any> = {}
    const defaults = options.defaultFilters ?? {}
    if (options.keepFilters) {
      newFilters = { ...defaults }
      options.keepFilters.forEach((filter) => {
        if (filters[filter]) newFilters[filter] = filters[filter]
      })
    }
    onChangeCallback(newFilters ?? defaults)
  }

  return {
    clearFilter,
    clearSearch,
    reset,
    onChangeFilter
  }
}

