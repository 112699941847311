import React, { useEffect, useState, useContext, useMemo } from 'react';
import {
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  Button,
  FormFeedback,
  InputGroup,
  Nav,
  TabPane,
  TabContent
} from 'reactstrap';
import {BodyWrapper, FooterWrapper} from "../../../../Components/Modal";
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Required } from '../../../../Components/Form/FormCommon';
import useFormState from '../../../../Hooks/useFormState';
import LoadingBar from '../../../../Layout/LoadingBar';
import DynamicEnhancedSelect from "../../../../Components/Form/DynamicEnhancedSelect";
import {ChannelSources} from "./ChannelSources";
import { AppContext } from '../../../../Providers/AppProvider';
import {notify, StandardAlert, Utils, TabNav} from "@thedmsgroup/mastodon-ui-components";
import EntityId from "../../../../Components/Table/EntityId";

const ValidationSchema = Yup.object().shape({
  name: Yup.string().trim().required('Please enter a channel name'),
  product: Yup.string().trim().required('Please select a product'),
  vertical_id: Yup.number().transform((n) => (isNaN(n) ? 0 : n)).moreThan(0, 'Please select a vertical'),
  account_id: Yup.number().transform((n) => (isNaN(n) ? 0 : n)).moreThan(0, 'Please select an account'),
});

/*
Component with form for editing/creating a Channel
 */
const Channel = ({
                   channelId,
                   close,
                   onSuccess,
                   onOpenChannelRules,
                   onOpenChannelSourceRules,
                   initialTab='details',
                   isModal
}) => {
  const app = useContext(AppContext);

  const [activeTab, setActiveTab] = useState(initialTab )
  const [loading, setLoading] = useState(channelId > 0)
  const [channel, setChannel] = useState(null)
  const {
    formApi, formIsValid, formValues, formErrors,
  } = useFormState(
    {
      id: channelId ?? 0,
      name: '',
      description: '',
      product: '',
      vertical_id: 0,
      account_id: 0,
      status: 'active',
      sources:[]
    },
    ValidationSchema,
  )

  const isNew = !channelId;

  useEffect(
    () => {
      if (!isNew && !channel) {
        load()
      }

    }, []);

  const handleSelectAccount = (id) => formApi.setValue('account_id', id ?? "")
  const handleSelectVertical = (id) => formApi.setValue('vertical_id', id ?? "")
  const handleChangeSources = (sources) => formApi.setValue('sources', sources)


  const handleSave = () => {
    const isValid = formApi.validate()
    if (isValid) {
      save()
    }
  };

  const load = async () => {

    const result = await app.api.endpoints.channels.show(channelId);

    if (!result) {
      notify(`Unable to load channel: ${app.api.error.name}`, 'error')
    } else {
     setChannel(result); //do we need this or can we work from form?
     formApi.setValues(result)
    }
    setLoading(false);
  };

  const save = async () => {
    app.showLoader('modal', isNew ? 'Creating channel' : 'Updating channel');

    const ch = { ...formValues }

    //translate source picks into eligibilities
    const channelSources = ch.sources.map((src) => {
      return {
        source_id: src.id,
        channel_id: channelId,
        status: src.eligibility.status,
        distribution: src.eligibility.distribution,
        fixed_distribution: src.eligibility.fixed_distribution,
      }
    })

    let result = null;

    if (ch.id) {
      ch.sources = channelSources
      result = await app.api.endpoints.channels.update(ch)
    } else {
      //Need to create the channel first to get the ID, then set it in the sources
      ch.sources = []
      result = await app.api.endpoints.channels.create(ch)
      if (result) {
        ch.id = result.id
        ch.sources = channelSources.map((cs) => {
          return {...cs, channel_id: result.id}
        })
        result = await app.api.endpoints.channels.update(ch)
      }
    }

    app.showLoader(false);

    if (!result) {
      notify(`Unable to save channel: ${app.api.error.name}`, 'error')
    } else {
      notify(`The channel was saved`, 'success')
     onSuccess && onSuccess(result, isNew);
     close()
    }
  };

  // This awkwardness is because ChannelSources requires a channel-like object
  // with a product and vertical
  // but in creation mode we just have the form values, so here we make the form values
  // looks like the channel object
  const formValuesAsChannel = useMemo(() => {
    if (isNew) {
      return {
        id: 0,
        vertical: {id: formValues.vertical_id},
        product: formValues.product,
        account: { id: formValues.account_id },
        status: formValues.status,
        sources:formValues.sources
      }
    }
    return channel;
  }, [formValues])

  return (
    <>
    <BodyWrapper isModal={isModal}>
      <LoadingBar name="modal" active={loading} />

      {!formIsValid
            && (
            <StandardAlert color="warning" className="alert-form-validation">
              <FaIcon icon="exclamation-triangle" size="lg" />
              {' '}
              Please correct the errors below
              before continuing
            </StandardAlert>
            )}

      <Nav className="mb-3 mastodon-tabs">
        <TabNav
          isActive={activeTab === 'details'}
          setActive={setActiveTab}
          id="details"
        >
          Details
        </TabNav>
        <TabNav
          isActive={activeTab === 'sources'}
          setActive={setActiveTab}
          id="sources"
        >
          Sources
        </TabNav>
      </Nav>

      <TabContent activeTab={activeTab}>
        <TabPane tabId="details">
          <div>
            <Row>
              <Col sm={12}>
                <FormGroup>
                  <Label>
                    Name
                    {' '}
                    <Required />
                  </Label>
                  <Input
                    type="text"
                    name="name"
                    onChange={formApi.handleOnChange}
                    value={formValues.name}
                    invalid={!!formErrors.name}
                  />
                  <FormFeedback>{formErrors.name}</FormFeedback>
                </FormGroup>

                <FormGroup>
                  <Label>
                    Description
                  </Label>

                  <Input
                    type="textarea"
                    name="description"
                    onChange={formApi.handleOnChange}
                    value={formValues.description}
                  />
                </FormGroup>

                <FormGroup>
                  <Label>
                    Account { isNew && <Required />}
                  </Label>

                  { isNew && (
                      <DynamicEnhancedSelect
                        onChange={handleSelectAccount}
                        value={formValues.account_id}
                        name="account_id"
                        endpoint="accounts"
                        params={{options:true}}
                        labelProperty="name"
                        className="w-100"
                        invalid={!!formErrors.account_id}
                        invalidFeedback={formErrors.account_id}
                        isClearable
                        isSearchable
                      />
                  )}

                  {/* Read only text for existing order */}
                  { !isNew && channel && <div><EntityId id={channel.account.id} /> {channel.account.name}</div>}

                </FormGroup>

                <FormGroup>
                  <Label>
                    Vertical
                    {' '}
                    { isNew && <Required />}
                  </Label>
                  { isNew && (

                    <DynamicEnhancedSelect
                      onChange={handleSelectVertical}
                      value={formValues.vertical_id}
                      name="vertical_id"
                      endpoint="verticals"
                      params={{options:true}}
                      labelProperty="display_name"
                      className="w-100"
                      invalid={!!formErrors.vertical_id}
                      invalidFeedback={formErrors.vertical_id}
                      isClearable
                      isSearchable
                    />

                  )}

                  {/* Read only for existing channel */}
                  { !isNew && channel && <div><EntityId id={channel.vertical.id} /> {channel.vertical.display_name}</div>}
                </FormGroup>

                <FormGroup>
                  <Label>
                    Product { isNew && <Required />}
                  </Label>
                  { isNew && (

                    <InputGroup>
                      <Input
                        type="select"
                        onChange={formApi.handleOnChange}
                        defaultValue={formValues.product}
                        name="product"
                        invalid={!!formErrors.product}
                      >
                        <option value="">Select...</option>
                        <option value="clicks">Clicks</option>
                        <option value="calls">Calls</option>
                        <option value="leads">Leads</option>
                      </Input>
                      <FormFeedback>{formErrors.product}</FormFeedback>
                    </InputGroup>

                  )}

                  {/* Read only for existing channel */}
                  { !isNew && channel && <div>{Utils.titleCase(channel.product)}</div>}

                  <FormFeedback>{formErrors.product}</FormFeedback>
                </FormGroup>

                {/* status for existing channel (new ones set active by default) */}
                { !isNew && (
                  <FormGroup>
                    <Label>
                      Status
                    </Label>
                    <InputGroup>
                      <Input
                        type="select"
                        onChange={formApi.handleOnChange}
                        value={formValues.status}
                        name="status"
                      >
                        <option value="active">Active</option>
                        <option value="archived">Archived</option>
                      </Input>
                    </InputGroup>
                  </FormGroup>

                )}
                { !isNew && onOpenChannelRules && (
                  <FormGroup>
                    <Label>
                      Routing Rules
                    </Label>

                    <div>
                      <Button color="link" className="inline me-3" onClick={()=>onOpenChannelRules(channel)} title="Channelrouting rules">
                        <FaIcon icon="clipboard-list" size="sm" />
                        {" "}
                        Edit Routing Rules for Channel
                      </Button>
                    </div>
                  </FormGroup>
                )}
              </Col>
            </Row>

          </div>
        </TabPane>
        <TabPane tabId="sources"  style={{minHeight:'400px'}}>

            <ChannelSources
              channel={formValuesAsChannel}
              sources={formValues.sources}
              onChange={handleChangeSources}
              onOpenRules={onOpenChannelSourceRules}
            />


        </TabPane>
      </TabContent>

    </BodyWrapper>
      <FooterWrapper isModal={isModal}>
          <Button
            onClick={close}
            color="link"
            size="sm"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            color="primary"
            size="sm"
          >
            Save
          </Button>

      </FooterWrapper>
    </>
  );
};

Channel.propTypes = {
  close: PropTypes.func.isRequired,
  //channel: PropTypes.object.isRequired,
  channelId: PropTypes.number.isRequired,
  onSuccess: PropTypes.func,
  tab:PropTypes.string
};

export default Channel;
