import React, {FC, useState} from 'react'
import {Row, Col, Button, Label, Spinner} from 'reactstrap'
import useApi from "../../../../Hooks/useApi"
import DynamicEnhancedSelect from "../../../../Components/Form/DynamicEnhancedSelect"
import {notify, Stack, RowDivider} from "@thedmsgroup/mastodon-ui-components";
import EntityId from "../../../../Components/Table/EntityId";
import {FontAwesomeIcon as FaIcon} from "@fortawesome/react-fontawesome";
import {BodyWrapper, FooterWrapper} from "../../../../Components/Modal";

type SourceTokenStepProps = {
  source:any;
  vendors:any[],
  onSuccess?:(source:any)=>void,
  cancel:()=>void,
  isModal?:boolean
}
export const SourceTokenStep:FC<SourceTokenStepProps> = ({
  source,
  vendors,
  onSuccess,
  cancel,
  isModal
}) => {
  if(!source) return null
  const {api} = useApi()
  const [vendorList, setVendorList] = useState<any[]>(vendors) //state for list
  const [selectedVendor, setSelectedVendor] = useState<any>() //state for selector
  const [isSaving, setIsSaving] = useState(false)

  const handleSelectVendor = (selectedId:string, selectedOption:any) => {
    setSelectedVendor(selectedOption);
  }

  const handleAddNew = () => {

    if (selectedVendor) {
      if (!vendorList.find((vendor:any) => vendor.id === selectedVendor.id)) {
        setVendorList((prev) => [...prev, selectedVendor])
      }
    }
    //clear selector dropdown
    setSelectedVendor(undefined)

  }

  const handleRemove = (index:number) => {
    const newVendors = [...vendorList]
    newVendors.splice(index, 1)
    setVendorList(newVendors)
  };

  const save = async() => {

    setIsSaving(true)

    //No batch save on API side
     let batchResult = true;
      for (const vendor of vendorList) {
        const tokenResult = await saveToken(source.id, vendor.id);
        if (!tokenResult) {
          batchResult =false
        }
        console.log("SourceTokenStep.tsx:saving for vendor and source", source, vendor );
      }


    if (batchResult) {
      if (vendorList.length > 1) {
        notify(`The source-tokens have been saved`, 'success')
      } else {
        notify(`The source-token has been saved`, 'success')
      }

      setIsSaving(false)
      onSuccess && onSuccess(source)

    } else {
      notify(`Unable to create source token for vendor: ${api.error ? api.error.name : ""}`, 'error');
    }
    setIsSaving(false)

  }

  const saveToken = async(sourceId:number|string, vendorId:number|string) => {

      const result = await api.endpoints.sourceTokens.create({
        source_id: sourceId,
        vendor_id: vendorId,
        status: 'active'
      })
    return Boolean(result);
  }

  return (
   <>
   <BodyWrapper isModal={isModal}  className='source-token-step'>


     <p>Optionally add tokens for one or more vendors to use this source.</p>
     <Button color="link" size="xs" onClick={cancel}>Skip this step</Button>


      <Row className="my-2">

        <Col sm={12} md={6} lg={3}>
          <h5>Source: <EntityId id={source.id} /> {source.name}</h5>
          {vendorList.length > 0 && <h5>Vendors</h5>}
          <Stack gap="1em" divider={<RowDivider/>}>
            {vendorList.map((vendor:any, i:number) => (
              <div key={i} className="selected-vendor d-flex align-items-center justify-content-between">
                <div><EntityId id={vendor.id} /> {vendor.name}</div>
                <Button onClick={()=>handleRemove(i)} color="link" size="sm" className="inline" title="Remove">
                  <FaIcon icon="times" size="sm" color="darkred"/>
                </Button>
              </div>
            ))}
          </Stack>
        </Col>

      </Row>
      <Row className="mt-3">
        <Col sm={12} md={6} lg={4}>
          <Row className="align-items-center">
            <Col sm={9}>
              <DynamicEnhancedSelect
                onChange={handleSelectVendor}
                value={selectedVendor?.id ?? ''}
                name="vendor"
                endpoint="vendors"
                params={{options:true}}
                labelProperty="name"
                className="w-100"
                placeholder="Select vendor..."
                returnOption
                //invalid={!!formErrors.account_id}
                // invalidFeedback={formErrors.account_id}
                isClearable
                isSearchable
                isMulti={false}
              />
            </Col>
            <Col sm={3}>
              <div>
                <Button onClick={handleAddNew}  size="sm" className="inline" title="Add" disabled={!selectedVendor || isSaving}>
                  <FaIcon icon="plus" size="sm" /> Add
                </Button>
              </div>

            </Col>
          </Row>
        </Col>
      </Row>
      </BodyWrapper>

      <FooterWrapper isModal={isModal}>
        <Button
          onClick={cancel}
          color="link"
          size="sm"
        >
          Cancel
        </Button>
        <Button
          onClick={save}
          color="primary"
          size="sm"
          disabled={!vendorList.length || isSaving}
        >
          Save{isSaving && <Spinner size="sm" className="ms-1" /> }
        </Button>

      </FooterWrapper>

    </>
  )
}
