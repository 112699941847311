import React, {FC} from 'react';
import {FontAwesomeIcon as FaIcon} from "@fortawesome/react-fontawesome";
import {Handle} from "@xyflow/react";
import {FlowNodeProps} from "./index";
import {HelpPopper} from "@thedmsgroup/mastodon-ui-components";


type AlertNodeData = {
  message?:string|React.ReactNode,
  helpText?:string|React.ReactNode,
}
type AlertNodeProps = FlowNodeProps<AlertNodeData>


export const AlertNode:FC<AlertNodeProps> = ({id, data, targetPosition}) => {
  const {message, helpText} = data
  return (
    <div className="node alert d-flex align-items-center">
      {targetPosition && <Handle id="target-handle" type="target" position={targetPosition} />}
      <div className="node-header">
        <div className="node-title flex-fill">
          <div><FaIcon icon="exclamation-triangle" className="me-1"/></div>
          <div className="flex-fill">
            {message}{helpText && <HelpPopper iconClass="ms-2" name={`alert-help-${id}`}>{helpText}</HelpPopper>}
          </div>
        </div>
      </div>



    </div>
  )
}
