import React, {useState, useEffect} from 'react'
import {
  Row, Col, FormGroup,FormFeedback, FormText, Input, InputGroup, Label, Button, Spinner,
} from "reactstrap"
import * as Yup from 'yup'
import useFormState from '../../../Hooks/useFormState'
import EnhancedSelect from "../../../Components/Form/EnhancedSelect"
import {CopyButton, StandardAlert, notify, JsonEditorLoadable} from "@thedmsgroup/mastodon-ui-components"
import useApi from "../../../Hooks/useApi"
import EntityId from "../../../Components/Table/EntityId"
import {SourceOptionLabel} from "../../../Components/Form/Select/SourceOptionLabel"
import {EntityOptionLabel} from "../../../Components/Form/Select/EntityOptionLabel"
import {BodyWrapper, FooterWrapper} from "../../../Components/Modal";
//import SeedDataBuilder from "../../../Components/Form/SeedDataBuilder"
//import {JsonEditor} from "jsoneditor-react";

const ValidationSchema =  Yup.object().shape({
  source_id: Yup.number()
    .transform((n) => (isNaN(n) ? 0 : n))
    .moreThan(0, 'A source is required'),
  vendor_id: Yup.number()
    .transform((n) => (isNaN(n) ? 0 : n))
    .moreThan(0, 'A vendor is required'),
  token: Yup.string().trim().test('token-for-existing', 'A token value is required', function test(value){
    // if new source-token, allow blank token, else require a value
    const {isNew} = this.options.context;
    return isNew || Boolean(value);
  })
})


export const SourceTokenForm  = ({sourceToken, title, close, onSuccess, isModal}) => {
  const isNew = !sourceToken?.id;
  const {api} = useApi();

  const [vendorChoices, setVendorChoices] = useState([])
  const [sourceChoices, setSourceChoices] = useState([])
  const [isSaving, setIsSaving] = useState(false);
  const [selectedSource, setSelectedSource ] = useState();

  const {
    formApi, formIsValid, formValues, formErrors,
  } = useFormState(
    {
      id: sourceToken.id,
      label: sourceToken.label,
      description: sourceToken.description,
      source_id:( (sourceToken?.source?.id) || '').toString(),
      vendor_id: ((sourceToken?.vendor?.id) || '').toString(),
      seed_data: Array.isArray(sourceToken.seed_data) ? "{}" : JSON.stringify(sourceToken.seed_data), // php quirk, empty object = array
      post_type: sourceToken.post_type,
      status: sourceToken.status,
      token: sourceToken.token
    },
    ValidationSchema,
    null,
    null,
    {isNew}
  );


  //Reset form on unmount
  useEffect(() => {
    loadChoices();
    return () => formApi.clearForm()
  }, [])

  const loadChoices = async() => {
    const sources = await api.endpoints.sources.list({options:true})
    if (sources) {
      //we need to know source vertical id for the seed data builder
      setSourceChoices(sources.map(source => {
        return {value:source.id, label:source.name, status:source.status, vertical:source.vertical, product:source.product}
      }))

      if (sourceToken.source?.id) {
        setSelectedSource(() => {
          return sources.find(s => s.id.toString() === sourceToken.source.id.toString())
        })
      }
    }

    const vendors = await api.endpoints.vendors.list()
    if (vendors) {
      setVendorChoices(vendors.map(vendor => {
        return {value:vendor.id, label:vendor.name}
      }))
    }

  }

  const handleSave = () => {
    const isValid = formApi.validate()
    if (isValid) {
      save()
    }
  };

  const save = async() => {
    setIsSaving(true)
    const params = {...formValues}

    if (params.seed_data === "") {
      params.seed_data = "{}"
    }
    // params.seed_data = params.seed_data && Object.keys(params.seed_data).length > 0
    //   ? JSON.stringify(params.seed_data)
    //   : null

    let result
    if (isNew) {
      delete params.id
      result = await api.endpoints.sourceTokens.create(params)
    } else {
      result = await api.endpoints.sourceTokens.update(params)
    }

    if (result) {
      notify(`The source-token has been saved`, 'success')
      setIsSaving(false)
      onSuccess && onSuccess()
      close()
    } else {
      notify(`Unable to save vendor to source: ${api.error ? api.error.name : ""}`, 'error');
    }
    setIsSaving(false)
  }

  const handleUpdateSeedData = (obj) => formApi.setValue('seed_data', obj)
  const handleSelectSource = (id) => {
    formApi.setValue('source_id', id)
    // pull the source from the source choices and put it into state
    // (data needed for seed data builder)
    setSelectedSource( () => {
      return id ? sourceChoices.find(s => s.value.toString() === id.toString()) : null
    })
  }
  const handleSelectVendor = (id) => {
    formApi.setValue('vendor_id', id)
  }


  return (
    <>

        <BodyWrapper isModal={isModal}>
          <div className="form-section border source-token-form">
            {title && <div className="form-section-header">{title}</div>}

            {!formIsValid
            && (
              <StandardAlert color="warning" icon="alert" className="alert-form-validation">
                Correct the errors indicated below
              </StandardAlert>
            )}

            <Row>
              <Col sm={12} lg={10}>
                {/*<FormGroup>
                  <Label>Vendor</Label>
                  <div><EntityId id={vendor.id} /> {vendor.name}</div>
                </FormGroup>*/}

                {/* if provided a vendor, that means we are picking a source (vendor static) */}
                <FormGroup>
                  <Label>Vendor</Label>
                  {isNew ? (
                      <EnhancedSelect
                        value={formValues.vendor_id}
                        options={vendorChoices}
                        controlShouldRenderValue
                        isMulti={false}
                        isSearchable
                        onChange={handleSelectVendor}
                        formatOptionLabel={EntityOptionLabel}
                        isInvalid={!!formErrors.vendor_id}
                        invalidFeedback={formErrors.vendor_id}
                      />

                  ) : (
                    <div>
                      <EntityId id={sourceToken.vendor.id} /> {sourceToken.vendor.name}
                    </div>
                  )}
                </FormGroup>

                {/* if provided a source, that means we are picking a vendor (source static) */}
                <FormGroup>
                  <Label>Source</Label>
                  {isNew ? (
                      <EnhancedSelect
                        value={formValues.source_id}
                        options={sourceChoices}
                        controlShouldRenderValue
                        isMulti={false}
                        isSearchable
                        onChange={handleSelectSource}
                        formatOptionLabel={SourceOptionLabel}
                        isInvalid={!!formErrors.source_id}
                        invalidFeedback={formErrors.source_id}
                      />
                  ) : (
                    <div>
                      <EntityId id={sourceToken.source.id} /> {sourceToken.source.name}
                    </div>
                  )}
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col sm={12} lg={8}>
                <FormGroup>
                  <Label>
                    Token
                  </Label>
                  <InputGroup>
                    <Input
                      type="text"
                      name="token"
                      value={formValues.token}
                      onChange={formApi.handleOnChange}
                      invalid={formErrors.token}
                    />

                      <CopyButton
                        id="copy-token"
                        textToCopy={formValues.token}
                        buttonSize="xs"
                      >
                        Copy
                      </CopyButton>

                    <FormFeedback>{formErrors.token}</FormFeedback>
                  </InputGroup>
                  {isNew && <FormText>If blank, a token will be created automatically</FormText>}

                </FormGroup>

                <FormGroup>
                  <Label>
                    Label
                  </Label>
                  <Input
                    type="text"
                    name="label"
                    value={formValues.label}
                    onChange={formApi.handleOnChange}
                  />
                </FormGroup>

                <FormGroup>
                  <Label>
                    Description
                  </Label>
                  <Input
                    type="text"
                    name="description"
                    value={formValues.description}
                    onChange={formApi.handleOnChange}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col sm={12} lg={3}>
                <FormGroup>
                  <Label>
                    Status
                  </Label>
                  <InputGroup>
                    <Input
                      type="select"
                      onChange={formApi.handleOnChange}
                      value={formValues.status}
                      name="status"
                    >
                      <option value="active">Active</option>
                      <option value="paused">Paused</option>
                      {!isNew && <option value="archived">Archived</option>}
                    </Input>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col sm={12} lg={3}>
                <FormGroup>
                  <Label>
                    Post Type
                  </Label>
                  <InputGroup>
                    <Input
                      type="select"
                      onChange={formApi.handleOnChange}
                      value={formValues.post_type}
                      name="post_type"
                    >
                      <option value="">Any</option>
                      <option value="ping_post">PingPost</option>
                      <option value="direct_post">Direct Post</option>
                    </Input>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>


            {/* need to set vertical ID based on source vertical */}
            <FormGroup>
              <Label>Seed Data</Label>
              {/*{selectedSource ? (*/}
              {/*  <SeedDataBuilder*/}
              {/*    seedData={formValues.seed_data}*/}
              {/*    verticalId={selectedSource.vertical.id}*/}
              {/*    onUpdate={handleUpdateSeedData}*/}
              {/*    view={formValues.seed_data ? 'json' : 'form'}*/}
              {/*  />*/}
              {/*) : (*/}
              {/*  <StandardAlert type="info">Select a source before setting seed data</StandardAlert>*/}
              {/*)}*/}

              <JsonEditorLoadable
                code={formValues.seed_data}
                onChange={handleUpdateSeedData}
              />

            </FormGroup>
          </div>
        </BodyWrapper>

        <FooterWrapper isModal={isModal} className="text-end">
          <Button
            color="link"
            size="sm"
            onClick={close}
            disabled={isSaving}
          >
            Cancel
          </Button>
          <Button
            size="sm"
            onClick={handleSave}
            disabled={isSaving}
            className="ms-1"
          >
            Save {isSaving && <Spinner size="sm" color="primary" className="ms-1" />}
          </Button>
        </FooterWrapper>

    </>


  );
}

