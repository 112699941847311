import React, {useContext} from 'react';
import classnames from "classnames";
import {Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem} from "reactstrap";
import {RoutingFlowContext} from "./index";
import {FontAwesomeIcon as FaIcon} from "@fortawesome/react-fontawesome";
import {RelativeTime, CopyButton} from '@thedmsgroup/mastodon-ui-components';
import { Handle } from '@xyflow/react';
import {faKey} from "@fortawesome/free-solid-svg-icons";
import {StatusBadge} from "./StatusBadge";
import type {FlowNodeProps} from "./index";


type SourceTokenNodeProps = FlowNodeProps & {
  onEdit:(token:any) =>void,
  onAddForCurrentVendor:(vendor:any, newSource?:boolean) =>void,
  onAddForCurrentSource:(source:any) =>void,
  clearFilters:(vendor:any) => void
}


export const SourceTokenNode = (
  {
    //passed by react-flow
    sourcePosition,
    targetPosition,
    data,
    //Additional properties
    onEdit,
    onAddForCurrentVendor,
    onAddForCurrentSource,

  }:SourceTokenNodeProps
) => {
  const {token, alert, dataView} = data
  const context = useContext(RoutingFlowContext)

  const handleOpenToken = (e: any) => {
    e.stopPropagation()
    onEdit(token)
  }

  // const handleAddForCurrentVendor = (e: React.MouseEvent<HTMLButtonElement>) => {
  //   e.stopPropagation()
  //   onAddForCurrentVendor(context.selectedVendor)
  // }
  // const handleAddForCurrentSource = (e: React.MouseEvent<HTMLButtonElement>) => {
  //   e.stopPropagation()
  //   if (token.source) {
  //     onAddForCurrentSource(token.source)
  //   }
  //
  // }

  // Create a token related to a vendor or source
  const handleAddForTarget = (e:React.MouseEvent<HTMLElement>, forNewSource:boolean=false) => {
    e.stopPropagation()
    if (context.selectedVendor) {
      onAddForCurrentVendor(context.selectedVendor, forNewSource)
    } else if (token.source) {
      onAddForCurrentSource(token.source)
    }
  }

  return (

    <div  className={classnames("node source-token", token.status ?? 'active', {alert})}>

      <StatusBadge status={token.status} />
      <Handle id="target-handle" type="target" position={targetPosition} />
      <Handle id={'source-handle'} type="source" position={sourcePosition} />

        <div className="node-header">

          {token.id > 0 && (
            <div className="icon-col">
              <FaIcon icon={alert ? 'exclamation-triangle' : faKey} size="sm"/>
            </div>
          )}

          <div className="node-title flex-fill">
            {token.id > 0 ? (
              <>

                <div className="d-flex align-items-center">
                  <div className="token">
                    {token.token}
                  </div>
                </div>
                <div className="actions">
                  <CopyButton
                    id={`copy-token-${token.id}`}
                    textToCopy={token.token}
                    buttonSize="sm"
                  />
                  <Button color="link" size="sm" onClick={handleOpenToken} title="View/Edit Token">
                    <FaIcon icon="cog" size="sm"/>
                  </Button>
                </div>
              </>
            ) : (
              <div className="alert-message w-100 d-flex flex-nowrap">
                {/* having no token will only happen during vendor view, when there is one vendor displayed and it has no source tokens */}
                <div><FaIcon icon="exclamation-triangle" className="me-1" size="sm"/></div>
                <div className="flex-fill"><i>No token assigned</i></div>
                {/*<Button color="link" size="xs" onClick={handleAddForTarget} title="Add source-token for selected vendor">
                  <FaIcon icon="plus" size="sm"/> Add
                </Button>*/}

                <UncontrolledDropdown>
                  <DropdownToggle  color="link" className="inline" size="sm" caret>
                    <FaIcon icon="plus" size="xs"/> Add
                  </DropdownToggle>
                  <DropdownMenu end>
                    <DropdownItem onClick={handleAddForTarget}>Add for existing source</DropdownItem>
                    <DropdownItem onClick={(e)=>handleAddForTarget(e, true)}>Create a new source</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            )}

          </div>

        </div>

      {token.id > 0 && (token.label || token.description) && (
        <div className="node-detail d-flex">

            <div className="icon-col">
              <FaIcon icon={faKey} size="sm"/>
            </div>
            <div>
              {token.label && <div>{token.label}</div>}
              {token.description && <div><small>{token.description}</small></div>}
            </div>

        </div>
      )}


        { token.id > 0 && dataView.includes("sales") && (
          <div className="node-detail d-flex">

              <div className="icon-col">
                <FaIcon icon="calendar" size="sm"/>
              </div>
              <div>
                <div>
                  <label>Match:</label> <RelativeTime date={token.last_matched_at} uniqueId={`ven-${token.id}-match`} showTimezone={true} />
                </div>
                <div>
                  <label>Sale:</label> <RelativeTime date={token.last_sold_at} uniqueId={`ven-${token.id}-sale`} showTimezone={true} />
                </div>
              </div>

          </div>
        )}


    </div>


  )
}

