import axios from 'axios';

/*
 * Adapted from: https://github.com/FrancescoSaverioZuppichini/API-Class
 * Sets up Axios client and handlers used for all APIs.
 * Creates generic list, show, create, update, and delete methods that are
 * used for many Core API endpoints.
 *
 * Instances of API classes are created in AppProvider and available through React context (AppContext)
 *
 * Usage:
 * Create class that extends the base and provide a base URL
 *  class CoreAPI extends BaseAPI {
 *    constructor() {
 *      super(process.env.API_URL_CORE, [... optional array of endpoint names...] );
 *    }
 *
 * If endpointNames array is provided, methods for list, show, update, create, and delete
 * will be created for each of them. In addition, custom methods can be created in the extended class.
 *
 * Using the core API in the global scope:
 * In components with a reference to context
 * const app = useContext(AppContext);
 * const accounts =  await app.api.endpoints.accounts.list();
 *
 * Using the useApi hook (which has its own context reference
 *    const{api} = useApi();  //core api for general usage
 *    or
 *    const apiUpdate = useApi('orders', 'update');
 *
 * Using other APIs.
 *    const app = useContext(AppContext);
 *    const Accounting = React.useMemo( () => app.getApi('accounting'), []);
 *    const result = await Accounting.updateCard(params);
 */
class BaseAPI {
  constructor(baseUrl, endpointNames = [], requestCallback) {
    this.url = baseUrl;
    this.endpoints = {};
    this.requestCallback = requestCallback;
    this.error = {};
    this.client = axios.create({
      baseURL: this.url,
    });
    this.client.interceptors.request.use(
      (request) => this.requestHandler(request),
    );
    this.client.interceptors.response.use(
      (response) => this.successHandler(response),
      (error) => this.errorHandler(error),
    );
    if (endpointNames) {
      this.createAll(endpointNames);
    }
  }

  /**
   * Create and store a single entity's endpoints
   * @param entityName string
   */
  createEntity = (entityName) => {
    // check for format (eg,'target-segments' in url, but targetSegments as object property)
    const key = this.kebabCaseToCamel(entityName);
    if (!this.endpoints.hasOwnProperty(key)) {
      this.endpoints[key] = this.createEndpoints(entityName);
    }
  };

  createEntities = (arrayOfEntity) => arrayOfEntity.map((entity) => this.createEntity(entity));

  createAll = (endpointNames) => this.createEntities(endpointNames);

  kebabCaseToCamel = (str) => str.replace(/(-\w)/g, (matches) => matches[1].toUpperCase());

  /**
   * Create the basic endpoints handlers for CRUD operations
   * Called like:
   * api.endpoints.accounts.list()
   * api.endpoints.orders.show(29)
   *
   * @param name string
   */
  createEndpoints = (name) => {
    const endpoints = {};

    // Create the basic REST endpoints
    endpoints.list = (params = {}) => this.makeRequest('get', name, params);

    endpoints.show = (id, params={}) => this.makeRequest('get', `${name}/${id}`, params);

    endpoints.create = (toCreate) => this.makeRequest('post', `${name}`, toCreate);

    endpoints.update = (toUpdate) => {
      const {id, ...rest} = toUpdate;
      return this.makeRequest("put", `${name}/${id}`, rest)
    };

    endpoints.delete = (id) => this.makeRequest('delete', `${name}/${id}`);

    return endpoints;
  };

  batchGet = async (requests) => {
    let promises = [];
    if (Array.isArray(requests) && requests.length) {
      // array of objects likes {endpoint:'vendors',action:'list', params:{param:1}}
      promises = requests.reduce((acc, { endpoint, action, params }) => {
        // console.log('API.js: endpoint, action', endpoint, action, this.endpoints[endpoint][action]);
        if (this.endpoints.hasOwnProperty(endpoint)) {
          acc.push(this.endpoints[endpoint][action](params));
        }
        return acc;
      }, []);
    }

    let result = false;
    try {
      result = await Promise.all(promises);
    } catch (error) {
      this.setError(error);
    }
    return result;
  };

  requestHandler = (request) => {
    this.clearError();

    const token = request.token || window.sessionStorage.UE_CLICKS_AUTH_TOKEN;

    if (token) {
      request.headers.Authorization = `Bearer ${token}`;
    }

    // For go-accounting service, we get a CORS error if we use content-type application/json
    // (because it triggers pre-flight request)
    if (request.url.indexOf('accounting.platform.ue.co') !== -1) {
      request.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    }

    return request;
  };

  errorHandler = (error) => {
    this.setError(error.response.data, error.response.status);
    return Promise.reject(error.response);
  };

  successHandler = (response) => {
    this.requestCallback && this.requestCallback();

    // Privacy API optout returns no data
    if (response.status === 201 && !response.data) {
      return response;
    }

    // For blob requests (file downloads) send everything so we can read content-disposition headers, etc
    if (response.request.responseType && response.request.responseType === 'blob') {
      return response;
    }

    return response.data;
  };

  makeRequest = async (method, url, paramsOrData, config = {}, throwError = false) => {
    const requestConfig = {
      url,
      method,
      ...config,
    };

    if (method === 'get') {
      requestConfig.params = paramsOrData || '';
    } else {
      requestConfig.data = paramsOrData || {};
    }

    try {
      const response = await this.client.request(requestConfig);
      return response;
      // return this.transformResponse(response)
    } catch (error) {
      if (throwError) {
        throw error;
      }
      return false;
    }
  };

  setError(result, status) {
    const error = {
      name: '',
      message: '',
      status,
      form: {},
    };

    if (status === 500) {
      error.name = 'Server Error';
      if (result.error) {
        if (result.error?.exception && Array.isArray(result.error.exception)) {
          error.message = result.error.exception[0].message;
        } else {
          error.message = result.error;
        }
      } else if (result.message) {
        error.message = result.message;
      } else {
        error.message = 'Unknown Server Error';
      }
    } else if (status === 404) {
      error.name = 'Resource Not Found';
      if (Array.isArray(result.exception) && result.exception.length) {
        error.message = result.exception[0].message;
      }
    } else if (status === 400) {
      // bad request, parse form errors
      if (result.form && result.form.hasOwnProperty('children')) {
        // old return value from symfony form error
        error.name = 'Invalid Request';
        // filter out fields with no errors
        error.form = Object.keys(result.form.children).reduce((acc, field) => {
          if (result.form.children[field].hasOwnProperty('errors')) {
            acc[field] = result.form.children[field].errors[0];
          }
          return acc;
        }, {});
      } else if (result.errors) {
        // new form validation response  {errors: {some_field: "This value is not valid."}}
        error.name = 'Invalid Request';
        error.form = result.errors;
      }
    } else if (status === 401) {
      error.name = 'Unauthorized Request';
      if (result.error !== undefined && result.error.message !== undefined) {
        error.message = result.error.message
      } else {
        error.message = 'Please sign in again';
      }
    } else {
      error.name = `Unknown Error (${status})`;
    }

    // console.log('API.js:setError', error);

    this.error = error;
  }

  clearError() {
    this.error = {
      name: '',
      message: '',
      status: 0,
      form: {},
    };
  }

  // getQueryString(params) {
  //     return Object
  //         .keys(params)
  //         .map(k => {
  //             if (Array.isArray(params[k])) {
  //                 return params[k]
  //                     .map(val => `${encodeURIComponent(k)}[]=${encodeURIComponent(val)}`)
  //                     .join('&')
  //             }
  //
  //             return `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`
  //         })
  //         .join('&')
  // }
}

export default BaseAPI;
