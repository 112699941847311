import React, { useEffect } from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import {StandardAlert} from "@thedmsgroup/mastodon-ui-components";
import PixelForm from '../../../../Components/Form/PixelForm';
import useDidMount from '../../../../Hooks/useDidMount';
import useUniqueKeyArray from '../../../../Hooks/useUniqueKeyArray';

/*
 * ClickTags: a form for entering multiple tracking pixels associated with a source.
 */
const ClickTags = ({ clickTags = [], onChange }) => {
  const didMount = useDidMount();

  const {
    items,
    addItem,
    removeItem,
    updateItem,
  } = useUniqueKeyArray(clickTags);

  // Update tag on state change
  useEffect(() => {
    if (didMount) {
      onChange('click_tags', items.map((item) => item.value));
    }
  }, [items]);

  return (
    <>
      <p>Enter URLs or tags for tracking clicks associated with this source.</p>
      {clickTags.length === 0
      && (
      <StandardAlert color="light">
        <span>
          No click tags are configured.
          <Button color="link" size="sm" className="inline ms-1" onClick={addItem}>
            <FaIcon icon="plus" className="me-1" />
            <span>Add a tag</span>
          </Button>
&nbsp;
        </span>
      </StandardAlert>
      )}

      <div>

        {items.map((tag, i) => (
          <PixelForm
            pixel={tag.value}
            className="click-tag"
            handleRemove={() => removeItem(i)}
            handleChange={(val) => updateItem(i, val)}
            key={tag.key}
          />
        ))}

        {items.length > 0 && (
        <div className="text-right">
          <Button color="link" size="sm" onClick={addItem}>
            <FaIcon icon="plus" className="me-1" />
            <span>Add Another</span>
          </Button>
        </div>
        )}
      </div>
    </>
  );
};

ClickTags.propTypes = {
  onChange: PropTypes.func.isRequired,
  clickTags: PropTypes.array.isRequired,
};

export default ClickTags;
