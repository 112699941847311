import { library } from '@fortawesome/fontawesome-svg-core';
// General icons
import {
  faArchive,
  faBan,
  faBars,
  faBullseye,
  faCalendar,
  faCheck,
  faCheckCircle,
  faClipboardList,
  faCog,
  faCopy,
  faEdit,
  faEllipsisH,
  faEllipsisV,
  faEnvelope,
  faExternalLinkAlt, // "source"
  faEye,
  faFileAlt,
  faFileDownload,
  faFileExcel,
  faFolder,
  faFolderOpen,
  faGripLines,
  faHandHoldingUsd,
  faHeart,
  faIdCard,
  faImages,
  faIndent,
  faList,
  faLock,
  faMagic,
  faMinus,
  faPaste,
  faPause,
  faPencilAlt,
  faPercent,
  faPhone,
  faPlay,
  faPlug,
  faPlus,
  faSearch,
  faShoePrints,
  faStream,
  faTags,
  faThumbsUp,
  faTimes,
  faTrash,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';

// rules tree
import {
  faAd, faBalanceScale, faClock, faCalendarTimes, faCommentDollar, faCommentSlash, faDollarSign, faFill, faHandPaper, faHourglassHalf, faPaperclip, faPaperPlane, faProjectDiagram, faSlidersH, faStar, faStopwatch, faCloudDownloadAlt
} from '@fortawesome/free-solid-svg-icons';
// notifiers
import {
  faUserCircle, faQuestionCircle, faInfoCircle, faExclamationCircle, faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
// pointers
import {
  faArrowUp, faArrowDown, faArrowsAltH, faArrowsAlt, faArrowRight, faArrowLeft, faChevronUp,
  faChevronLeft, faChevronRight, faChevronDown, faChevronCircleRight, faChevronCircleDown, faChevronCircleUp, faChevronCircleLeft, faLongArrowAltUp, faLongArrowAltDown, faMousePointer,
} from '@fortawesome/free-solid-svg-icons';
// reports and tables
import {
  faSync, faFilter, faThLarge, faCircle, faColumns, faFileCsv, faHistory, faSort, faSortUp, faSortDown,
} from '@fortawesome/free-solid-svg-icons';
// password meter and permissions, regular-svg
 import {
   faFrown, faMeh, faSmile, faGrinBeam, faDotCircle,  faCircle as faCircleRegular,
 } from '@fortawesome/free-regular-svg-icons';
// more regular
// (need to alias the heart because we also load it from 'regular' style. To use this one, ['far', 'heart']
import { faPlusSquare, faMinusSquare, faCheckSquare, faSquare, faHeart as faHeartOpen } from '@fortawesome/free-regular-svg-icons';

/*
 * Font Awesome library.
 * @see: https://www.npmjs.com/package/@fortawesome/react-fontawesome
 * Usage:
 *    import { FontAwesomeIcon as FaIcon } from "@fortawesome/react-fontawesome";
 *    <FaIcon icon="clock"  />  //Loads faClock
 *    (there is no need to import the faClock icon because it has already been registered in the library)
 *
 * Note: the fas prefix (for Font Awesome Solid) is being inferred as the default (most of our icons are solid)
 * To use a different font, specify it like this: <FaIcon icon={["far","smile"]}  />  (far = font-awesome-regular)
 */
export const FaLibrary = () => {
  library.add(
    faArchive,
    faBan,
    faBars,
    faBullseye,
    faCalendar,
    faCheck,
    faCheckCircle,
    faCheckSquare,
    faClipboardList,
    faCog,
    faCopy,
    faEdit,
    faEllipsisH,
    faEllipsisV,
    faEnvelope,
    faExternalLinkAlt,
    faEye,
    faFileAlt,
    faFileDownload,
    faFileExcel,
    faFolder,
    faFolderOpen,
    faGripLines,
    faHandHoldingUsd,
    faHeart,
    faIdCard,
    faImages,
    faIndent,
    faList,
    faLock,
    faMagic,
    faMinus,
    faPaste,
    faPause,
    faPencilAlt,
    faPercent,
    faPhone,
    faPlay,
    faPlug,
    faPlus,
    faSearch,
    faShoePrints,
    faSquare,
    faStream,
    faTags,
    faThumbsUp,
    faTimes,
    faTrash,
    faUsers,
  );
  // rules tree
  library.add(faAd, faBalanceScale, faClock, faCalendarTimes, faCommentDollar, faCommentSlash, faDollarSign, faFill, faHandPaper, faHourglassHalf, faPaperclip, faPaperPlane, faProjectDiagram, faSlidersH, faStar, faStopwatch, faCloudDownloadAlt);
  // notifiers
  library.add(faUserCircle, faQuestionCircle, faInfoCircle, faExclamationCircle, faExclamationTriangle);
  // password meter
  library.add(faFrown, faMeh, faSmile, faGrinBeam,  faDotCircle, faCircleRegular);
  // pointers
  library.add(
    faArrowUp,
    faArrowDown,
    faArrowsAltH,
    faArrowsAlt,
    faArrowRight,
    faArrowLeft,
    faChevronUp,
    faChevronLeft,
    faChevronRight,
    faChevronDown,
    faChevronCircleRight,
    faChevronCircleDown,
    faChevronCircleUp,
    faChevronCircleLeft,
    faLongArrowAltUp,
    faLongArrowAltDown,
    faMousePointer,
  );
  // reports and tables
  library.add(faColumns, faSync, faHistory, faFilter, faThLarge, faCircle, faFileCsv, faSort, faSortUp, faSortDown);
  // more regular
  library.add(faPlusSquare, faMinusSquare, faHeartOpen);
};
