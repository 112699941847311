import React, {
  useState, useContext, useEffect, useCallback,
} from "react";
import { Row, Col } from 'reactstrap';
import {useParams, useSearchParams, } from "react-router-dom";
import confirm from 'reactstrap-confirm';
import {notify} from "@thedmsgroup/mastodon-ui-components/lib/common/Notify";
import RulesContext from '../../../Providers/RulesContext';
import RuleTree from './RuleTree';
import TreeRule from './TreeRule';
import { TreeProvider } from '../../../Components/Rules/TreeProvider';
import RulesConfig from './RulesConfig';
import '../../../Components/Rules/styles.scss';
import './styles.scss';
import DocTitle from '../../../Layout/DocTitle';
import usePermission from '../../../Hooks/usePermission';
import useRuleTree from '../../../Hooks/useRuleTree';
import RuleSkeleton from './RuleSkeleton';
import OrderInfoBar from './OrderInfoBar';
import ChangeLog from './ChangeLog';
import { AppContext } from '../../../Providers/AppProvider';
import OrderDashboard from "./OrderDashboard";


// Just a check for the top of the form being out of bounds
// const isFormInViewport= ()=> {
//     const bounding = document.getElementById('col-rule-config').getBoundingClientRect();
//     return ( bounding.top >= 0);
// };

const NEW_RULE = {
  id: 0,
  isNew: true,
  label: 'New Rule',
  ads: [],
  bidding: {
    type: 'pass',
    absolute_bid: 1,
    bid_modifier: 100,
  },
  budgets: {},
  caps: {},
  channel_modifiers: [],
  click_urls: [],
  datasheets: [],
  delivery_destinations: [],
  call_started_webhooks: [],
  call_ended_webhooks: [],
  destination_sip: {},
  exclusive: false,
  enrichment_addons: [],
  impression_urls: [],
  match_conditions: [[]],
  override_parent_ads: false,
  override_parent_channel_modifiers: false,
  parent_id: 0,
  proxy_bidding: [],
  redirect_url: '',
  redirect_url_template: '',
  rules: [],
  schedule: [],
  schedule_type: 'inactive',
  schedule_modifiers: {},
  schedule_caps: {},
  schedule_affects_siblings: false,
  tags: [],
  internal_data: '',
};

const RulesManager = () => {
  const app = useContext(AppContext)
  const {orderId} = useParams()

  const [searchParams, setSearchParams] = useSearchParams()
  const gotoRuleId = searchParams.get('rule')
  const allowChangeLog = usePermission('changes.view')
  const allowAdvanced = usePermission('rules_advanced.edit')
  const allowViewAuctions = usePermission('auctions.view')
  const allowEdit = usePermission('orders.edit')
  const [order, setOrder] = useState(null)
  const {
    tree,
    refreshKey,
    selectedRule,
    setTree,
    getRule,
    deleteRule,
    addTempRule,
    swapRule,
    selectRule,
    updateRuleTree,
    getPrecedingRule,
    getDisabledAncestor,
    findPropAncestors,
  } = useRuleTree(null, null, NEW_RULE)
  const [linkedData, setLinkedData] = useState({})
  const [actionsOpen, setActionsOpen] = useState(false)
  const [activeTab, setActiveTab] = useState(allowEdit && searchParams.get('tab') || 'summary' )
  const [isDirty, setIsDirty] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingTree, setIsLoadingTree] = useState(false)
  const [ready, setReady] = useState(false)


  // URL change: order pickers outside of this component may change the URL
  // Go back to not-ready mode
  useEffect(() => {
    if (ready) setReady(false)
    loadOrder()
  }, [orderId])



  useEffect(() => {
    if (selectedRule && !selectedRule.isNew) {
      updateQueryString()
    }
  }, [selectedRule, activeTab])


  const copyRule = async (rule, label = '') => {
    const copy = {
      parent_id: rule.parent_id,
      label: label || `${rule.label} (Copy)`,
      copy_from_id: rule.id,
      // Api sets priority one higher than source rule
    }
    app.showLoader('rulesConfig', 'Copying Rule...')

    const result = await app.api.endpoints.rules.create(copy)

    app.showLoader(false)

    if (!result) {
      notify(`Unable to copy rule: ${app.api.error.name}`, 'error')
    } else {
      notify('The rule has been copied', 'success')
      //TODO need to set the new rule as the one selected
      loadRulesTree()
      handleSelectRule(result)
    }
  }

  const moveRule = async (rule, targetRule, placement = 'below') => {
    app.showLoader('rulesConfig', 'Moving Rule...')

    // console.log('index.js:MoveRule:', `Moving ${rule.id} [pr ${rule.priority}, parent ${rule.parent_id}] ${placement}  ${targetRule.id} [pr ${targetRule.priority}, parent ${targetRule.parent_id}]`);

    let maxPriority = 0
    const parentRule = getRule(rule.parent_id)
    if (parentRule && parentRule.rules.length) {
      parentRule.rules.map((r) => {
        if (r.priority > maxPriority) maxPriority = r.priority
      });
    }

    const movingRule = { id: rule.id, label: rule.label }

    if (placement === 'below') {
      movingRule.priority = targetRule.priority + 1;
      movingRule.parent_id = targetRule.parent_id
    } else if (placement === 'above') {
      if (maxPriority && targetRule.priority === maxPriority) {
        movingRule.priority = maxPriority - 1
      } else {
        movingRule.priority = targetRule.priority
      }
      movingRule.parent_id = targetRule.parent_id
    } else if (placement === 'child') {
      movingRule.priority = 0
      movingRule.parent_id = targetRule.id
    }

    const result = await app.api.endpoints.rules.update(movingRule);
    app.showLoader(false);

    if (result) {
      loadRulesTree(tree.id);
      loadRule(rule.id)
    }
  }

  // called from useEffect after selectRule or tab
  const updateQueryString = () => {

    setSearchParams({ ...searchParams, tab: activeTab, rule: selectedRule.id }, {replace:true});
  }

  const dirtyRuleConfirm = async () => await confirm({
    title: 'Unsaved Rule',
    message: 'The current rule has unsaved changes',
    confirmText: 'Continue editing',
    cancelText: 'Discard changes',
  })

  const leaveNewRuleConfirm = async () => await confirm({
    title: 'Unsaved New Rule',
    message: 'Please save or cancel the current new rule',
    confirmText: 'Continue editing',
    cancelText: 'Discard new rule',
  })

  const loadOrder = async () => {
    const order = await app.api.endpoints.orders.show(orderId)

    const groupBy = key => array =>
      array.reduce((objectsByKeyValue, obj) => {
        const value = obj[key]
        objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj)
        return objectsByKeyValue
      }, {});

    if (order === false) {
      notify(`Unable to load order: ${app.api.error.name}`, 'error')
    } else {
      setOrder(order)
      let accountAds; let channels; const attributes = {}; let attributeGroups; let tokens; let integrations; let
        datasheets
      // Get account peripheral data used by rules
      // (note: integrations data comes paged so we use a high limit to get all
      const batchResult = await app.api.batchGet([
        { endpoint: 'ads', action: 'list', params: { account_id: order.account.id } },
        { endpoint: 'channels', action: 'list', params: { options:true, account_id: order.account.id, vertical_id: order.vertical.id, product: order.product } },
        { endpoint: 'attributes', action: 'list', params: { options: true, vertical_id: order.vertical.id, product: order.product } },
        { endpoint: 'tokens', action: 'list', params: { vertical_id: order.vertical.id, product: order.product } },
        { endpoint: 'integrations', action: 'list', params: { limit:1000,vertical_id: order.vertical.id, product: order.product, account_id: order.account.id } },
        { endpoint: 'datasheets', action: 'list', params: { account_id: order.account.id } },
      ])

      if (Array.isArray(batchResult)) {
        [accountAds, channels, attributeGroups, tokens, integrations, datasheets] = batchResult

        // Ungroup the attributeGroup data and store in attributes
        Object.entries(attributeGroups).map((attrGroup) => {
          const groupName = attrGroup[0]
          return attrGroup[1].map((attr) => attributes[attr.alias] = { ...attr, groupName })
        })

        if (integrations.data) { // this is BC handling during api transition // todo remove after transition
          // Filter out integrations with null versions, won't save
          integrations.data = integrations.data.filter(int => Boolean(int.current_version))
          integrations = groupBy('type')(integrations.data)
        }
      }

      setLinkedData({
        order, accountAds, channels, attributes, tokens, attributeGroups, integrations, datasheets,
      });

      loadRulesTree(order.rule_id)
      const rule = await loadRule(gotoRuleId || order.rule_id)
      if (rule) {
        setReady(true)
      }


    }
  };


  const loadRule = async (ruleId) => {
    setIsLoading(true)

    const rule = await app.api.endpoints.rules.show(ruleId, {v2:1})

    if (rule === false) {
      notify(`Unable to load rule: ${app.api.error.name}`, 'error')
    } else {
      selectRule(rule, true)
    }

    setIsLoading(false)

    return rule
  };

  // Loads the rule tree by loading the account's rule (the order's "parent" rule)l
  // Called after loading order (on mount), and after save, disable, move
  const loadRulesTree = async (rootRuleId) => {
    if (!rootRuleId && tree) {
      rootRuleId = tree.id
    }
    setIsLoadingTree(true)
    const ruleTree = await app.api.endpoints.rules.tree(rootRuleId )

    if (ruleTree === false) {
      notify(`Unable to load rules: ${app.api.error.name}`, 'error')
    } else {
      setTree(ruleTree)
    }

    setIsLoadingTree(false)
  };

  const getIntegrationDefinitionsByType = (type) => {
    if (linkedData.integrations) {
      return linkedData.integrations[type] || []
    }
    return [];
  }

  // after updating or creating an account datasheet
  const refreshAccountDatasheetList = async () => {
    const datasheets = await app.api.endpoints.datasheets.list({ account_id: linkedData.order.account.id })
    if (datasheets) {
      setLinkedData((prev) => ({ ...prev, datasheets }))
    } else {
      console.log('Unable to refresh account datasheet list')
    }
  }

  const toggleActionsPane = () => setActionsOpen(!actionsOpen)

  // Tabs can be selected from inside RulesConfig (where the tabs are)
  // or from the rules tree (by clicking on icons).
  // In the tree you can effectively select a tab and a rule at the same time,
  // which is why we have the rule argument.
  //
  // Use a callback because it is passed to all tabs (keeps single reference to function)
  const handleSetActiveTab = useCallback((tab, rule) => {
    if (allowEdit) {
      setActiveTab(tab);
      if (rule && rule.id !== selectedRule?.id) {
        handleSelectRule(rule)
      }
    }
  }, [selectedRule, isLoading])

  // Create a temporary rule to work on
  const addRule = (targetRule, placement) => addTempRule(targetRule, placement, { ...NEW_RULE })

  const disableRule = async (rule, isDisabled) => {
    const targetRule = { id: rule.id, label: rule.label, disabled: !!isDisabled }

    const result = await app.api.endpoints.rules.update(targetRule)

    if (result) {
      notify(`The rule has been ${isDisabled ? 'disabled' : 'enabled'}`, 'success')
      loadRulesTree(tree.id)
      selectRule(result, true)
    }
  }

  const handleDeleteRule = async (rule) => {
    app.showLoader('rulesConfig', 'Deleting Rule...')

    const result = await app.api.endpoints.rules.delete(rule.id)
    app.showLoader(false)
    if (!result) {
      notify(`Unable to delete rule: ${app.api.error.name}`, 'error')
    } else {
      notify('The rule has been deleted', 'success');

      const fallbackRule = getPrecedingRule(rule)
      handleSelectRule(fallbackRule)
      loadRulesTree()

    }
  }

  // Used for a few targeted async updates where we just want to update a specific prop
  // Bid value edited in the tree, or edit rule name.
  const updateRule = async (data, rule, reset = false) => {
    // just the data and required fields
    const { id, parent_id, label } = rule || selectedRule
    const updatedRule = {
      id, parent_id, label, ...data,
    }
    const result = await app.api.endpoints.rules.update(updatedRule)

    if (result) {
      updateRuleTree(result);
      if (reset && selectedRule.id === rule.id) {
        loadRule(rule.id)
      }
    } else {
      notify(`Unable to update rule: ${app.api.error.name}`, 'error')
    }
    return result;
  }

  const saveRule = async (saveRule, isNew) => {
    setIsUpdating(true);
    let result;
    if (isNew) {
      // delete rule.isNew;
      result = await app.api.endpoints.rules.create(saveRule);
      if (result) {
        swapRule(result, saveRule);
      }
    } else {
      result = await app.api.endpoints.rules.update(saveRule);
      if (result) {
        updateRuleTree(result);
        selectRule(result, true);
      }
    }

    setIsUpdating(false);
    if (!result) {
      notify(`Unable to save rule: ${app.api.error.name}`, 'error');
    } else {
      selectRule(result, true);
      loadRulesTree()
      notify('The rule has been saved', 'success');
    }

  }


  const handleCancel = () => {
    let rule
    if (selectedRule.isNew) {
      //remove the temporary new rule in the tree, select prev sibling or parent
      rule = getPrecedingRule(selectedRule)
      deleteRule(selectedRule)

    } else {
      //reload current
      rule = selectedRule
    }

    loadRule(rule.id)
  }

  const handleSelectRule = async (rule, refresh) => {
    if (isLoading) return
    //TODO: Consider changing this function to only accept ID
    if (typeof rule === 'number' || typeof rule === 'string') {
      rule = {id:rule}
    }
    if (rule ) {
      if (rule.id !== selectedRule?.id){
        if (isDirty) {
          // confirm leaving a dirty rule
          const stay = await dirtyRuleConfirm()
          if (stay) return
        } else if (selectedRule?.isNew) {
          // confirm leaving a new unsaved rule
          const stay = await leaveNewRuleConfirm();
          if (stay) return
          // discard the new rule and go to previous
          deleteRule(selectedRule)
          const previous = getPrecedingRule(selectRule)
          loadRule(previous.id)
          return
        }
      } else if (!refresh) {
        //already selected
        return;
      }

    } else {
      //if no rule provided, select previous
      rule = getPrecedingRule(selectedRule)
    }

    if (rule) {
      loadRule(rule.id)
    }

  }

  // Kludge to get tree height to reset itself
  // Tree watches scroll and resize to set its height, but closing the order stats panel
  // does not trigger these events so we force it.
  // Other (less performant) solutions might be using a ResizeObserver or requestAnimationFrame
  const handleToggleSection = (expanded) => {
    if(!expanded) {
      window.scrollTo({top:window.scrollY + 1})
    }
  }

  const rulesAPI = {
    selectedRule,
    getRule,
    deleteRule: handleDeleteRule,
    addTempRule,
    addRule,
    updateRule,
    saveRule,
    copyRule,
    moveRule,
    setSelected: handleSelectRule,
    setActiveTab: handleSetActiveTab,
    setIsDirty,
    refreshRules: loadRulesTree, // used by bulk add
    updateRuleTree,
    refreshAccountDatasheetList,
    toggleActionsPane,
    disableRule,
    getDisabledAncestor,
    findPropAncestors,
    getIntegrationDefinitionsByType,
  }

  return (
    <div id="target-rule-manager">

      <DocTitle pageTitle={`${linkedData?.order?.name} - Target Rules`} />


      <RulesContext.Provider value={{
        tree,
        selectedRule,
        verticalId: linkedData.order ? linkedData.order.vertical.id : 0,
        product: linkedData.order ? linkedData.order.product : '',
        attributes: linkedData.attributes,
        attributeGroups: linkedData.attributeGroups,
        api: rulesAPI,
        allowEdit
      }}
      >

        {ready && linkedData.order && (
          <>
            <Row>
              <Col sm={12}>

                <OrderInfoBar order={linkedData.order} userTimezone={app.user.timezone} />

              </Col>

            </Row>

            {allowViewAuctions && (
              <OrderDashboard
                orderId={linkedData.order.id}
                userTimezone={app.user.timezone}
                onToggle={handleToggleSection}
              />)}
          </>

        )}


        <div className="d-flex flex-nowrap" key={`order-${orderId}`}>

          {/* TODO: selectedRuleAncestors (used in TreeBranch for isAncestorBranch) */}
          <TreeProvider rule={tree} product={linkedData?.order?.product}>

              <RuleTree
                rule={tree}
                selectedRule={selectedRule}
                ancestors={[]}
                expandToLevel={2}
                ruleComponent={TreeRule}
                isLoading={isLoadingTree || !ready}
              />


          </TreeProvider>

          {/* ID for routine that checks if form is in viewport */}
          <div className="col-rule-config flex-grow-1 d-flex flex-column" id="col-rule-config">



            {/* The ref allows the sibling Tree to call activeTab method in RulesConfig */}
            {!ready ? (
              <RuleSkeleton />
            ) : (
              <RulesConfig
                rule={selectedRule}
                activeTab={activeTab}
                onSelectTab={handleSetActiveTab}
                onCancel={handleCancel}
                isRoot={selectedRule ? selectedRule.id === order.rule_id : false}
                order={linkedData.order}
                accountAds={linkedData.accountAds}
                channels={linkedData.channels}
                datasheets={linkedData.datasheets}
                tokens={linkedData.tokens}
                treeOpen
                actionsOpen={actionsOpen}
                isDirty={isDirty}
                //TODO: separate props for these states?
                isUpdating={isUpdating || isLoading}
                allowAdvanced={allowAdvanced}
                key={orderId + (selectedRule?.id ?? 0) + refreshKey}
              />
            )}

            {ready && selectedRule && selectedRule.isNew !== true && allowChangeLog && (
            <div className="rule-change-section">
              <ChangeLog
                order={linkedData.order}
                rule={selectedRule}
                attributes={linkedData.attributes}
                refreshKey={refreshKey}
              />
            </div>
            )}

          </div>
        </div>
      </RulesContext.Provider>

    </div>

  );
};

export default RulesManager;
