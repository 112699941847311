import React, { useEffect, useState } from 'react';
import {
  Button, Modal, ModalHeader, ModalBody,
} from 'reactstrap';
import lazify from '../../../utils/Lazify';
import PageSection from '../../../Layout/PageSection';
import Table from './Table';
import {notify} from "@thedmsgroup/mastodon-ui-components/lib/common/Notify";
import FilterBar from '../../../Components/Table/FilterBar';
import Filters from './Filters';
import DocTitle from '../../../Layout/DocTitle';
import usePagedTableState from '../../../Hooks/usePagedTableState';
import useApi from '../../../Hooks/useApi';
import usePermission from '../../../Hooks/usePermission';
import PhoneNumber from './PhoneNumber';

const LazyChangeLog = lazify(() => import(/* webpackChunkName: "ChangeLog" */ '../../ChangeLog/EntityChangeLog'));

const DefaultFilters = {
  available: '',
};

const DefaultControls = {
  limit: 50,
  sort_by: 'inserted_at',
  sort_dir: 'desc',
};

/*
 * Phone Numbers List - paged table with filters
 *
 */
const PhoneNumberList = () => {
  const {
    filters, setFilters, controls, updateQuery,
  } = usePagedTableState(
    DefaultFilters,
    DefaultControls,
    { useQueryString: true, useTableControls: true },
  );
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedNumber, setSelectedNumber] = useState(null);
  const [changeLogKey, setChangeLogKey] = useState(1);
  const apiList = useApi('phoneNumbers', 'list');
  // const apiUpdate = useApi('phoneNumbers', 'update');
  const allowView = usePermission('phone_numbers.view');
  const allowCreate = usePermission('phone_numbers.create');
  const allowChangeLog = usePermission('changes.view');

  useEffect(() => {
    if (!loading) {
      load();
    }
  }, [filters, controls.sort_by, controls.sort_dir, controls.page, controls.limit]);

  const filterParamsToApi = (filters) => {
    const apiParams = { ...filters };

    if (filters.vertical) {
      apiParams.vertical_id = filters.vertical;
      delete apiParams.vertical;
    }

    if (filters.available !== undefined && !filters.available) {
      delete apiParams.available;
    }

    return apiParams;
  };

  const load = async () => {
    // if(!this.isUserAccount) return;
    setLoading(true);
    updateQuery();
    const apiFilters = filterParamsToApi(filters);
    const {
      page, sort_by, sort_dir, limit,
    } = controls;
    const result = await apiList.fetch({
      ...apiFilters,
      page,
      sort_by,
      sort_dir,
      limit,
    });

    if (result) {
      const { limit, total, data } = result; // row:0, page:1, limit:20, data:[]
      setData(data);
      setPageCount(total > limit ? Math.ceil(total / limit) : 1);
      setTotalRows(total);
    } else {
      notify(`Unable to load phone numbers: ${apiList.error.name}`, 'error');
    }

    setLoading(false);
  };

  const closeEditModal = (refresh = false) => {
    setEditModalOpen(false);
    setSelectedNumber(null);
    if (refresh === true) {
      load();
      setChangeLogKey(changeLogKey + 1);
    }
  };

  const openEditModal = (number) => {
    if (!number) {
      number = {
        id: 0,
        phone_number: '',
        source: null,
        script: null,
        seed_data: {},
      };
    }
    setSelectedNumber(number);
    setEditModalOpen(true);
  };

  // const handleStatusChange = async(source, status) => {
  //
  //   if(source && allowEdit){
  //     let result = await apiUpdate.fetch(
  //       { ...source,
  //         vendor_id:source.vendor.id,
  //         vertical_id:source.vertical.id,
  //         status:status
  //       }
  //     );
  //     return result ? true : false;
  //   }
  //   return false;
  //
  // }

  if (!allowView) return (null);

  return (
    <>
      <DocTitle pageTitle="Phone Numbers" />

      <FilterBar>
        <Filters filters={filters} onChange={setFilters} onRefresh={() => load()} loading={loading} />
      </FilterBar>

      <PageSection
        title="Phone Numbers"
        tools={allowCreate === true ? (
          <Button
            className="ms-auto"
            onClick={() => openEditModal(null)}
            color="primary"
            size="sm"
          >
            New Number
          </Button>
        ) : (null)}
      >

        {/* onStatusChange={handleStatusChange}  */}
        <Table
          data={data}
          loading={loading}
          pageCount={pageCount}
          totalRows={totalRows}
          showObject={false}
          {...controls}
          openModal={openEditModal}

        />

        <Modal isOpen={editModalOpen} toggle={closeEditModal} size="lg" id="phone-number-modal" scrollable>
          {selectedNumber && (
            <>
              <ModalHeader toggle={closeEditModal}>{selectedNumber.id === 0 ? 'Create Phone Number' : 'Phone Number Settings'}</ModalHeader>
              <ModalBody>

                <PhoneNumber
                  {...selectedNumber}
                  isNew={selectedNumber.id === 0}
                  closeModal={closeEditModal}
                />

              </ModalBody>
            </>
          )}
        </Modal>

      </PageSection>

      {allowChangeLog === true && (
      <LazyChangeLog
        entityType="phonenumber"
        refreshKey={changeLogKey}
      />
      )}

    </>
  );
};

export default PhoneNumberList;
