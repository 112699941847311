import React, { useState, useEffect, useMemo } from 'react';
import {
  Badge,
  Button,
  Alert,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import { titleCase } from '../../../utils/string';
import {DateFormatter} from "../../../Components/Table/Table";
import useApi from '../../../Hooks/useApi';
import {StandardAlert, JsonDisplay,  notify} from "@thedmsgroup/mastodon-ui-components";
import {DeliveryRejectCategoryChoices} from "../../../Components/Table/FilterChoices";
import {DataRows,DataRow} from "../../../Components";
import FlexTable from "../../../Components/Table/FlexTable";
import HttpLog from "./HttpLog";
import NumberFormat from "react-number-format";

const HelpText = ({children}) => <Alert color="secondary">{children}</Alert>

/*
 * Fetches and displays delivery detail.
 */

const Deliveries = ({
                      deliveryOptions = [],
                      selectedDeliveryId = null,
                      onSelect
}) => {

  const { api } = useApi()

  const [detail, setDetail] = useState(null)
  const [selectedRows, setSelectedRows] = useState({})
  const [view, setView] = useState('list')
  const [deliveries, setDeliveries] = useState({}) // a cache of loaded delivery detail
  const [loading, setLoading] = useState(false)

  const toggleView = () => {
    setView(view === 'list' ? 'detail' : 'list');
    setSelectedRows({})
  }


  const handleClickRow = (index) => {
    const delivery = deliveryOptions[index]
    setSelectedRows({[index]:true})
    setView('detail')
    onSelect(delivery.id)

    if (delivery) {
      loadDetails(delivery)
    }
  }

  //Scroll to row when detail opens (your selected row could be hidden when the table shortens)
  useEffect(() => {
    if (view === 'detail' && selectedRows) {
      const rowKeys = Object.keys(selectedRows)
      if (rowKeys.length) {
        const row = document.getElementById(`row-${rowKeys[0]}`)
        if (row) {
          row.scrollIntoView({behavior:"smooth", block: "nearest"})
        }
      }
    }
  }, [view])

  const columns = useMemo(() => [
    {
      Header: 'Type',
      accessor: 'type',
      Cell: ({ value }) => (
        <Badge size="xs" className={value.toLowerCase()}>{titleCase(value)}</Badge>
      ),
      maxWidth: 80,
      id:'foobar'
    },
    {
      Header: 'Integration',
      accessor: 'integration_info.label',
    },
    {
      Header: 'Version',
      accessor: 'integration_info',
      maxWidth: 80,
      Cell: ({ value }) => (

        <Link
          target="_blank"
          className="link ads"
          to={`/tools/integrations/${value.id}`}
          title="View Integration"
        >
          {value.version}<FaIcon icon="external-link-alt" size="sm" className="ms-1" />
        </Link>

      ),
    },
    {
      Header: 'Rejection',
      accessor: 'reject_category',
      Cell: ({ value }) => (
        <RejectionCategoryDisplay category={value} />
      ),
    },
    {
      Header: 'Duration',
      accessor: 'duration',
      Cell:({value}) => <NumberFormat value={value} displayType="text" thousandSeparator suffix={' ms'}/>,
      maxWidth:80
    },
    {
      Header: 'Sent',
      accessor: 'sent_at',
      sortType: (a, b) => {
        return new Date(b.sent_at) - new Date(a.sent_at);
      },
      Cell: ({ value }) => {
        return <DateFormatter value={value} format="yyyy-MM-dd hh:mm:ss:SS" />
        // return <RelativeTime date={value} showTimezone={true} />;
      },
    },
    {
      Header: 'Account',
      accessor: 'account.name',
    },
    {
      Header: 'Order',
      accessor: 'order.name',
    },
  ], [])

  const initialTableState = useMemo(
    () => ({
      sortBy: [
        { id: 'sent_at', 'desc' : false },
      ],
    }),
    []
  )

  // On mount or when external ID selection, load the delivery detail
  useEffect(() => {
    if (deliveryOptions.length) {
      if (selectedDeliveryId) {
        const index = deliveryOptions.findIndex(el => el.id === selectedDeliveryId)
        if (index >= 0) {
          loadDetails(deliveryOptions[index])
          setSelectedRows({[index]: true})
          setView('detail')
        }
      }

    }
    //Unmount: clear delivery detail cache
    return () => {
      setDeliveries({})
      setDetail(null)
    }
  }, [selectedDeliveryId]);

  const loadDetails = async (delivery) => {
    if (!delivery) return
    setLoading(true)
    onSelect(delivery.id)
    let result = await api.endpoints.debug.deliveries(delivery.id)

    if (result) {
      result = Object.assign(result, delivery)
      result.timeout = delivery.reject_category === "delivery_timeout"
      setDeliveries((prev) => {
        const newDeliveries = prev ? {...prev} : {}
        newDeliveries[delivery.id] = result
        return newDeliveries
      });
      setDetail(result)


    } else {
      notify(`Unable to load delivery details: ${api.error.name}`, 'error')
    }

    setLoading(false)
  };


  return (
    <div className="form-section">
      <div className="form-section-header d-flex justify-content-between">
        <div>Deliveries</div>

      </div>
      {deliveryOptions.length === 0 && (
        <StandardAlert color="light">
          This auction has no deliveries
        </StandardAlert>
      )}



      {deliveryOptions.length > 0 && (
        <div className={classnames("deliveries-table-container", {scrolling:view === 'detail'})}>
          <FlexTable
            columns={columns}
            data={deliveryOptions}
            initialState={initialTableState}
            className='deliveries-table'
            onClickRowCallback={handleClickRow}
            selectedRowIds={selectedRows}
          />
        </div>
      )}

      {view === 'detail' && detail && (
        <>
          {deliveryOptions.length > 0 && (
            <div className="form-section border mt-2">
              <div className="form-section-header d-flex justify-content-between">
                <div>{detail.integration_info?.label}</div>
                <Button onClick={toggleView}  color="transparent" title="Close detail">
                  <FaIcon icon="times" />
                </Button>
              </div>


                <div>
                  <div className="form-section delivery flex-grow-1">
                    {!detail.delivery_id && (
                      <StandardAlert color="light" icon="info">
                        This integration made no HTTP requests.
                      </StandardAlert>
                    )}

                    {detail.cached && <StandardAlert>
                      This delivery used a cached response from a prior delivery.
                    </StandardAlert>}

                    {detail.timeout && <StandardAlert color="danger" icon="clock">
                      This delivery timed out – the response was not used.
                    </StandardAlert>}

                    {detail.notified && <StandardAlert icon="clock">
                      This delivery only notified – we did not wait for a response & the response was not used.
                    </StandardAlert>}

                    <DataRows>
                      <DataRow label="Delivery ID">
                        {detail.delivery_id || <span>&mdash;</span>}
                      </DataRow>

                      {!detail.integration_info && <DataRow label="Integration">
                        {detail.integration}
                      </DataRow>}

                      {detail.integration_info &&
                        <DataRow label="Integration">
                          <Link
                            target="_blank"
                            className="link ads"
                            to={`/tools/integrations/${detail.integration_info.id}`}
                            title="View Integration"
                          >
                            <FaIcon icon="external-link-alt" size="sm" className="me-1" />
                            {detail.integration_info.label} [version {detail.integration_info.version}]

                          </Link>
                        </DataRow>
                      }

                      <DataRow label="Result Data">
                        <div>
                          {detail.data ? <>
                            <HelpText>This data was returned from our integration after interpreting the client response. It is NOT the actual response returned from the client, only our interpretation of it.<br/>
                              To see what the client actually responded, please review the actual response further below.</HelpText>
                            <JsonDisplay data={detail.data || ''} scrollable height="300px" /></> : <span>&mdash;</span>}
                        </div>
                      </DataRow>

                      {detail.input_data && (
                        <DataRow label="Integration Data">
                          <div>
                            <ToggleMe>
                              <HelpText>This is all the data that was available to our integration to work with. It does NOT mean this data was sent to clients.<br/>
                                To see what was actually sent, please review the actual request further below. INTERNAL USE ONLY.</HelpText>
                              <JsonDisplay data={detail.input_data || ''} scrollable height="300px" />
                            </ToggleMe>
                          </div>
                        </DataRow>
                      )}

                      {detail.settings && <DataRow label="Settings">
                        <ToggleMe>
                          <JsonDisplay data={detail.settings || ''} scrollable height="300px" />
                        </ToggleMe>
                      </DataRow>}

                      {detail.error && <DataRow label="Error">
                        <div className="error">
                          {detail.error}
                        </div>
                      </DataRow>}
                      {detail.api_operations?.map((log, i) => <HttpLog log={log} key={i} />)}

                    </DataRows>


                  </div>

                </div>

            </div>
          )}
        </>
      )}



    </div>
  );
};



const ToggleMe = ({ children }) => {
  const [show, setShow] = useState(false);
  const handleToggle = () => setShow(!show);
  return (
    <div className="toggle-me">
      <Button color="link" size="sm" className="inline d-flex align-items-center" onClick={handleToggle}>
        <FaIcon icon="eye" className="me-1" />
        <div>{show ? 'hide' : 'show'}</div>
      </Button>
      <div className={classnames(['data', { show }])}>
        {children}
      </div>
    </div>
  );
};

const RejectionCategoryDisplay = ({category, showIcon}) => {
  const resolved = DeliveryRejectCategoryChoices.find(cat => cat.value === category);
  return (
    <span>
      {showIcon && <FaIcon icon="exclamation-triangle" size="sm" className="me-1" color="darkred" />}
      {resolved ? resolved.label : category}
    </span>
  )
}

Deliveries.propTypes = {
  deliveryOptions: PropTypes.array,
};

export default Deliveries;
