import BaseAPI from "../../../Services/BaseAPI";

class RoutingApi extends BaseAPI {
  constructor() {
    super(process.env.API_URL_CORE);
  }

  sourceRoutes = (id)     => this.makeRequest('get', `${this.url}/routing/source/${id}`);
  channelRoutes = (id)    => this.makeRequest('get', `${this.url}/routing/channel/${id}`);
  vendorRoutes = (id, params={})    => this.makeRequest('get', `${this.url}/routing/vendor/${id}`, params);
  ordersForChannel = (id) => this.makeRequest('get', `${this.url}/orders/channel/${id}`);
  channelsForOrder = (id) => this.makeRequest('get', `${this.url}/routing/order/${id}`);
}

export default RoutingApi
