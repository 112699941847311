import React from 'react';
import PropTypes from 'prop-types';
import {FormGroup, FormFeedback, Input, Label, FormText} from 'reactstrap';
import {StandardAlert} from "@thedmsgroup/mastodon-ui-components";
import {JsonEditorLoadable} from "@thedmsgroup/mastodon-ui-components";
import IconCheckbox from "../../../Components/Form/IconCheckbox";
import classnames from "classnames";
// import {useDebouncedCallback} from "use-debounce";

/*
 * Special settings for advanced life forms only
 */
const Advanced = ({
  internalData,
  excludeProfitReporting,
  autoRedirect,
  uniqueLeadKey,
  qualifiedRateKey,
  matchLimit,
  matchTimeout,
  onChange,
  errors,
}) => {
  //
  // const handleChangeInternalDataDebounced = useDebouncedCallback( (value) =>{
  //   console.log('Advanced.js:change value', value);
  //   onChange('internal_data', value)
  // }, 500);

  const handleChangeInternalData = (value) => onChange('internal_data', value);
  const handleExcludeProfitReporting = (name, val) => onChange('exclude_profit_reporting', val);

  const handleAutoRedirect = (name, val) => onChange('auto_redirect', val);

  return (
    <div>
      {(errors.internal_data)
        && (
          <StandardAlert color="info" className="validation">
            Please correct the errors below before continuing
          </StandardAlert>
        )}

      <div className="form-section">
        <div className="form-section-header">Internal Data</div>
        <p>Settings for reporting and other internal processes, in JSON format</p>
        <FormGroup className="mt-2">

          <JsonEditorLoadable
            code={internalData}
            onChange={handleChangeInternalData}
            isInvalid={!!errors.internal_data}
          />

          <FormFeedback>{errors.internal_data}</FormFeedback>

        </FormGroup>

      </div>

      <div className="form-section">
        <div className="form-section-header">Exclude from Profit Reporting</div>
        <p>Do not log revenue to DMS Profit Reporting. This will apply to all descendant rules.</p>

        <IconCheckbox
          name="exclude_profit_reporting"
          value={Boolean(excludeProfitReporting)}
          onChange={handleExcludeProfitReporting}
          className="mt-3"
        >
          Exclude from profit reporting
        </IconCheckbox>
      </div>

      <div className="form-section">
        <div className="form-section-header">Auto-Redirect</div>
        <p>For auctions with a single exclusive bid, redirect the visitor without requiring a click</p>

        <IconCheckbox
          name="auto_redirect"
          value={Boolean(autoRedirect)}
          onChange={handleAutoRedirect}
          className="mt-3"
        >
          Auto Redirect Visitor
        </IconCheckbox>
      </div>

      <div className="form-section">
        <div className="form-section-header">Unique Lead Key</div>
        <p>Specify an expression to uniquely identify this lead</p>
        <Input
          name="unique_lead_key"
          type="text"
          value={uniqueLeadKey}
          onChange={onChange}
        />
      </div>

      <div className="form-section">
        <div className="form-section-header">Sale Rate Key</div>
        <p>Specify an expression to customize the way sale rate is aggregated. By default this is `{'{source_id}-{buyer_name}-{broker_name}'}`</p>
        <Input
          name="qualified_rate_key"
          type="text"
          placeholder={"{source_id}-{buyer_name}-{broker_name}"}
          value={qualifiedRateKey}
          onChange={onChange}
        />
      </div>

      <div className="form-section">
        <div className="form-section-header">Match Limit</div>
        <p>Limit the number of matches to return from auctions</p>
        <Input
          name="match_limit"
          type="text"
          value={matchLimit}
          onChange={onChange}
          className="teeny"
          placeholder="3"
        />
      </div>

      <div className="form-section">
        <div className="form-section-header">Match Timeout (in milliseconds)</div>
        <p>Limit the amount of time an auction can take.</p>
        <Input
          name="match_timeout"
          type="text"
          value={matchTimeout}
          onChange={onChange}
          className="teeny"
          placeholder="5000"
        />
        <FormText>This is a "best effort", not a guarantee. Primarily, it affects timeouts of integrations used during
          the auction.</FormText>
      </div>

    </div>
  );
};

Advanced.propTypes = {
  internalData: PropTypes.string,
  autoRedirect: PropTypes.bool,
  uniqueLeadKey: PropTypes.string,
  qualifiedRateKey: PropTypes.string,
  matchLimit: PropTypes.number,
  matchTimeout: PropTypes.number,
  onChange: PropTypes.func,
  errors: PropTypes.object,
};

export default Advanced;
