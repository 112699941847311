import React from 'react';


const RulesContext = React.createContext({
  tree:[],
  selectedRule: null,
  verticalId: 0,
  product: '',
  attributes: [],
  attributeGroups: [],
  api:{},
  allowEdit: false,
});

export default RulesContext;
