import React, {FC, useRef, useMemo} from "react";
import {RoutingMap} from "./index";
import {Button, Modal, ModalBody, ModalHeader, Input} from "reactstrap";
import {ModalBaseType} from "../../../types";
import {Link} from "react-router-dom";
import {FontAwesomeIcon as FaIcon} from "@fortawesome/react-fontawesome";
import {notify} from "@thedmsgroup/mastodon-ui-components/lib/common/Notify";

type RoutingMapModalPropertiesType = ModalBaseType & {
  sourceId?:number;
  channelId?:number;
  orderId?:number;
  accountId?:number;
  vendorId?:number;
};

const RoutingMapModal: FC<RoutingMapModalPropertiesType> = ({
                                                        isOpen,
                                                        toggle,
                                                        title="Routing Map",
  sourceId,
  channelId,
  orderId,
  vendorId,
  accountId
}) => {
  const query = useMemo(()=>{
    if (sourceId) {
      return `?source=${sourceId}`;
    } else if (channelId) {
      return `?channel=${sourceId}`;
    } else if (orderId) {
      return `?account=${accountId}&order=${sourceId}`;
    }
  }, [sourceId, channelId, orderId, accountId])

  const copyAreaRef = useRef();

  const handleCopyLink = () => {

    if (copyAreaRef.current) {
      //@ts-ignore
      copyAreaRef.current.value = `${location.protocol}//${location.host}/#/routing${query}`;
      //@ts-ignore
      copyAreaRef.current.select();
      document.execCommand('copy');
      notify('Link copied', 'success', { autoClose: 1000 });
    }

  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="full" >
      <ModalHeader toggle={toggle}>{title}</ModalHeader>

      <div className="ms-3">
        <Link
          target="_blank"
          to={{
          pathname:"/routing",
          search: query
        }} className="link routing-link p-0">
          <FaIcon icon="external-link-alt" size="sm" />
          {' '}
          Permalink
        </Link>
        <Button color="link" size="sm" className="inline ms-1" onClick={handleCopyLink} title="Copy routing map link">
          <FaIcon icon="copy" size="sm" />
        </Button>
      </div>

      {/* Hidden from view, used for copy to clipboard */}
      <div id="copy-url">
        <Input
          type="text"
          defaultValue=""
          //@ts-ignore
          innerRef={copyAreaRef}
        />
      </div>

      <ModalBody>
        <RoutingMap
          useQuery={false}
          sourceId={sourceId}
          channelId={channelId}
          orderId={orderId}
          accountId={accountId}
          vendorId={vendorId}
        />
      </ModalBody>
    </Modal>
  )
}

export default RoutingMapModal;
