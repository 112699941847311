import React, { useContext, useEffect, useState } from 'react';
import {
  Button, Modal, ModalHeader,
} from 'reactstrap';
import lazify from '../../../utils/Lazify';
import PageSection from '../../../Layout/PageSection';
import VendorsTable from './VendorsTable';
import {Modal as RoutingRulesModal} from "../../Routing/Rules";
import Vendor from './Vendor';
import {notify} from "@thedmsgroup/mastodon-ui-components/lib/common/Notify";
import DocTitle from '../../../Layout/DocTitle';
import useApi from '../../../Hooks/useApi';
import usePermission from '../../../Hooks/usePermission';
import { AppContext } from '../../../Providers/AppProvider';
import FilterBar from '../../../Components/Table/FilterBar';
import Filters from './Filters';
import {LiveFilteringContext, LiveFilteringProvider} from "../../../Providers/LiveFilteringProvider";
import Sources from "./Vendor/Sources";
import RoutingMapModal from "../../Routing/Mapper/Modal";

const LazyChangeLog = lazify(() => import(/* webpackChunkName: "ChangeLog" */ '../../ChangeLog/EntityChangeLog'));

/*
 * Sources List - table
 *
 */
const VendorsList = () => {

  const [loading, setLoading] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [names, setNames] = useState([]);
  const [vendorModalOpen, setVendorModalOpen] = useState(false);
  const [rulesModalOpen, setRulesModalOpen] = useState(false);
  const [sourceTokensModalOpen, setSourceTokensModalOpen] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [routingMapId, setRoutingMapId] = useState(null);
  const [changeLogKey, setChangeLogKey] = useState(1);
  const apiList = useApi('vendors', 'list');
  const allowView = usePermission('vendors.view');
  const allowCreate = usePermission('vendors.create');
  const allowChangeLog = usePermission('changes.view');
  const app = useContext(AppContext);

  if (!allowView) return null;

  useEffect(() => {
    if (!loading) {
      load();
    }
  }, []);

  const load = async () => {
    setLoading(true);
    const result = await apiList.fetch();

    if (result) {
      setVendors(result);
    } else {
      notify(`Unable to load vendors: ${apiList.error?.name}`, 'error');
    }

    setLoading(false);
  };

  useEffect(() => {
    setNames(vendors.map(v => v.name));
  }, [vendors]);

  const openVendorModal = (vendor) => {

    if (!vendor) {
      vendor = {
        id: 0,
        name: '',
      };
    }

    if (vendor.id > 0) {
      loadVendorDetail(vendor)
    } else {
      setSelectedVendor(vendor);
      setVendorModalOpen(true);
    }
  };

  const openRulesModal = (vendor) => {
    setSelectedVendor(vendor);
    setRulesModalOpen(true);
  };



  const closeRulesModal = () => setRulesModalOpen(false);

  const openSourceTokensModal = (vendor) => {
    setSelectedVendor(vendor);
    setSourceTokensModalOpen(true);
  };

  const closeSourcesModal = (refresh) => {
    if (refresh === true) {
      load();
    }
    setSourceTokensModalOpen(false);
  }

  const openRoutingMapModal = (vendorId) => setRoutingMapId(vendorId)
  const closeRoutingMapModal = () => setRoutingMapId(null)

  const loadVendorDetail = async (vendor) => {
    const result = await app.api.endpoints.vendors.show(vendor.id);
    if (result.auth_tokens !== undefined && result.auth_tokens.length > 0) {
      let token = result.auth_tokens[0].token;
      setSelectedVendor({
        ...vendor,
        token: token
      });
    } else {
      setSelectedVendor(vendor);
      notify(`Unable to load API Key`, 'error', {autoClose:500})
    }
    setVendorModalOpen(true)
  }

  const closeVendorModal = (refresh = false) => {
    setSelectedVendor(null);
    setVendorModalOpen(false);
    if (refresh === true) {
      load();
      setChangeLogKey(changeLogKey + 1);
    }
  };

  return (
    <>
      <DocTitle pageTitle="Vendors" />

      <LiveFilteringProvider useQueryString filters={{}}>

      <FilterBar>
        <Filters loading={loading} />
      </FilterBar>

      <PageSection
        title="Vendors"
        tools={allowCreate === true ? (
          <Button
            className="ms-auto"
            onClick={() => openVendorModal()}
            color="primary"
            size="sm"
          >
            New Vendor
          </Button>
        ) : (null)}
      >

      <TotalCount allCount={vendors.length} className="mb-2" />

        <VendorsTable
          vendors={vendors}
          loading={loading}
          openEditModal={openVendorModal}
          openRulesModal={openRulesModal}
          openSourceTokensModal={openSourceTokensModal}
          openRoutingMapModal={openRoutingMapModal}
        />


          <Vendor
            vendor={selectedVendor}
            isOpen={vendorModalOpen}
            close={closeVendorModal}
            onSuccess={load}
            names={names}
          />


        {selectedVendor && (
          <>
            <RoutingRulesModal
              entityType="vendor"
              entity={selectedVendor}
              isOpen={rulesModalOpen}
              close={closeRulesModal}
              onRefresh={load}
            />
            <Sources
              isOpen={sourceTokensModalOpen}
              vendor={selectedVendor}
              close={closeSourcesModal}
            />
          </>

        )}

        <RoutingMapModal
          isOpen={Boolean(routingMapId)}
          toggle={closeRoutingMapModal}
          vendorId={routingMapId}
        />

      </PageSection>

      {allowChangeLog === true && (
      <LazyChangeLog
        entityType="vendor"
        refreshKey={changeLogKey}
      />
      )}
    </LiveFilteringProvider>
    </>
  );
};

const TotalCount = ({  allCount, className }) => {
  const { filteredCount } = useContext(LiveFilteringContext);
  return allCount > 0 ? (
    <div className={`total-count ${className}`}>
      Total:
      {filteredCount < allCount && <> {filteredCount} of </>} {allCount}

    </div>
  ) : null;
};

export default VendorsList;
