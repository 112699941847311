import type {StatusType} from "../../../types";
import {FontAwesomeIcon as FaIcon} from "@fortawesome/react-fontawesome";
import {Utils} from "@thedmsgroup/mastodon-ui-components";
import {Badge} from "reactstrap";
import React from "react";

/*
 * A version of reactstrap badge to indicate archived or paused status
 */
export const StatusBadge = ({status}: {status:StatusType}) => {
  if (!status || status === 'active') return null;
  return (
    <Badge title={`Status: ${status}`} className={`status-badge ${status}`}>
      <FaIcon icon={status === 'archived' ? 'archive':'pause'} size='1x' /> {Utils.titleCase(status)}
    </Badge>
  )
}
