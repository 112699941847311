import React from 'react';
import classnames from "classnames";
import {Button, UncontrolledTooltip} from "reactstrap";
import {FontAwesomeIcon as FaIcon} from "@fortawesome/react-fontawesome";
import {faFileInvoiceDollar} from "@fortawesome/free-solid-svg-icons";
import { RelativeTime, Utils } from '@thedmsgroup/mastodon-ui-components';
import { Handle, Position } from '@xyflow/react';
import {DataRows, DataRow} from "../../../Components";
import EntityId from "../../../Components/Table/EntityId";
import {StatusBadge} from "./StatusBadge";
import type {FlowNodeProps} from "./index";

type ChannelNodeProps = FlowNodeProps & {
  onEdit:(channel:any) =>void,
  onExamine:(channelId:number) =>void,
  onGetOrders:(channel:any) =>void,
  onOpenRules:(channel:any) =>void,
  onOpenChannelSources:(channel:any) =>void,
}

const ChannelNode = (
  {
    //Passed by react-flow
    targetPosition,
    sourcePosition,
    data,
    //Additional properties
    onEdit,
    onExamine,
    onGetOrders,
    onOpenRules,
    onOpenChannelSources,
  }:ChannelNodeProps
) => {
  const {channel, dataView, flowView} = data;
  const handleOrderHandleClick = (e:any) => {
    e.stopPropagation();
    //TODO clear route highlighting too
    onGetOrders(channel);
  }



  // stop Propagation so clicking buttons does not activate toggle
  const handleEdit = (e: any) => {
    e.stopPropagation();
    onEdit(channel);
  }
  const handleExamine = (e: any) => {
    e.stopPropagation();
    onExamine(channel.id);
  }
  const handleOpenRules = (e: any) => {
    e.stopPropagation();
    onOpenRules(channel);
  }
  const handleOpenChannelSources = (e: any) => {
    e.stopPropagation();
    onOpenChannelSources(channel);
  }

  const handleGotoAuction = (e: any) => {
    e.stopPropagation()
    window.open(`#/auctions?page=1&channel=${channel.id}`, '_blank')
  }

  return (
    <div  className={classnames("node channel", channel.status, {'route-focus':flowView==='channel'} )}>
      <StatusBadge status={channel.status} />
      <Handle id="target-handle" type="target" position={targetPosition} />
      {/*<Handle id={'source-handle'} type="source" position={sourcePosition} />*/}
      <OrderHandle
        position={sourcePosition}
        onClick={handleOrderHandleClick}
        channelId={channel.id}
      />

        <div className="node-header">
          <div className="icon-col">
            <EntityId id={channel.id} title="Channel ID" className="me-1"/>
          </div>
          <div className="node-title flex-fill">

            <div>
              {channel.name}
            </div>

            <div className="actions">
              <Button color="link" size="sm" onClick={handleExamine} title="Examine channel routes" disabled={flowView==='channel'}>
                <FaIcon icon="eye" size="sm"/>
              </Button>
              <Button color="link" size="sm" onClick={handleEdit} title="Edit Channel" >
                <FaIcon icon="cog" size="sm"/>
              </Button>
              <Button color="link" size="sm" onClick={handleOpenChannelSources} title="View/Edit channel source eligibilities">
                <FaIcon icon="project-diagram" size="sm"/>
              </Button>
              <Button color="link" size="sm" onClick={handleOpenRules} title="View/Edit routing rule">
                <FaIcon icon="clipboard-list" size="sm"/>
              </Button>
              <Button
                color="link"
                size="sm"
                title="View auctions"
                onClick={handleGotoAuction}
              >
                <FaIcon icon="comment-dollar" size="sm"/>
              </Button>
            </div>
          </div>

        </div>

      {/* If mapper is focused on one channel, show product/vertical */}
      {flowView === 'channel' && (
        <div className="node-detail d-flex">

            <div className="icon-col">
              <FaIcon icon="info-circle" size="sm"/>
            </div>
            <DataRows labelWidth="60px" noDivider gap="0.1em">
              <DataRow label="Product">{Utils.titleCase(channel.product)}</DataRow>
              <DataRow label="Vertical">{Utils.titleCase(channel.vertical.display_name)}</DataRow>
            </DataRows>

        </div>
      )}

        {dataView.includes("sales") && (
          <div className="node-detail d-flex">

              <div className="icon-col">
                <FaIcon icon="calendar" size="sm"/>
              </div>

              <DataRows labelWidth="60px" noDivider gap="0.1em">
                <DataRow label="Match">
                  <RelativeTime date={channel.last_matched_at} uniqueId={`src-${channel.id}-match`} showTimezone={true} />
                </DataRow>
                <DataRow label="Sale">
                  <RelativeTime date={channel.last_sold_at} uniqueId={`src-${channel.id}-sale`} showTimezone={true} />
                </DataRow>
              </DataRows>

          </div>
        )}

    </div>
  )
}

export default ChannelNode;

/*
 * Custom handle that shows an icon and allows clicking to get channel's orders
 */
const OrderHandle = ({ position, channelId, onClick }: {position:Position, channelId:number, onClick:React.MouseEventHandler}) => (
  <>
    <Handle
      type="source"
      id="source-handle"
      position={position}
      style={{ background: '#fff', right:'-6px '}}
      className="order-handle"
      onClick={onClick}
    >
      <div className="d-inline" id={`source-handle-${channelId}`}>
        <FaIcon icon={faFileInvoiceDollar} size="sm"/>
      </div>

    </Handle>
    {/*
    Normally we'd use the Handle as the tooltip target,
    but react-flow turns Handle id into data-id, and the tooltip can't find the target
    */}
    <UncontrolledTooltip
      target={`source-handle-${channelId}`}
      className="flow-tooltip"
      // modifiers don't seem to work well here
      //@ts-ignore
      //modifiers={[{name:'offset',options:{offset: '10', enabled: true}}]}
    >
      View orders that use this channel
    </UncontrolledTooltip>
  </>

)

