import React, {FC, useState, useEffect} from "react";
import DocTitle from "../../../Layout/DocTitle";
import {RulesManager} from "./Manager";
import EntityInfoBar from "./EntityInfoBar";
import {useParams} from "react-router-dom";
import useApi from "../../../Hooks/useApi";
import {RoutedEntityType} from "../../../types";
import {ChannelSourceRoute} from ".";
import {StandardAlert} from "@thedmsgroup/mastodon-ui-components";
import PageSection from "../../../Layout/PageSection";

type RoutingRuleUrlParamsType = {
  entityId: string,
  entityType: RoutedEntityType,
  channelId?: string,
  sourceId?: string,
}

/*
 * Page for Routing rules
 * The routing rules manager contained in a page section
 */
export const Page: FC<any> = () => {

  const { api } = useApi();
   const { entityId, entityType, channelId, sourceId } = useParams<RoutingRuleUrlParamsType>();
   const [entity, setEntity] = useState<any>(null);
   const [isLoading, setIsLoading] = useState<boolean>(true)

   useEffect(()=>{
     loadRoutedEntity()
   }, [])

   const loadRoutedEntity = async() => {
     let result;
     switch (entityType){
       case 'channel':
         result = await api.endpoints.channels.show(entityId)
         break;
       case 'source':
         result = await api.endpoints.sources.show(entityId)
         break;
       case 'vendor':
         result = await api.endpoints.vendors.show(entityId)
         break;
       case 'channelsource':
         const channel = await api.endpoints.channels.show(channelId)
         if (channel) {

           // the source array of a channel is its source eligibilities.
           const source = channel.sources.find((s:any) => s.id.toString() === sourceId);

           if (source) {
             //setChannelSource(sourceEligibility);
             result = new ChannelSourceRoute(channel, source)
           }
         }
         break;
     }
     if (result) {
       setEntity(result);
     }
     setIsLoading(false);
   }


  return (
    <div id="routing-rule-manager">
      <DocTitle pageTitle="Routing Rules" />

      {!entity && !isLoading && (
        <PageSection>
          <StandardAlert color="light">Unable to find the requested resource</StandardAlert>
        </PageSection>
      )}

      {entity && (
        <>
          {entityType && <EntityInfoBar entity={entity} entityType={entityType}/>}
          <RulesManager
            entityType={entityType}
            entityRuleId={entity?.rule_id}
            product={entity?.product}
            verticalId={entity?.vertical?.id}
          />
        </>

      )}

    </div>
  )
}

