import React, { useContext, useState } from 'react';
import {
  Button, Modal, ModalFooter, ModalBody, ModalHeader, Col, FormGroup, Label, Input, FormFeedback, Row,
} from 'reactstrap';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import useFormState from '../../../../Hooks/useFormState';
import {CopyButton, notify, StandardAlert} from "@thedmsgroup/mastodon-ui-components";
import LoadingBar from '../../../../Layout/LoadingBar';
import { AppContext } from '../../../../Providers/AppProvider';
import {Required} from "../../../../Components/Form/FormCommon";

/*
 * Component for editing or creating a vendor
 */
const Vendor = ({ vendor, isOpen, close, onSuccess }) => {
  if (!vendor) return null
  const [isNew, setIsNew] = useState(vendor.id === 0)
  const [confirmationMode, setConfirmationMode] = useState(false);
  const app = useContext(AppContext)
  const ValidationSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .lowercase()
      .required('Please enter a vendor name')
    //token: Yup.string().trim().transform(value => (!value ? null : value)).nullable().min(36, "Invalid format"),
  })

  const {
    formApi, formIsValid, formValues, formErrors,
  } = useFormState(
    {
      ...vendor,
    },
    ValidationSchema,
    null,
    null,
   { isNew }, // validationContext allows conditional validation in case we need it later
  )


  const handleSave = () => {
    const isValid = formApi.validate();
    if (isValid) {
      save()
    }
  };

  const save = async () => {
    app.showLoader('modal', isNew ? 'Creating vendor...' : 'Saving vendor...')
    let result;
    const data = { ...formValues }

    data.name = data.name.trim();
    if (isNew) {
      data.create_auth_token = true
      result = await app.api.endpoints.vendors.create(data)
      if (result) {
        formApi.setValues({
          name:result.name,
          crypto_key:result.crypto_key,
          token: result.auth_tokens ? result.auth_tokens[0].token : '',
          ueids: result.ueids
        })
        setConfirmationMode(true);
        setIsNew(false)
      }
    } else {
      result = await app.api.endpoints.vendors.update(data);
    }

    app.showLoader(false);

    if (result) {
      // if updating, just close the modal
      // if we created a vendor, keep modal open to show the new auto-created values
      if(!isNew) {
        close(true)
      }

      notify(`The vendor has been saved`, 'success')
      onSuccess && onSuccess(result, isNew)
    } else {
      // Could get 'name already exists' error
      if (app.api.error.form && app.api.error.form['token.token']) {
        // TODO: error handling for tabs
        notify(`Unable to save vendor: ${app.api.error.form['token.token']}`, 'error')
      } else {
        notify(`Unable to save vendor: ${app.api.error.name}`, 'error')
      }
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={close}
    >

      <ModalHeader toggle={close}>
        { isNew ? 'Create Vendor'
          : (
            confirmationMode ? 'Created Vendor' : (
              <>
                Edit Vendor | <span className="modal-subheader">{vendor.name}</span>
              </>
            )
          )}
        {' '}
      </ModalHeader>
      <ModalBody>
        <LoadingBar name="modal" />

        {!formIsValid
        && (
          <StandardAlert color="warning">
            Please correct the errors below before continuing
          </StandardAlert>
        )}

        <div className="vendor-form">

          <Row>
            <Col sm={12} md={10}>
              <FormGroup>
                <Label>
                  Name
                  {' '}
                  <Required />
                </Label>
                <Input
                  type="text"
                  name="name"
                  onChange={formApi.handleOnChange}
                  value={formValues.name}
                  invalid={!!formErrors.name}
                />
                <FormFeedback>{formErrors.name}</FormFeedback>
              </FormGroup>


              <FormGroup>
                <Label>
                  API Key
                  {' '}
                  {vendor.token && (
                    <CopyButton
                      id={`copy-token`}
                      textToCopy={vendor.token}
                      buttonSize="sm"
                      className="m-0 ms-1 p-0"
                    />)}

                </Label>

                <Input
                  type="text"
                  name="token"
                  id="key"
                  value={vendor.token || ''}
                  readOnly={true}
                  placeholder={ isNew ? ("A token will be automatically created.") : undefined}
                />

                <Label className={"mt-2"}>
                  Crypto Key
                  {' '}
                  {vendor.crypto_key && (
                    <CopyButton
                      id={`copy-token`}
                      textToCopy={vendor.crypto_key}
                      buttonSize="sm"
                      className="m-0 ms-1 p-0"
                    />)}
                </Label>

                <Input
                  type="text"
                  name="crypto_key"
                  id="crypto_key"
                  value={vendor.crypto_key || ''}
                  readOnly={true}
                  placeholder={ isNew ? ("A crypto key will be automatically created.") : undefined}
                />

                <Label className={"mt-2"}>
                  UEIDs
                </Label>

                <Input
                  type="textarea"
                  name="ueids"
                  id="ueids"
                  value={formValues.ueids}
                  onChange={formApi.handleOnChange}
                />

              </FormGroup>

            </Col>
          </Row>


        </div>
      </ModalBody>


      <ModalFooter>
        {confirmationMode ? (
          <Button
            onClick={() => close(true)}
            color="primary"
            size="sm"
          >
            close
          </Button>
        ) : (
          <>
            <Button
              onClick={close}
              color="link"
              size="sm"
            >
              Cancel
            </Button>
            <Button
              onClick={handleSave}
              color="primary"
              size="sm"
            >
              Save
            </Button>
          </>
        )}

      </ModalFooter>


    </Modal>
  );
};

Vendor.propTypes = {
  vendor: PropTypes.object,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  names: PropTypes.array,
};

export default Vendor;
