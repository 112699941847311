import React, {useState} from 'react'
import {Button, Card, CardBody,Modal, ModalBody, ModalHeader} from "reactstrap"
import {SourceTokenForm} from "./SourceTokenForm"
import {FontAwesomeIcon as FaIcon} from "@fortawesome/react-fontawesome"
import {SourceToken} from "../../../types"
import EntityId from "../../../Components/Table/EntityId"
import {Stack, Utils} from "@thedmsgroup/mastodon-ui-components"

type SourceTokenModalProps = {
  token:SourceToken,
  isOpen:boolean,
  toggle:()=>void,
  size?:string,
  onSuccess?:()=>void,
  sourcePreselections?:any[]
  scrollable?:boolean
}

export const SourceTokenModal = ({
     token,
     isOpen,
     toggle,
     onSuccess,
     sourcePreselections,
     size='lg',
     scrollable=false
}:SourceTokenModalProps) => {
  if (!token) return null;
  const [showPreselect, setShowPreselect] = useState<boolean>(Boolean(sourcePreselections && sourcePreselections.length))
  // the token prop, possibly modified with pre-selected source
  const [formToken, setFormToken] = useState(token);

  const handlePreselection = (source:any|null) => {
    if (source) {
      setFormToken((token) => {
        return {...token, source}
      })
    }
    setShowPreselect(false);
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="source-token-panel"
      size={size}
      scrollable={scrollable}
    >

      <ModalHeader toggle={toggle} >
        <div className="d-flex align-items-center">
          <Button size="sm" onClick={toggle} className="btn-close-white inline" color="link" >
            <FaIcon icon="times" className="me-2" />
          </Button>
          <div>{`${token.id ? 'Edit' : 'Create'}  Source-Token`}</div>
        </div>
      </ModalHeader>


        {showPreselect && sourcePreselections ? (
          <ModalBody>
          <SourcePreselect
            sources={sourcePreselections}
            onSelect={handlePreselection}
            />
          </ModalBody>
        ) : (
          <SourceTokenForm
            sourceToken={formToken}
            close={toggle}
            onSuccess={onSuccess}
            title={""}
            isModal={true}
          />
        )}



    </Modal>
  )
}

type SourcePreselectProps = {
  sources:any[],
  onSelect:(source:any|null) => void
}
const SourcePreselect = ({sources, onSelect}:SourcePreselectProps) => {
  if (!sources.length) return null;

  return (
    <div>
        <p>Create a token for {sources.length > 1 ? 'one of the sources' : 'the source'} below?</p>
        <Stack gap="1em" noDivider >
          {sources.map((source:any, i:number) => (
            <Card className="source-preselect" key={`preselect-${i}`}>
              <CardBody>
                <div className="d-flex justify-content-between">
                  <div>
                    <div><EntityId id={source.id} title="Source ID" className="me-1"/>  <b>{source.name}</b></div>
                    <div>{Utils.titleCase(source.product)} | {source.vertical.display_name}</div>
                  </div>
                  <div>
                    <Button
                      size="sm"
                      outline
                      onClick={() => onSelect(source)}
                    >Select</Button>
                  </div>

                </div>
              </CardBody>

            </Card>
          ))}
      </Stack>

      <Button size="sm" block className="mt-3" onClick={() => onSelect(null)}>
        No, I&apos;ll select another source <FaIcon icon="arrow-right" />
      </Button>
    </div>
  )
}
