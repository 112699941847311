import React from 'react'
import {Button} from "reactstrap"
import {FontAwesomeIcon as FaIcon} from "@fortawesome/react-fontawesome"
import {RelativeTime} from '@thedmsgroup/mastodon-ui-components'
import EntityId from "../../../Components/Table/EntityId"
import { Handle } from '@xyflow/react'
import type {FlowNodeProps} from "./index";
import classnames from "classnames"


type VendorNodeProps = FlowNodeProps & {
  onEdit:(vendor:any) =>void,
  onExamine:(vendorId:number) =>void,
  onOpenRules:(vendor:any) =>void,
}


export const VendorNode = (
  {
    //passed by react-flow
    sourcePosition,
    data,
    //Additional Properties
    onEdit,
    onExamine,
    onOpenRules,

  }:VendorNodeProps
) => {
  const {vendor, dataView, flowView} = data


  const handleExamine = (e: any) => {
    e.stopPropagation()
    onExamine(vendor.id)
  }

  const handleOpenRules = (e: any) => {
    e.stopPropagation()
    onOpenRules(vendor)
  }
  const handleEdit = (e: any) => {
    e.stopPropagation()
    onEdit(vendor)
  }

  /*
   * Using window.open here instead of the usual methods
   * We want to stop the propagation of the click so it doesn't pass the event to the
   * react-flow handler. And we also want to open in a new tab.
   * The react-router Link, or useNavigate hook, won't do both.
   */
  const handleGotoAuction = (e: any) => {
    e.stopPropagation()
    window.open(`#/auctions?page=1&vendor=${vendor.id}`, '_blank')
  }


  return (

    <div className={classnames("node vendor",  {'route-focus':flowView === 'vendor'})} >

      <Handle id={'source-handle'} type="source" position={sourcePosition} />

        <div className="node-header">
          <div className="icon-col">
            <EntityId id={vendor.id} title="Vendor ID" className="me-1"/>
          </div>
          <div className="node-title flex-fill">
            {vendor.id ? (
              <>
                <div>
                  {vendor.name}
                </div>
                <div className="actions">
                  <Button color="link" size="sm" onClick={handleExamine} title="Examine vendor routes">
                    <FaIcon icon="eye" size="sm"/>
                  </Button>
                  <Button color="link" size="sm" onClick={handleEdit} title="View/Edit Vendor">
                    <FaIcon icon="cog" size="sm"/>
                  </Button>
                  <Button color="link" size="sm" onClick={handleOpenRules} title="View/Edit routing rule">
                    <FaIcon icon="clipboard-list" size="sm"/>
                  </Button>
                  <Button
                    color="link"
                    size="sm"
                    title="View auctions"
                    onClick={handleGotoAuction}
                  >
                    <FaIcon icon="comment-dollar" size="sm"/>
                  </Button>
                </div>
              </>
            ) : (
              <div className="no-relation alert"><i>No Vendor</i></div>
            )}

          </div>

        </div>



        {dataView.includes("sales") && vendor.id && (
          <div className="node-detail d-flex">
              <div className="icon-col">
                <FaIcon icon="calendar" size="sm"/>
              </div>
              <div>
                <div>
                  <label>Match:</label> <RelativeTime date={vendor.last_matched_at} uniqueId={`ven-${vendor.id}-match`} showTimezone={true} />
                </div>
                <div>
                  <label>Sale:</label> <RelativeTime date={vendor.last_sold_at} uniqueId={`ven-${vendor.id}-sale`} showTimezone={true} />
                </div>
              </div>

          </div>
        )}


    </div>


  )
}

