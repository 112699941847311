import React, { useState, useEffect, useMemo } from 'react';
import {Link} from "react-router-dom";
import {Row, Col, Button, Input, Label, FormGroup, Badge} from 'reactstrap';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import useApi from "../../../../Hooks/useApi";
import InputSchemaApi from "../../../Tools/InputSchema/InputSchemaApi";
import {HelpPopper, JsonEditorLoadable, StandardAlert} from "@thedmsgroup/mastodon-ui-components";
import IconCheckbox from "@thedmsgroup/mastodon-ui-components/lib/components/Form/IconCheckbox";
import EnhancedSelect from "../../../../Components/Form/EnhancedSelect";
import FlexTable from "../../../../Components/Table/FlexTable";
import usePermission from "../../../../Hooks/usePermission";
import EntityId from "../../../../Components/Table/EntityId";
import {titleCase} from "@thedmsgroup/mastodon-ui-components/lib/utils/string";

export const RequiredFields = ({requiredFields=[], verticalId, onChange}) => {
  const {api} = useApi();
  const inputSchemaApi = new InputSchemaApi();
  const [inputSchema, setInputSchema] = useState();
  const [vertical, setVertical] = useState();
  const [loading, setLoading] = useState(true);
  const [fieldOptions, setFieldOptions] = useState([]);
  //You can add more than one field at a time
  const [newFields, setNewFields] = useState([])
  const [rejectNew, setRejectNew] = useState(false);
  const [lazyNew, setLazyNew] = useState(false);
  const allowInputSchema = usePermission('input_schema.edit');
  const allowVerticals = usePermission('verticals.edit');


  useEffect(() => {

    loadInputSchema();

  }, [verticalId])


  const handleToggleRejectNew = (e) => {
    setRejectNew(e.target.checked)
  }
  const handleToggleLazyNew = (e) => {
    setLazyNew(e.target.checked)
  }

  const handleAdd = () => {
    const newRequiredFields = newFields.map(fieldName => {
      return {
        field:fieldName,
        lazy: lazyNew,
        reject: rejectNew
      }
    });
    //remove dupes from existing, then add new
    const unDupedExisting = requiredFields.filter(f => !newRequiredFields.find(n => n.field === f.field));
    onChange([...unDupedExisting, ...newRequiredFields]);
    setNewFields([]);

  }

  const handleRemove = (index) => {
    if (requiredFields[index]) {
      const newRequired = [...requiredFields];
      newRequired.splice(index, 1);
      onChange(newRequired);
    }
  }


  //toggle checkboxes in row
  const handleToggleCheckedRow = (name, checked, index) => {
    if (requiredFields[index]) {
      const newRequired = [...requiredFields];
      newRequired[index][name] = checked;
      onChange(newRequired);
    }
  }

  const loadInputSchema = async() => {
    if (!verticalId) {
      setLoading(false);
      setVertical(null);
      return;
    }

    if (!loading) setLoading(true);
    const vert = await api.endpoints.verticals.show(verticalId);
    if (vert) {
      setVertical(vert);
      if (vert.input_schema) {

        const schema= await inputSchemaApi.show(vert.input_schema.id, {full: true});
        if (schema) {
          setInputSchema(schema);
          setFieldOptions(Object.entries(schema.content.properties).map(([field, fieldProps]) => {
              return {
                value:field,
                label:field,
                type:fieldProps.type,
                description:fieldProps.description ?? '',
                private: fieldProps.private ?? false,
                example: fieldProps.example ?? ''
              };
            }).sort((a, b) => a.value.localeCompare(b.value))
          );
        }

      }  else {
        setInputSchema(undefined);
      }
    } else {
      setVertical(undefined);
    }

    setLoading(false);
  }

  const columns = useMemo(() => [
    {
      Header:'Field',
      accessor: 'field'
    },
    {
      Header: <div>Reject <HelpPopper name="field-reject-help">Fail the auction if the field is missing</HelpPopper></div>,
      accessor: 'reject',
      Cell: ({row, value}) => (
        <IconCheckbox
          name="reject"
          value={!!value}
          onChange={(name, checked) => handleToggleCheckedRow(name, checked, row.index)}
        />
      ),
      maxWidth:100
    },
    {
      Header: <div>Lazy <HelpPopper name="field-lazy-help">The value can be provided on the POST if it is not available at the time of the PING</HelpPopper></div>,
      accessor: 'lazy',
      Cell: ({row, value}) => (
        <IconCheckbox
          name="lazy"
          value={!!value}
          onChange={(name, checked) => handleToggleCheckedRow(name, checked, row.index)}
        />
      ),
      maxWidth:100
    },
    {
      id: 'remove',
      Cell: ({row}) => {
        return <Button size="sm" className="inline" color="link" title="Remove this field" onClick={() => handleRemove(row.index)}>
          <FaIcon icon="times" color="red" />
        </Button>
      },
      maxWidth:60
    },
  ])

  //TODO: message: select a vertical (for new source)
  return (
    <div className="required-fields">
      <p>Set the required fields for source input, based on the input schema of the source&apos;s vertical.</p>
      <Row className="gx4">
      <Col sm={12} md={7}>

        {!loading && !verticalId && (
          <StandardAlert icon="alert" color="danger">A vertical for the source must be selected first.</StandardAlert>
        )}

        {!loading && vertical  && (
          <>
            {inputSchema ? (
              <>
                <Row className="mb-3 mt-1">
                  <Col sm={12}>
                    <div className="add-field-form align-items-center form-section border">
                      <Row className="align-items-center">
                        <Col sm={6}>
                          <EnhancedSelect
                            isClearable
                            isSearchable
                            onChange={setNewFields}
                            options={fieldOptions}
                            value={newFields}
                            tabSelectsValue
                            backspaceRemovesValue
                            isMulti
                            controlShouldRenderValue
                            closeMenuOnSelect
                            placeholder="Select Fields"
                            formatOptionLabel={RequiredFieldOptionLabel}
                          />
                        </Col>

                        <Col sm={2}>
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="checkbox"
                                defaultValue={false}
                                onChange={handleToggleRejectNew}
                              />
                              Reject
                            </Label>
                          </FormGroup>
                        </Col>

                        <Col sm={2}>
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="checkbox"
                                defaultValue={false}
                                onChange={handleToggleLazyNew}
                              />
                              Lazy
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col sm={2}>
                          <Button
                            color="primary"
                            size="sm"
                            onClick={handleAdd}
                            disabled={!newFields.length}
                          >Add</Button>
                        </Col>
                      </Row>
                    </div>

                  </Col>

                </Row>

                <Row className="mt-2">
                  <Col>
                    <FlexTable
                      data={requiredFields}
                      columns={columns}
                      initialState={{
                        sortBy: [{id: 'field', desc: false}]
                      }}
                      className="required-fields striped"
                      noDataMessage="No required fields are set"
                    />
                  </Col>
                </Row>
              </>
              ) : (
              <StandardAlert icon="alert" color="danger">The vertical {vertical.display_name} has no input schema. No required fields can be set.</StandardAlert>
              )}

          </>
        )}

      </Col>

      <Col sm={12} md={5}>
        {inputSchema && vertical && (
          <>
            <h5>{`Input Schema for Vertical: ${vertical.display_name}`}</h5>

            {(allowInputSchema || allowVerticals) && (
              <div className="mb-1">
                <div>{allowInputSchema && <Link to="/tools/inputschema" target="_blank">Manage Input Schema</Link>}</div>
                <div>{allowVerticals && <Link to="/verticals" target="_blank">Manage Verticals</Link>}</div>
              </div>
            )}

            <JsonEditorLoadable
              code={JSON.stringify(inputSchema.content, null, 2)}
              label={inputSchema.name}
              mode="view"
              height="400px"
            />
          </>

        )}
      </Col>
    </Row>


    </div>
  )
}

const RequiredFieldOptionLabel = (option, meta, selected) => (

    <div className="d-flex justify-content-between">
      <div className="d-flex">
        {meta.context === 'menu' &&
          <div>{selected && <FaIcon icon="check" size="sm" className="me-1" color="green"/>}</div>}

        <div>
          <div>{option.label}</div>
          {option.description && meta.context === 'menu' && <div>
            <small>{option.description}</small>
          </div>}
          {option.private && meta.context === 'menu' && <div>
            <small>{option.description && <span> | </span>}{option.private}</small>
          </div>}
        </div>
      </div>

      {meta.context === 'menu' && <div><small>{titleCase(option.type)}</small></div>}

    </div>
  );
