import React from 'react';
import {Row, Col} from "reactstrap";
import {FontAwesomeIcon as FaIcon} from "@fortawesome/react-fontawesome";
import {Utils} from "@thedmsgroup/mastodon-ui-components";
import EnhancedSelect from "../../../Components/Form/EnhancedSelect";
import {SourceTokenOptionLabel} from "../../../Components/Form/Select/SourceTokenOptionLabel";

/*
 * Message/form that appears in the flow-banner if there are too many source-tokens to display.
 * Presents an alert message and a picker that lets you search in the 'overflow' options by token or source,
 * and results in focusing the routing map on a source.
 *
 */
export const TokenOverflow = (
  {
    tokens,
    onSelect,
  }:any
) => {

  // const options = useMemo(() => {
  //   return tokens.map((token:any) => {
  //     return {value: token.id, label: token.token}
  //   })
  // }, [])

  const handleSelect = (sourceId:number|string) => {
    console.log("TokenOverflowNode.tsx:selected", sourceId )
    onSelect(sourceId);
  }

  return (

    <div  className="token-overflow">
      <Row>
        <Col sm={12} md={8} lg={7}>
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="d-flex align-items-center">
                <FaIcon icon="exclamation-triangle" size="lg" className="dog d-block me-2"/>
                <div>
                  <small>
                    There are {Utils.singularOrPlural(tokens.length, 'one', tokens.length)}
                    {' '} additional source-{Utils.singularOrPlural(tokens.length, 'token', 'tokens')} that could not be displayed here.
                    {' '} Select one to see routing mapped by source.
                  </small>

                </div>
              </div>
            </Col>
            <Col sm={6}>
              <div>
                <EnhancedSelect
                  value={''}
                  options={tokens}
                  controlShouldRenderValue
                  isMulti={false}
                  isSearchable
                  onChange={handleSelect}
                  formatOptionLabel={SourceTokenOptionLabel}
                  getOptionValue={(opt:any) => opt.source.id.toString()}
                  placeholder="Search by source name or token"
                  //Match on full token, partial token label, partial source name
                  filterOption={(option, input) => {
                    const {data} = option
                    return (data.label && data.label.toLowerCase().indexOf(input.toLowerCase()) >= 0)
                      || data.token.toLowerCase() === input.toLowerCase()
                      || data.source.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }}
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>






    </div>

  )
}

