import React, { useEffect, useState, useRef } from 'react';
import classnames from "classnames";
import {Button} from "reactstrap";
import {FontAwesomeIcon as FaIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import { RelativeTime } from '@thedmsgroup/mastodon-ui-components';
import { Handle } from '@xyflow/react';
import EntityId from "../../../Components/Table/EntityId";
import {DataRow, DataRows} from "../../../Components";
import {StatusBadge} from "./StatusBadge";
import {FlowNodeProps} from "./index";

type OrderNodeProps = FlowNodeProps & {
  onExamine:(order:any) =>void,
  onResize:(order:any) =>void,
}


export const OrderNode = (
  {
    data,
    targetPosition,
    onExamine,
    onResize,
  }:OrderNodeProps
) => {
  const {order, rules, alert, dataView, flowView} = data;
  const[expanded, setExpanded] = useState<boolean>(false);
  const resizeRef = useRef(null);

  const toggleExpanded = (e:any) => {
    e.stopPropagation()
    setExpanded(!expanded)
  }

  useEffect(() => {
    //Resize observer to trigger reflow of entire chart after expanded state updates
    if (resizeRef.current) {
      const observer = new ResizeObserver(onResize);
      observer.observe(resizeRef.current);

      // Cleanup function
      return () => {
        observer.disconnect();
      };
    }
  }, []);



  // stop Propagation so clicking buttons does not activate toggle
  const handleExamine = (e: any) => {
    e.stopPropagation();
    onExamine(order);
  }

  const handleGotoAuction = (e: any) => {
    e.stopPropagation()
    window.open(`#/auctions?page=1&order=${order.id}`, '_blank')
  }


  return (
    <div
      className={classnames("node order", order.status, {alert})}
      ref={resizeRef}
    >
      <StatusBadge status={order.status} />
      <Handle id="target-handle" type="target" position={targetPosition} />


        <div className="node-header">
          <div className="icon-col">
            <EntityId className="id me-1" id={order.id} title="Order ID"/>
          </div>
          <div className="node-title flex-fill">
            <div>
              {/*<StatusDot status={order.status} className="me-1"/>*/}
              <div>{order.name}</div>
              <div><small>{order.account.name}</small></div>
            </div>
            <div className="actions">

                <Button color="link" size="sm" onClick={handleExamine} title="Examine order routes">
                  <FaIcon icon="eye" size="sm"/>
                </Button>

              <Button
                  tag={Link}
                  color="link"
                  target="_blank"
                  to={{
                    pathname: `/accounts/${order.account.id}/order/${order.id}/rules`,
                  }}
                  size="sm"
                  title="View Order"
              >
                <FaIcon icon="external-link-alt" size="sm"/>
              </Button>
              <Button
                color="link"
                size="sm"
                title="View auctions"
                onClick={handleGotoAuction}
              >
                <FaIcon icon="comment-dollar" size="sm"/>
              </Button>
            </div>
          </div>

        </div>


      <div className="node-detail d-flex">
        <div className="icon-col">
          <FaIcon icon="clipboard-list" size="sm"/>
        </div>
        <div className="flex-fill">
          {expanded ? (
            <div className="node-expand">
              <Button size="sm" className="mb-1 inline" color="link" onClick={toggleExpanded}>Close</Button>
              <div className="rules">
                <div className="caption">Rules with bid modifiers on channels:</div>
                <div>
                  {rules && rules.length ? (
                    <>
                      {rules.map((rule:any, i:number) => (
                        <div className="rule" key={i}>
                          <div className="caption">
                            <Link
                              target="_blank"
                              className="link"
                              to={{
                                pathname: `/accounts/${order.account.id}/order/${order.id}/rules`,
                                search: `rule=${rule.id}`,
                              }}
                              title="View rule in order rule tree"
                            >
                              {rule.label} <FaIcon icon="external-link-alt" size="sm" className="ms-1"/>
                            </Link>
                          </div>

                          {/*{rule.channel_modifiers.length > 0 && (
                            <>
                              <DataRows labelWidth="160px"  gap="2px">
                                {rule.channel_modifiers.map((mod:any, i:number) => (<DataRow label={mod.channel.name} key={i}>
                                    {mod.bid_modifier}%
                                  </DataRow>
                                ))}
                              </DataRows>

                            </>
                          )}
*/}

                          <DataRows labelWidth="70px"  gap="2px">
                            {rule.bid_modifier && (<DataRow label="Bid Modifier">
                                {rule.bid_modifier}%
                              </DataRow>
                            )}
                            <DataRow label="Match">
                              <RelativeTime date={rule.last_matched_at} uniqueId={`rule-${rule.id}-match`} showTimezone={true} />
                            </DataRow>
                            <DataRow label="Sale">
                              <RelativeTime date={rule.last_sold_at} uniqueId={`rle-${rule.id}-sale`} showTimezone={true} />
                            </DataRow>
                          </DataRows>

                        </div>
                      ))}
                    </>
                  ) : (
                    <i>No rules found</i>
                  )}
                </div>
              </div>



            </div>
          ) : (
            <Button size="sm" className="inline" color="link" onClick={toggleExpanded}>View rules with channel</Button>
          )}
        </div>
      </div>





        {dataView.includes("sales") && (
          <div className="node-detail d-flex">
              <div className="icon-col">
                <FaIcon icon="calendar" size="sm"/>
              </div>
              <div className="flex-fill">

                <DataRows labelWidth="60px" noDivider gap="0.1em">
                  <DataRow label="Match">
                    <RelativeTime date={order.last_matched_at} uniqueId={`ord-${order.id}-match`} showTimezone={true} />
                  </DataRow>
                  <DataRow label="Sale">
                    <RelativeTime date={order.last_sold_at} uniqueId={`ord-${order.id}-sale`} showTimezone={true} />
                  </DataRow>
                </DataRows>
              </div>
          </div>
        )}

    </div>
  )
}

