import React, { useContext, useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import RulesContext from '../../../Providers/RulesContext';
import { TreeContext } from '../../../Components/Rules/TreeProvider';
import BidDisplay from '../../../Components/Rules/BidDisplay';
import RuleIcons from '../../../Components/Rules/RuleIcons';
import { RoutingIconSet } from '../../../Components/Rules/constants';

/**
 * Component for one item in the routing rules tree
 *
 * Note for future dev: the only difference between this and advertiser TreeRule
 * is this one has a button for opening rule actions
 * (in advertiser rules, the button and actions panel is in RulesConfig).
 * TODO: use a shared TreeRule, make actions panel work the same in both areas.
 */
const TreeRule = ({
  rule, expanded, setExpanded, level, parentDisabled,
}) => {
  const rulesContext = useContext(RulesContext);
  const treeContext = useContext(TreeContext);
  const [bidEditMode, setBidEditMode] = useState(false);
  // const[bidDelta, setBidDelta] = useState(null);
  // const[bidWarning, setBidWarning] = useState(null);

  const toggleBranch = (e) => {
    e.stopPropagation();
    setExpanded(!expanded);
  };

  // TODO: inline bidding
  // const handleBidClick = (e) =>{
  //   e.stopPropagation();
  //   treeContext.setCurrentBidEdit(rule.id);
  //   setBidEditMode(true);
  // };

  // If context tells us that rule focus has changed, set the bid edit mode if we are on this rule
  // (allows tabbing from rule to rule while in edit mode)
  useEffect(() => {
    if (treeContext.currentBidEdit === rule.id) {
      setBidEditMode(true);
    }
  }, [treeContext.currentBidEdit]);

  const isSelected = rulesContext.selectedRule && rule.id === rulesContext.selectedRule.id;

  const isDisabled = rule.disabled || parentDisabled;

  return (
    <div className={classnames(['tree-leaf', { root: level === 0 }])} id={`rule-${rule.id}`}>

      <div
        className={classnames('rule', {
          'is-selected': isSelected,
          'is-folder': rule.rules.length,
          disabled: isDisabled,
          'flow-include': rule.flow_control === 'include',
          'flow-exclude': rule.flow_control === 'exclude',
        })}
        onClick={() => rulesContext.api.setSelected(rule)}
      >

        <div className="rule-descriptors">
          {rule.flow_control === 'include' && <div className="descriptor flow-control">Include Only</div> }
          {rule.flow_control === 'exclude' && <div className="descriptor flow-control exclude">Exclude All</div> }
        </div>

        <div className="d-flex  align-items-center flex-wrap">

          {isDisabled && <FaIcon icon="ban" className="me-1" title="Rule disabled" />}
          <div className="rule-tree-label flex-fill">
            {!expanded && rule.rules.length > 0 && (
              <span className="rule-child-count me-1">
                (
                {rule.rules.length}
                )
              </span>
            )}
            {rule.label}
          </div>

          <div className="d-flex ms-auto align-items-center">
            {bidEditMode === false &&
              <RuleIcons
              size="sm" rule={rule}
              ruleType="routing"
              product={treeContext.product}
              iconSet={RoutingIconSet}
            />}

            <BidDisplay
              ruleId={rule.id}
              ruleType="routing"
              {...rule.bidding}
              clickHandler={() => {}}
            />

            <div className="rule-actions">
              { isSelected && rulesContext.allowEdit && (

              <Button
                color="link"
                className="m-0 pl-2"
                size="sm"
                disabled={rule.isNew}
                onClick={rulesContext.api.toggleActionsPane}
                title="Rule Actions"
              >
                <FaIcon icon="ellipsis-v" size="sm" />
              </Button>
              )}
            </div>
          </div>

        </div>

      </div>

    </div>
  );
};

TreeRule.propTypes = {
  rule: PropTypes.object,
  expanded: PropTypes.bool,
  setExpanded: PropTypes.func,
  level: PropTypes.number,
};

TreeRule.defaultProps = {
  expanded: true,
};

export default TreeRule;
