import React, {useMemo} from 'react';
import Select from '../Form/SimpleSelect';
import {DefaultReactSelectProps} from "./const";
import classnames from 'classnames';
import './EnhancedSelect.scss';


type EnhancedSelectPropsType = {
  onChange:any,
  options:any[],
  value:number|string,
  defaultValue?:number|string,
  label?:string,
  className?:string,
  disabled?:boolean,
  isInvalid?:boolean,
  invalidFeedback?:string,
  placeholder?:string|JSX.Element,
  // for react-select (this version not typed)
  getOptionValue?:(opt:any) => any,
  controlShouldRenderValue?:boolean,
  hideSelectedOptions?:boolean,
  closeMenuOnSelect?:boolean,
  tabSelectsValue?:boolean,
  backspaceRemovesValue?:boolean,
  isMulti?:boolean,
  isSearchable?:boolean,
  isClearable?:boolean,
  isLoading?:boolean,
  loadingMessage?:string|JSX.Element,
  formatOptionLabel?:any,
  returnValueAsString?:boolean,
  menuIsOpen?:boolean,
  filterOption?:(opt:any, input:string) => any
}

/*
 * Dropdown input that uses react-select (by way of SimpleSelect)
 * for enhanced features like search and multi-select. It uses bootstrap classes for invalid feedback.
 *
 * SimpleSelect allows us to use scalar values for react-select instead of options objects.
 *
 */
const EnhancedSelect = ({
                          onChange,
                          options = [],
                          value,
                          label,
                          disabled = false,
                          className="",
                          isInvalid,
                          invalidFeedback,
                          placeholder,
                          isLoading,
                          loadingMessage="",
                          returnValueAsString=true,
                          ...rest
                        }:EnhancedSelectPropsType) =>{

  const selectProps = useMemo(
    ()=>{
      const props = { ...DefaultReactSelectProps, ...rest }
      if (!returnValueAsString) {
        // @ts-ignore
        delete props.getOptionValue
      }
      return props;
     },
    [isLoading, placeholder, loadingMessage]);

  return (
    <div  className={classnames('enhanced-select', className, {"is-invalid":isInvalid})}>
      <div>
        {label && <label className="form-label">{label}</label>}
        <Select
          classNamePrefix="enhanced-select"
          isClearable
          onChange={onChange}
          options={options}
          value={value}
          tabSelectsValue
          backspaceRemovesValue
          placeholder={placeholder}
          isDisabled={disabled}
          isLoading={isLoading}
          loadingMessage={loadingMessage}
          {...selectProps}
        />
      </div>
      {invalidFeedback && <div className="invalid-feedback">{invalidFeedback}</div>}
    </div>
  );
}

export default EnhancedSelect
