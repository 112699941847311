import React, {useState, useEffect} from "react";
import {
  Button,
} from 'reactstrap';
import useApi from "../../../Hooks/useApi";
import {SourceTokenTable, Filters} from "../SourceToken";
import usePagedTableState from "../../../Hooks/usePagedTableState";
import PageSection from "../../../Layout/PageSection";
import DocTitle from "../../../Layout/DocTitle";
import FilterBar from "../../../Components/Table/FilterBar";
import {SourceTokenModal} from "./SourceTokenModal";



const DefaultControls = {
  limit: 15,
  sort_by: 'created_at',
  sort_dir: 'desc',
};

const SourceTokenList = () => {
  const {api} = useApi();
  const [isLoading, setIsLoading] = useState(true);
  const [pageCount, setPageCount] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const {
    filters, setFilters,  updateQuery, controls
  } = usePagedTableState(
    {},
    DefaultControls,
    { useQueryString: true, useTableControls: true },
  );
  const [tokens, setTokens] = useState([])
  const [selectedToken, setSelectedToken] = useState(null);



  useEffect(() => {
    load();
  }, [filters, controls.sort_by, controls.sort_dir, controls.page, controls.limit])

  const load = async() => {
    setIsLoading(true);
    updateQuery();
    const {
      page, sort_by, sort_dir, limit,
    } = controls;
    const result = await api.endpoints.sourceTokens.list({
      ...filters,
      page,
      sort_by,
      sort_dir,
      limit,
    });


    if (result) {
      const { limit, total, data } = result;
      setTokens(data);
      setPageCount(total > limit ? Math.ceil(total / limit) : 1);
      setTotalRows(total);
    }
    setIsLoading(false);
  }


  const archive = async(sourceToken) => {
    setIsLoading(true);
    const result = await api.endpoints.sourceTokens.update({id:sourceToken.id, status:'archived'})
    if (result) {
      load();
    }
    setIsLoading(false);
  }

  const onCloseForm = () =>  setSelectedToken(null);


  const onAdd = () => {
    setSelectedToken({
      id: 0,
      token: "",
      seed_data: null,
      status: 'active',
      source: undefined,
      vendor: undefined,
    });
  }

  const onEdit = (sourceToken=null) => {
    setSelectedToken(sourceToken);
  }


  return (
   <>
     <DocTitle pageTitle="Source-Vendor Tokens" />

     <FilterBar>
       <Filters
         filters={filters}
         onChange={setFilters}
         loading={isLoading}
         onRefresh={load}
       />


     </FilterBar>

     <PageSection
       title="Source-Tokens"
       tools={<Button
         size="sm"
         onClick={onAdd}
       >
         Add
       </Button>}
     >

        <SourceTokenTable
          data={tokens}
          loading={isLoading}
          noDataMessage="No source-tokens were found"
          onEdit={onEdit}
          onArchive={archive}
          pageCount={pageCount}
          totalRows={totalRows}
          {...controls}
        />

   </PageSection>

     {/* TODO token subtitle {selectedToken.id > 0 && <div className="modal-subheader">{selectedToken.label || selectedToken.token}</div>} */}
       {selectedToken && (
           <SourceTokenModal
             isOpen={Boolean(selectedToken)}
             token={selectedToken}
             toggle={onCloseForm}
             onSuccess={load}
             scrollable
           />
       )}

   </>
  )
}

export default SourceTokenList;


