import React from 'react';
import classnames from "classnames";
import {Button} from "reactstrap";
import {FontAwesomeIcon as FaIcon} from "@fortawesome/react-fontawesome";
import {ChannelSourceRoute} from "../Rules";
import { RelativeTime } from '@thedmsgroup/mastodon-ui-components';
import {Handle} from "@xyflow/react";
import {FlowNodeProps} from "./index";


type EligibilityNodeProps = FlowNodeProps & {
  onLinkSourceToChannel:(source:any) =>void,
  onOpenRules:(channelSourceRoute:ChannelSourceRoute) =>void,
  onOpenChannelSources:(channel:any) =>void,
}


export const EligibilityNode = (
  {
    //Passed by react-flow
    targetPosition,
    sourcePosition,
    data,
    //additional properties
    onOpenRules,
    onLinkSourceToChannel,
    onOpenChannelSources,
  }:EligibilityNodeProps
) => {

  const {eligibility, source, channel, alert, dataView} = data;

  if (!eligibility) return null;


  const handleOpenRule = (e: any) => {
    e.stopPropagation();
    if (data.source && data.channel) {
      onOpenRules(new ChannelSourceRoute(channel, {...source, eligibility}));
    }
  }

  const handleLinkChannelSource = (e:any) => {
    e.stopPropagation();
    if (data.source ) {
      onLinkSourceToChannel(source);
    }
  }

  const handleOpenChannelSources = (e:any) => {
    e.stopPropagation();
    onOpenChannelSources(channel);

  }


  const compositeId = `${source.id}-${channel.id}`

  return (
    <div className={classnames("node eligibility", {alert})}>
      <Handle id="target-handle" type="target" position={targetPosition} />
      <Handle id={'source-handle'} type="source" position={sourcePosition} />

        <div className="node-header">
          <div className="node-title flex-fill">
            {channel.id > 0 && source.id > 0 && (
              <div className="fw-normal">
                <i>Source eligibility for channel</i>
              </div>
            )}

            {(source.id > 0 && !channel.id )&& (
              <div className="alert-message w-100 d-flex flex-nowrap align-items-center">
                <div><FaIcon icon="exclamation-triangle" size="sm" className="me-1"/></div>
                <div className="flex-fill"><i>Source not used by a channel</i></div>
                <Button color="link" size="xs" onClick={handleLinkChannelSource} title="Link a channel to this source">
                  <FaIcon icon="plus" size="sm"/> Add
                </Button>
              </div>
            )}

            {(channel.id > 0 && !source.id) && (
              <div className="alert-message w-100 d-flex flex-nowrap">
                <div><FaIcon icon="exclamation-triangle" className="me-1" size="sm"/></div>
                <div className="flex-fill"><i> Channel has no sources</i></div>
                <Button color="link" size="xs" onClick={handleOpenChannelSources} title="Link a source to this channel">
                  <FaIcon icon="plus" size="sm"/> Add
                </Button>
              </div>
            )}


            {channel.id > 0 && source.id > 0 && (
              <div className="actions">
                {/* Removed the modal in favor of editing eligibilities from the channel */}
                {/*<Button color="link" size="sm" onClick={handleOpenSettings} title="Edit eligibility settings" className="me-1">
                <FaIcon icon="cog" size="sm"/>
              </Button>*/}
                <Button color="link" size="sm" onClick={handleOpenRule} title="View/Edit routing rule">
                  <FaIcon icon="clipboard-list" size="sm"/>
                </Button>
              </div>
            )}

          </div>
        </div>

        {channel.id > 0 && dataView.includes("sales") && (
          <div className="node-detail d-flex">
              <div className="icon-col">
                <FaIcon icon="calendar" size="sm"/>
              </div>
              <div>
                <div>
                  <label>Match:</label> <RelativeTime date={eligibility.last_matched_at} uniqueId={`eg-${compositeId}-match`} showTimezone={true} />
                </div>
                <div>
                  <label>Sale:</label> <RelativeTime date={eligibility.last_sold_at} uniqueId={`eg-${compositeId}-sale`} showTimezone={true} />
                </div>
              </div>

          </div>
        )}


    </div>
  )
}
