import React, {useState, useEffect} from "react"
import {
  Button, Modal, ModalHeader, ModalBody
} from 'reactstrap'
import useApi from "../../../../Hooks/useApi"
import {SourceTokenTable, SourceTokenForm, Filters} from "../../SourceToken"
import useTableState from "../../../../Hooks/useTableState"
import {FontAwesomeIcon as FaIcon} from "@fortawesome/react-fontawesome"

const DefaultControls = {
  limit: 20,
  sort_by: 'created_at',
  sort_dir: 'desc',
};

/*
 * Modal that lists vendors related to sources (via a token).
 * Allows editing and adding tokens
 */
const Vendors = ({source, isOpen, close}) => {
  if(!source) return null
  const {api} = useApi()
  const {
    filters, setFilters,  query, controls
  } = useTableState(
    {source:source.id},
    DefaultControls,
    { useQueryString: false, useTableControls: true },
  )
  const [isLoading, setIsLoading] = useState(true)
  const [tokens, setTokens] = useState([])
  const [pageCount, setPageCount] = useState(1)
  const [totalRows, setTotalRows] = useState(0)
  const [selectedToken, setSelectedToken] = useState(null)
  const [refreshOnClose, setRefreshOnClose] = useState(false)


  useEffect(() => {
    if (isOpen) {
      load()
    }
    return () => {
      setTokens([]);
    }
  }, [isOpen, source, query])

  const load = async() => {
    setIsLoading(true);
    const {
      page, sort_by, sort_dir, limit,
    } = controls
    const result = await api.endpoints.sourceTokens.list({
      source: source.id,
      ...filters,
      page,
      sort_by,
      sort_dir,
      limit,
    });

    if (result) {
      const { limit, total, data } = result
      setTokens(data)
      setPageCount(total > limit ? Math.ceil(total / limit) : 1)
      setTotalRows(total)
    }
    setIsLoading(false)
  }


  const archive = async(sourceToken) => {
    setIsLoading(true)
    const result = await api.endpoints.sourceTokens.update({id:sourceToken.id, status:'archived'})
    if (result) {
      load()
    }
    setIsLoading(false);
  }


  const onCloseForm = (refresh) => {
    if (refresh === true) { // could be click event
      load()
    }
    setSelectedToken(null);
  }

  const onAdd = () => {
    setSelectedToken({
      id: 0,
      token: "",
      seed_data: null,
      status: 'active',
      source: source,
      vendor: {id:0, name:''}
    })
  }

  const onCloseModal = () => {
    setSelectedToken(null)
    close(refreshOnClose);
  }
  const onEdit = (sourceToken=null) => {
    setSelectedToken(sourceToken)
  }

  const handleUpdateTokenSuccess = () => {
    setRefreshOnClose(true)
    load()
  }


  return (
    <Modal isOpen={isOpen} toggle={onCloseModal} size="xlg" id="source-vendor-modal" scrollable>
        <ModalHeader toggle={onCloseModal}>
          {selectedToken?.id > 0 && <Button color="link" size="sm" className="inline me-1" onClick={onCloseForm}>
            <FaIcon icon="arrow-left" />
          </Button>
          }
          {selectedToken ? (selectedToken.id > 0 ? 'Edit Source Token' : 'Create Source Token') : 'Source Tokens for Source'}
        </ModalHeader>
        <div className="modal-subheader">{source.name}</div>

          {selectedToken ? (
            <SourceTokenForm
              source={source}
              sourceToken={selectedToken}
              close={onCloseForm}
              onSuccess={handleUpdateTokenSuccess}
              isModal
            />
          ) : (
            <ModalBody>
                <div className="source-vendors above-table-filters d-block flex-fill mb-2">
                  <Filters
                    filters={filters}
                    onChange={setFilters}
                    onRefresh={load}
                    loading={isLoading}
                    showVendors={false}
                  />
                </div>


              <div className="text-end">
                <Button
                  size="sm"
                  onClick={onAdd}
                  className="ms-2"
                >
                  <FaIcon icon="plus" size="sm"/> Add Token
                </Button>
              </div>

              <SourceTokenTable
                showSource={false}
                data={tokens}
                loading={isLoading}
                noDataMessage="No source tokens were found"
                onEdit={onEdit}
                onArchive={archive}
                pageCount={pageCount}
                totalRows={totalRows}
                {...controls}
              />
            </ModalBody>
          )}

      </Modal>
  )
}

export default Vendors

