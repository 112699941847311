import React, { useState, useCallback, useEffect } from 'react';
import { InputGroup,  Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import useApi from '../../Hooks/useApi';
import EnhancedSelect from "./EnhancedSelect";

/*
 * React-Select element for that fetches choices from the API for the indicated resource
 * Shows spinner while loading.
 *
 * React-select expects options to have a value and a label.
 * By default, this component will use 'id' for the option value and 'name' for the label value
 */
const DynamicEnhancedSelect = ({
  name,
  endpoint,
  action = 'list',
  params={},
  value,
  placeholder = 'Select...',
  onChange,
  returnOption=false,
  valueProperty='id',
  labelProperty='name',
  invalid=false,
  invalidFeedback='',
  ...selectProps
}) => {

  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState([]);
  const api = useApi(endpoint, action);

  // fetch on load
  useEffect(() => {
    if (!items || !items.length) {
      load();
    }
  }, []);

  const load = async() => {

    const result = await api.fetch(params);
    if (result) {

      //if paged, use 'data' array
      const dataset = result.data ?? result;

      const choices =  dataset.reduce((acc, item) => {
          if (typeof item[valueProperty] !== 'undefined'){
            acc.push({...item, value:item[valueProperty], label:item[labelProperty] ?? 'Label Unknown'})
          }
          return acc;
        }, [])
      setItems(choices)

    } else  {
      console.log('Unable to load items for dynamic select! ', name, api.error ?? '');
    }

    setIsLoading(false);
  }

  const handleOnChange = useCallback((val) => {
    // Returned values will be the ID, or whatever was specified as the value property
    // if Multiselect, value will be an array of IDs
    // Optionally we can also return the entire object(s) from the source data
    if (returnOption) {
      const opts = Array.isArray(val)
        ? items.filter((item) => val.includes(item.value))
        : items.find((item) => item.value === val)

      onChange(val, opts)
    } else {
      onChange(val)
    }
  }, [items])

  return (
    <>
      { isLoading ? (
        <div className="inline-loading">
          <Spinner size="sm" />
          {' '}
          Loading...
        </div>
      ) : (
        <InputGroup>

          <EnhancedSelect
            onChange={handleOnChange}
            options={items}
            value={value || ''}
            isInvalid={invalid}
            invalidFeedback={invalidFeedback}
            placeholder={placeholder}
            getOptionValue={(opt) => opt[valueProperty] ?? ''}
            {...selectProps}
          />
        </InputGroup>
      )}
    </>

  );
};

DynamicEnhancedSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
  placeholder: PropTypes.string,
  invalid: PropTypes.bool,
  invalidFeedback: PropTypes.string,
  name: PropTypes.string.isRequired,
  endpoint: PropTypes.string.isRequired,
  action: PropTypes.string,
  labelProperty: PropTypes.string,
  params: PropTypes.object,
};
DynamicEnhancedSelect.defaultProps = {
  onChange: () => {},
  invalid: false,
  labelProperty: 'name',
};

export default DynamicEnhancedSelect;
