import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import copy from 'fast-copy';
import usePermission from "../../Hooks/usePermission";

const TreeContext = React.createContext();

function getRuleIdArray(rule) {
  let ids = [];
  if (rule) {
    ids.push(rule.id);
    if (rule.rules.length) {
      rule.rules.map((rule) => {
        const treeIds = getRuleIdArray(rule);
        ids = [...ids, ...treeIds];
      });
    }
  }
  return ids;
}

const TreeProvider = (props) => {
  // Set rule IDs to currentBidEdit/Updating
  const [currentBidEdit, setCurrentBidEdit] = useState(0);
  const [currentBidUpdating, setCurrentBidUpdating] = useState(0);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState('');
  const [isFiltered, setIsFiltered] = useState(false);
  const [filteredResultCount, setFilteredResultCount] = useState(0);
  // Array of just the rule ids so we can know our position in the tree while using keys to move
  const [ruleIds, setRuleIds] = useState(getRuleIdArray(props.rule));
  const [closedBranches, setClosedBranches] = useState([]);

  // Set listeners for traversal of tree using keys
  useEffect(() => {
    const El = document.getElementById('col-rule-tree');

    const handler = function (event) {
      event.stopPropagation();
      // TODO  revert if bad input
      if (ruleIds.length <= 1) return;

      if ((event.shiftKey && event.key === 'Tab') || event.key === 'ArrowUp') {
        // MOVE UP
        // console.log('TreeProvider.js:useEffect: SHIFT-tab OR UP was pressed!', currentBidEdit);
        // Note: in Chrome, shift-tab will move focus from the input to the URL bar
        setCurrentBidEdit((id) => {
          const idx = ruleIds.indexOf(id);
          return idx > 0 ? ruleIds[idx - 1] : ruleIds[ruleIds.length - 1];
        });
      } else if (event.key === 'Tab' || event.key === 'ArrowDown') {
        // MOVE DOWN
        // console.log('TreeProvider.js:useEffect: tab or down was pressed!', currentBidEdit);
        setCurrentBidEdit((id) => {
          const idx = ruleIds.indexOf(id);
          return idx + 1 < ruleIds.length ? ruleIds[idx + 1] : ruleIds[0];
        });
      }
    };

    El && El.addEventListener('keydown', handler);
    return () => {
      El && El.removeEventListener('keydown', handler);
    };
  }, []);

  const getMatches = (rule, matchCount = 0) => {
    // TODO: don't show root rule if nothing matches, including root rule (show "none found" message?)
    // or: show root rule but have message "No matching rules found")

    if (search || filter) {
      // Clone because we are adding properties to the rule object,
      // and these should go away when search is cleared or changes.
      const filteredRule = copy(rule);
      const childCount = filteredRule.rules.length;
      // filter by name search first
      filteredRule.isMatch = search ? filteredRule.label.toLowerCase().includes(search.toLowerCase()) : true;

      // Filter on rule properties if not filtered out by search
      // (see useRuleTree hook "enhanceTree" and "ruleHasProp" where we add the properties
      if (filteredRule.isMatch && filteredRule.summary) {
        filteredRule.isMatch = filter ? filteredRule.summary.includes(filter) : true;
      }

      if (filteredRule.isMatch) {
        ++matchCount;
      }

      filteredRule.hasChildren = filteredRule.rules.length > 0;

      filteredRule.rules = filteredRule.rules.reduce((acc, rule) => {
        rule = getMatches(rule, matchCount);
        if (rule.isMatch || rule.rules.length) {
          ++matchCount;
          acc.push(rule);
        }
        return acc;
      }, []);

      // Used to display a notification like "12 filtered" under a tree branch (feature is disabled)
      // filteredRule.filteredCount = filteredRule.hasChildren ? childCount - filteredRule.rules.length : 0;

      // TBD We should update the rule ids array used for tabbing through bids,
      // but we can't do it here while recursing

      // setRuleIds(getRuleIdArray(filteredRule));
      setIsFiltered(true);
      setFilteredResultCount(matchCount);
      return filteredRule;
    }
    setIsFiltered(false);
    setFilteredResultCount(0);

    return rule;
  };

  const clearSearch = () => {
    setSearch('');
    setFilter('');
    setRuleIds(getRuleIdArray(props.rule));
  };

  const onToggleBranch = (id, expanded) => {
    setClosedBranches(prev => {
      if (expanded && prev.includes(id)) {
        return prev.filter(current => current !== id )
      } else if (!expanded) {
        return [...prev, id]
      }
      return [...prev];
    })
  }

  return (
    <TreeContext.Provider value={{
      currentBidEdit,
      setCurrentBidEdit,
      currentBidUpdating,
      setCurrentBidUpdating,
      getMatches,
      search,
      filter,
      isFiltered,
      filteredResultCount,
      clearSearch,
      setSearch,
      closedBranches,
      onToggleBranch,
      setFilter,
      ruleType: props.ruleType,
      product: props.product,
    }}
    >
      {props.children}
    </TreeContext.Provider>
  );
};

TreeProvider.propTypes = {
  rule: PropTypes.object,
  ruleType: PropTypes.string, // routing (publisher) or targeting (advertiser)
  children: PropTypes.node,
  product: PropTypes.string,
};

export { TreeProvider, TreeContext };
