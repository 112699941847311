import React, {
  useState, useEffect,
} from 'react';

import EnhancedSelect from "../../../../Components/Form/EnhancedSelect";
import { Table, Badge, Button } from 'reactstrap';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import {
  notify,
  StandardAlert,
  //HelpPopper,
  //MaskedInputGroup,
  Utils
} from "@thedmsgroup/mastodon-ui-components";

import useApi from '../../../../Hooks/useApi';
import {ChannelSourceRoute} from "../../../Routing/Rules";
import {SourceOptionLabel} from "../../../../Components/Form/Select/SourceOptionLabel";
import EntityId from "../../../../Components/Table/EntityId";
import {Channel, ChannelSource, ProductType} from "../../../../types";
//import IconCheckbox from '../../../Components/Form/IconCheckbox';
//import StatusToggler from "../../../Components/Form/StatusToggler";


// We decided to allow distribution to be nullable
// import * as Yup from "yup";
// const ChannelSourceValidation = (
//   Yup.addMethod(Yup.mixed, 'validChannelSources', function(message) {
//     return this.test('test-channel-sources', message,
//       function(channelSources) {
//         let valid = true;
//         channelSources.map( (item) => {
//           //console.log('ChannelSourceDetail.js:testing', item, parseInt(item.distribution));
//           if( isNaN(parseInt(item.distribution))){
//             valid = false;
//           }
//         });
//
//         return valid;
//       })
//   })
// );
//
// const ChannelSourceValidationSchema = Yup.object().shape({
//   sources: Yup.mixed().validChannelSources('Please check that all dates are filled out, and from/to dates are in the right order'),
// });

// const PercentMaskOptions = {
//   mask: Number,
//   unmask: true,
//   scale:0,
//   max:100,
//   signed: false,
// }

const DefaultChannelSource = {
  id:0,
  name:"",
  status: "",
  eligibility: {
    distribution:"",
    fixed_distribution:false,
    status: "active"
  }
}

/*
 * Channel Sources
 * Table and inputs for editing "channel eligibilities" (sources that distribute on a channel)
 *
 * Note: we are no longer using the distribution, fixed, or status field, though they are still in the data structure
 */
type ChannelSourcesProps = {
  channel:Channel,
  //product:ProductType,
 // verticalId: number,
  sources:ChannelSource[],
  onChange:(sources:ChannelSource[])=>void,
  onOpenRules:(cs:ChannelSourceRoute)=>void
}

export const ChannelSources = ({ channel, sources, onChange, onOpenRules }:ChannelSourcesProps) => {

  const [sourceOptions, setSourceOptions] = useState([]);
  const [addIndex, setAddIndex] = useState<null|number>(null);

  // We must have channel vertical and product in order to get the source options
  // (In channel creation mode, user might not have picked these yet)
  const isReady = channel && channel.product && channel.vertical;
  //const sortDistributionDesc = (items:any[]) => items.sort((a, b) => (parseInt(a.distribution) < parseInt(b.distribution) ? 1 : -1));

  const { api } = useApi();

  useEffect(() => {
    if (isReady) {
      loadSourceOptions()
    }
  }, [channel])

  const loadSourceOptions = async() => {
    const result = await api.endpoints.sources.list({
      options:true,
      product:channel.product,
      vertical_id:channel.vertical.id
    })

    if (result) {

      setSourceOptions(result.map((s:any )=> {
        return {
          value:s.id,
          label:s.name,
          status:s.status,
          vertical:s.vertical,
          product:s.product
        }
      }),)
    }
  }

  // const handleChangeDistribution = useCallback((val:string, index:number) => {
  //   const sources = [...formValues.sources];
  //
  //   if (sources[index]) {
  //     sources[index].eligibility.distribution = val;
  //     formApi.setValues({ sources });
  //   }
  // }, [formValues]);

  // const handleChangeFixed = useCallback((fixed:boolean, index:number) => {
  //   const sources = [...formValues.sources];
  //   if (sources[index]) {
  //     sources[index].eligibility.fixed_distribution = fixed;
  //
  //     formApi.setValues({ sources });
  //   }
  // }, [formValues]);
  //
  // const handleChangeEligibilityStatus = useCallback((status:string, index:number) => {
  //   const sources = [...formValues.sources];
  //   if (sources[index]) {
  //     sources[index].eligibility.status = status;
  //
  //     formApi.setValues({ sources });
  //   }
  // }, [formValues]);

  const handleChangeSource = (source:ChannelSource, index:number) => {
    const newSources = [...sources]
    if (newSources[index]) {
      newSources[index] = source
      onChange(newSources)
    }
  }

  const handleAddNew = () => {
    const newSources = removeEmptySources(sources);
    newSources.push({...DefaultChannelSource})
    setAddIndex(newSources.length - 1)
    onChange(newSources)
  }

  //We're just removing rows without selected sources instead of bothering with validation
  const removeEmptySources = (arr:any[]) =>  arr.filter(src => src.id)


  const handleRemove = (index:number) => {
    let newSources = [...sources]
    newSources.splice(index, 1)
    newSources = removeEmptySources(newSources)
    onChange(newSources)
    setAddIndex(null)
  };


  const handleOpenRules = (index:number) => {
    const source = sources[index]

    if (source) {
      if (channel.id && source.eligibility.rule_id) {
        onOpenRules(new ChannelSourceRoute(channel, source))
      } else {
        notify('Please save before editing rules', 'info', { containerId: `channel-source-${channel.id}` });
      }
    }
  };

  return isReady ? (
    <div className="channel-sources">


        <div className="source-rows">
          {sources.length > 0 ? (
            <Table>
              <thead>
              <tr>
                <th>Source</th>
                {/*                <th>
                  Distribution{' '}
                  <HelpPopper name="source-distribution" title="Distribution">
                    Optionally specify a distribution percentage.
                    The value may be automatically raised to adjust for low volume
                    in other sources. When fixed, the distribution will not change.
                  </HelpPopper>
                </th>
                <th>Status <HelpPopper name="channel-source-status" title="Channel-Source Status" iconClass="ms-1">
                  Activate or pause a specific channel source. This does not affect
                  the status of the channel or source in general.
                </HelpPopper></th>*/}
                <th>&nbsp;</th>
              </tr>

              </thead>
              <tbody>
              {sources.map((source:any, i:any) => (
                <ChannelSourceRow
                  index={i}
                  source={source}
                  sourceOptions={sourceOptions}
                  isNew={i === addIndex}
                  onRemove={handleRemove}
                  onChangeSource={handleChangeSource}
                  //onChangeDistribution={handleChangeDistribution}
                  // onChangeFixed={(value:boolean) => handleChangeFixed(value, i)}
                  //onChangeEligibilityStatus={(status:string) => handleChangeEligibilityStatus(status, i)}
                  openRules={handleOpenRules}
                  key={`source-${i}`}
                />
              ))}
              </tbody>

            </Table>
          ) : (
            <StandardAlert color="light">No sources are assigned.</StandardAlert>
          )}

        </div>

        <div className="mb-2">
          <Button color="link" size="sm" onClick={handleAddNew}  >
            <FaIcon icon="plus" />{' '}Source
          </Button>
        </div>



    </div>
  ) : (
    <StandardAlert icon="alert" color="warning">
      A vertical and product for the channel must be selected before configuring a channel&apos;s sources
    </StandardAlert>
  )
};



type ChannelSourceRowProps = {
  index:number,
  isNew:boolean,
  source:any,
  onRemove:(index:number) => void,
  onChangeSource: (source:ChannelSource, index:number)=>any,
  //onChangeDistribution: (value:string, index:number)=>any,
  //onChangeFixed: (value:boolean, index:number)=>any,
  //onChangeEligibilityStatus: (value:string, index:number)=>any,
  openRules: (index:number)=>any,
  sourceOptions: any[]
}
const ChannelSourceRow = ({
  index,
  isNew,
  source,
  onRemove,
  onChangeSource,
 // onChangeDistribution,
  //onChangeFixed,
 // onChangeEligibilityStatus,
  openRules,
  sourceOptions
}:ChannelSourceRowProps) => {

  const handleSelectSource = (sourceId:string) => {
    const source = sourceOptions.find((s) => s.value.toString() === sourceId);

    if ( source ) {
      onChangeSource({
        id: source.value,
        name: source.label,
        status: source.status,
        eligibility: {
          distribution:"",
          fixed_distribution: false,
          status: "active"
        }
      } , index);
    }
  }

  return (
    <tr className="channel-source-row align-items-center">

      {isNew ? (
        <td valign="middle">

            <EnhancedSelect
              isClearable
              isSearchable
              onChange={handleSelectSource}
              options={sourceOptions}
              value={source.id.toString()}
              tabSelectsValue
              backspaceRemovesValue
              isMulti={false}
              controlShouldRenderValue
              hideSelectedOptions
              closeMenuOnSelect
              placeholder="Select Source (type to search)"
              isInvalid={false}
              formatOptionLabel={SourceOptionLabel}
             // menuIsOpen={true}
            />

        </td>
      ) : (
        <td  valign="middle">
          <EntityId id={source.id} className="me-1"/>
          {source.name}
          {source.status !== "active" && <Badge size="xs" color={source.status === 'archived'?'danger':'warning'} className="ms-1">{Utils.titleCase(source.status)}</Badge>}
        </td>
      )}


{/*      <td className=" d-flex align-items-center">
        <SourceDistributionInput value={source.eligibility.distribution} onChange={(value:string) => onChangeDistribution(value, index)}/>

        <SourceDistributionFixedCheckbox value={!!source.eligibility.fixed_distribution} onChange={(fixed:boolean) => onChangeFixed(fixed, index)}/>
      </td>
      <td valign="middle">
        <div className="d-flex flex-nowrap align-items-center">
          <StatusToggler
            status={source.eligibility.status}
            target={index}
            onChange={(id, status) => onChangeEligibilityStatus(status, id)}
            showArchived={false}
            allowEdit={true}
          />
        </div>
      </td>*/}
      <td valign="middle" className="text-end">
        {/* Newly added, unsaved channel sources can't edit rules because they don't have IDs first */}
        <Button
          color="link"
          className="inline me-3"
          onClick={() => openRules(index)}
          title="Channel-Source routing rules"
          disabled={!source.eligibility.rule_id}
        >
          <FaIcon icon="clipboard-list" size="sm" />
          {" "}
          Routing Rules
        </Button>

        <Button onClick={()=>onRemove(index)} color="link" size="sm" className="inline" title="Remove">
          <FaIcon icon="times" size="sm" color="darkred"/>
        </Button>
      </td>

    </tr>
  )
};


// type SourceDistributionInputProps = {
//   value:string|number,
//   onChange:(value:string) => void
// }
// const SourceDistributionInput = ({ value, onChange }:SourceDistributionInputProps) => (
//
//   <MaskedInputGroup
//     className="teeny text-end"
//     {...PercentMaskOptions}
//     value={value !== null ? value.toString() : ''}
//     onAccept={(value) => onChange(value)}
//     //invalid={!!formErrors.interval}
//     placeholder={!value && value !== '0' ? 'open' : ''}
//     suffix="%"
//   />
// );
//
//
//
// type SourceDistributionFixedCheckboxProps = {
//   value:boolean,
//   onChange:(value:boolean) => void
// }
// const SourceDistributionFixedCheckbox = ({ value, onChange }:SourceDistributionFixedCheckboxProps) => (
//   <div className="ms-3 d-flex flex-nowrap align-items-center">
//     <IconCheckbox
//       name="fixed_distribution"
//       value={!!value}
//       onChange={onChange}
//     >Fixed</IconCheckbox>
//   </div>
//);

