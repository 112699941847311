import React, {useMemo} from 'react';
import { Link } from 'react-router-dom';
import { Label, Button } from 'reactstrap';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import PageSection from '../../../Layout/PageSection';
import usePermission from '../../../Hooks/usePermission';
import { titleCase } from "@thedmsgroup/mastodon-ui-components/lib/utils/string";
import StatusToggler from "../../../Components/Form/StatusToggler";
import useApi from '../../../Hooks/useApi';
import {notify} from "@thedmsgroup/mastodon-ui-components/lib/common/Notify";
import {RelativeTime} from "@thedmsgroup/mastodon-ui-components";
import {RoutedEntityType} from "../../../types";

type EntityInfoBarType = {
  entityType:RoutedEntityType,
  entity: any
}

/*
 * Bar at top of routing rules manager page shows info and links
 * related to the routed entity (source, channel, channel-source, or vendor)
 */
const EntityInfoBar = ({ entityType, entity }:EntityInfoBarType) => {
  const allowEditSources = usePermission('sources.edit');
  const allowEditChannels = usePermission('channels.edit');
  const allowStatusToggle = (entityType==="source" && allowEditSources) || (entityType==="channelsource" && allowEditChannels);
  const allowViewAuctions = usePermission('auctions.view');

  const linkSearch = useMemo(()=>{
    let routingMap, auctions;
    switch (entityType) {
      case "channel":
        auctions = `page=1&${entityType}=${entity.id}`
        routingMap = `${entityType}=${entity.id}`
        break;
      case "source":
        auctions = `page=1&${entityType}=${entity.id}`
        routingMap = `${entityType}=${entity.id}`
        break;
      case "channelsource":
        //entity is ChannelSourceRoute
        auctions = `page=1&source=${entity.source.id}&channel=${entity.channel.id}`
        routingMap = `source=${entity.source.id}`
        break;
    }
    return {routingMap, auctions};
  }, [entityType])

  const sourceUpdate = useApi('sources', 'update');
  const channelUpdate = useApi('channels', 'update');

  // Only sources or channel-sources
  // We don't currently change channel status in the UI
  const handleStatusChange = async (id:number, status:string) => {
    if (allowStatusToggle) {
      let result;
      if (entityType === "channelsource") {
        // we update the whole channel in this case
        const chn = { ...entity.channel };

        chn.sources = chn.sources.map((cs: any) =>
          ({
            source_id: cs.id,
            channel_id: chn.id,
            ...cs.eligibility,
            status: cs.id === entity.source.id ? status : cs.eligibility.status
          }));

        result = await channelUpdate.fetch(chn);
      } else if (entityType === "source") {
        result = await sourceUpdate.fetch({
          id: entity.id,
          name: entity.name,
          product: entity.product,
          vendor_id: entity.vendor.id,
          vertical_id: entity.vertical.id,
          token: entity.token,
          status,
        });
      }



      if (result) {
        notify('Status updated', 'success');
      } else {
        notify('Unable to update status', 'error');
      }

      return Boolean(result);
    }
    return false;
  };

  return (
    <PageSection className="entity-info-section mb-3">

      <div>
        <h5 className="p-0 m-0 me-4 mb-3">
            <span className="card-title">
              {entityType === 'channelsource' ? 'Channel-Source' : titleCase(entityType)} Routing:
              <span className="subtitle">{entity.name}</span>
            </span>
        </h5>
      </div>

      <div className="d-flex justify-content-between align-items-center">

        {/* Links and actions */}
        <div className="d-flex ">
            {allowViewAuctions && entityType !== "vendor" && (
              <>
                <span className="action-item">
                  <Button
                    tag={Link}
                    color="link"
                    to={{
                      pathname: '/auctions',
                      search: linkSearch.auctions,
                    }}
                    title="Auctions"
                    className="inline ms-2"
                  >
                    <FaIcon icon="comment-dollar" size="sm" /> Auctions
                  </Button>
                </span>
                <span className="action-item">
                  <Button
                    tag={Link}
                    color="link"
                    to={{
                      pathname: '/routing',
                      search: linkSearch.routingMap,
                    }}
                    title="Routing Map"
                    className="inline ms-2"
                  >
                    <FaIcon icon="project-diagram" size="sm" /> Routing
                  </Button>
                </span>
              </>

            )}

        </div>

        {/* Entity Info */}
        <div className="d-flex align-items-center">


          {entity.account && (
            <div className="entity-info-cell">
              <Label>Account</Label>
              <div>{entity.account.name}</div>
            </div>
          )}

          {entityType !== "vendor" && (
          <div className="entity-info-cell">
            <Label>Vertical</Label>
            <div>{entity.vertical.display_name}</div>
          </div>
          )}

          {entityType !== "vendor" && (
            <div className="entity-info-cell">
              <Label>Product</Label>
              <div>{titleCase(entity.product)}</div>
            </div>
          )}

          {entityType === "channelsource" && (
            <div className="entity-info-cell">
              <Label>Distribution</Label>
              <div>
                {!isNaN(parseInt(entity.distribution)) ? `${entity.distribution}%` : <span>&mdash;</span> }
                {entity.fixed_distribution && <span> (fixed)</span>}
              </div>
            </div>
          )}

          {/* Can update source, but not channel */}
          {(entityType === "source" || entityType === "channel") && (
            <div className="entity-info-cell">
              <Label>{titleCase(entityType)} Status</Label>
              <div>
                <StatusToggler
                  status={entity.status}
                  target={entity.id}
                  onChangeAsync={handleStatusChange}
                  allowEdit={allowStatusToggle}
                />
              </div>
            </div>
          )}

          {entityType === "channelsource" && (
            <>
              <div className="entity-info-cell">
                <Label>Channel Status</Label>
                <div>
                  <StatusToggler
                    status={entity.channel.status}
                    target={entity.channel.id}
                    allowEdit={false}
                  />

                </div>
              </div>

              <div className="entity-info-cell">
                <Label>Channel-Source Status</Label>
                <div>
                  <StatusToggler
                    status={entity.status}
                    target={entity.channel.id}
                    onChangeAsync={handleStatusChange}
                    allowEdit={allowStatusToggle}
                    showArchived={false}
                  />

                </div>
              </div>
            </>

          )}

          <div className="entity-info-cell">
            <Label>Last Matched</Label>
            <div>
              {entity.last_matched_at ? (
                <RelativeTime date={entity.last_matched_at} showTimezone={true} />
              ) : <span>&mdash;</span>}
            </div>
          </div>

          <div className="entity-info-cell">
            <Label>Last Sold</Label>
            <div>
              {entity.last_sold_at ? (
                <RelativeTime date={entity.last_sold_at} showTimezone={true} />
              ) : <span>&mdash;</span>}
            </div>
          </div>


        </div>


      </div>


    </PageSection>
  );
};


export default EntityInfoBar;
