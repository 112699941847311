import React, {useState} from 'react'
import {Modal,  ModalHeader} from "reactstrap"
import {default as SourceForm} from "./index"
import {SourceTokenStep} from "./SourceTokenStep";


/*
 * Modal dialog wraps the source form for editing and creating sources
 */
type SourceModalProps = {
  source:any,
  vendorPreselections?:any[],
  isOpen:boolean,
  toggle:()=>void,
  size?:string,
  onSuccess?:(source?:any, isNew?:boolean)=>void,
  tokenStep?:boolean,
  scrollable?:boolean
}

export const SourceModal = ({
                                   source,
                                   vendorPreselections=[],
                                   isOpen,
                                   toggle,
                                   onSuccess,
                                   size='xlg',
                                   scrollable=false,
                                 }:SourceModalProps) => {

  if (!source) return null

  const [step, setStep] = useState('')
  const [newSource, setNewSource] = useState(null)
  const isNew = !source.id

  const handleSave = (source:any) => {

    if (isNew && source) {
      setStep('token');
      setNewSource(source);
    } else {

      onSuccess && onSuccess(source);
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="mst-source-modal"
      size={size}
      scrollable={scrollable}
    >

      <ModalHeader toggle={toggle}>

            {step === 'token' ? (
                <span>Add Vendor Tokens for Source</span>
              ) : (
                <>
                  {source.id
                    ? <>Edit Source | <span className="modal-subheader">{source.name}</span></>
                    : 'Create Source'
                  }
                </>
            )}


      </ModalHeader>


        {(step === 'token' && newSource)  ? (
            <SourceTokenStep
              source={newSource}
              vendors={vendorPreselections}
              onSuccess={onSuccess}
              cancel={toggle}
              isModal
            />
        ) : (
          <SourceForm
            source={source}
            cancel={toggle}
            onSuccess={handleSave}
            isModal
          />
        )}

    </Modal>
  )
}
