import React, {FC} from 'react';
import {Row, Col, UncontrolledDropdown, DropdownToggle,DropdownItem, DropdownMenu} from "reactstrap";
import {FontAwesomeIcon as FaIcon} from "@fortawesome/react-fontawesome";

/*
 * Dropdown selector for picking table columns to be shown
 *
 * (Actually we are managing hidden columns, as that is what react-table wants to know)
 */
export type ColumnSelectorDef = {
  id:string,
  label:string,
}
type ColumnSelectProps = {
  columns:ColumnSelectorDef[],
  hiddenColumns:string[],
  onChange:any,
  loading?:boolean
}

export const ColumnSelect:FC<ColumnSelectProps> = ({
  columns,
  hiddenColumns,
  onChange,
  loading
}) => {

  const handleSelect = (colId:string) => {

    if (hiddenColumns.includes(colId)) {
      onChange(hiddenColumns.filter((c:string) => c !== colId))
    } else {
      onChange([...hiddenColumns, colId])
    }

  }

  return (
    <UncontrolledDropdown className="column-select">
      <DropdownToggle color="link" size="sm" caret disabled={loading}>Columns</DropdownToggle>
      <DropdownMenu>
        {/* Toggle=false on DropdownItem to prevent menu from closing on pick */}
        {columns.map((col:ColumnSelectorDef, i:number) => (
          <DropdownItem key={i} toggle={false} onClick={() => handleSelect(col.id)}>
            <Row className="align-items-center">
              <Col sm={2}>
                {hiddenColumns.includes(col.id) ? '' : <FaIcon icon='check' />}
              </Col>
              <Col>
                {col.label}
              </Col>
            </Row>

          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}
