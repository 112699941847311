import React, {useEffect, useState} from "react";
import {
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  Button,
  FormText,
  FormFeedback, ModalHeader,
  Modal,
  ModalBody,
  ModalFooter
} from "reactstrap";
import {notify} from "@thedmsgroup/mastodon-ui-components/lib/common/Notify";
import {StandardAlert} from "@thedmsgroup/mastodon-ui-components";
import useFormState from "../../../Hooks/useFormState";
import {JsonEditorLoadable} from "@thedmsgroup/mastodon-ui-components";
import EnhancedSelect from "../../../Components/Form/EnhancedSelect";
import {InputSchemaOptionLabel} from "./InputSchemaOptionLabel";
import {InputSchemaType} from "../../../types";
import InputSchemaApi from "./InputSchemaApi";
import LoadingBar from "../../../Layout/LoadingBar";

type InputSchemaFormProps = {
  schema:InputSchemaType;
  allSchema:InputSchemaType[];
  isOpen:boolean;
  close:(refresh?:any|boolean)=>void;
  onView:(schema:InputSchemaType)=>void;
}
/*
 * Form for editing or creating an input schema
 */
const InputSchemaForm = ({schema, allSchema, onView, isOpen, close}:InputSchemaFormProps) => {
  const api = new InputSchemaApi();
  const[parentChoices, setParentChoices] = useState<any[]>([])
  const[isValidSchema, setIsValidSchema] = useState<boolean>(true)
  const[saving, setSaving] = useState(false);
  const isNew = !schema.id;
  const {
    formApi,
    formValues,
    formErrors,
    formIsValid,
  } = useFormState({
    id: schema.id,
    name:schema.name,
    description:schema.description,
    parent_id:schema.parent_id,
    content:JSON.stringify(schema.content, null, 2)
  });


  const updateParentChoices = () => {
    const choices = allSchema.filter(s => s.id !== schema.id).map(s => {
      return {value:s.id, label:s.name, description:s.description}
    });
    setParentChoices([
      {
        value:"",
        label:"None",
        description: "",
      },
      ...choices
    ]);
  }

  const handleViewParent = (id:number) => {
    const parent = allSchema.find(s => s.id === id);
    if (parent) {
      onView(parent);
    }
  }


  // Parent choices after schema choices are loaded
  useEffect(()=>{
    updateParentChoices();
  }, []);


  const editorChanged = (newCode:any, isValid:boolean) => {
    setIsValidSchema(isValid);
    formApi.setValue('content', newCode);
  }


  const handleSelectParent = (id:number) => {
    formApi.setValue("parent_id", id);
  }


  const handleCancel = () => {
    formApi.clearForm();
    setIsValidSchema(true);
    close();
  }

  const checkValid = () => {
    if (!formValues.name) {
      formApi.setError("name", "Name is required");
      return false;
    }

    if (allSchema.find((s) => s.id !== formValues.id && s.name.toLowerCase() === formValues.name.toLowerCase())) {
      formApi.setError("name", "This name is already used for another schema.");
      return false;
    }

    // Editor will do its own syntax and schema validating onChange
    if (!isValidSchema) {
      formApi.setError("content", "Invalid schema");
    }

    return true;
  }

  const handleSave = async() => {

    if (checkValid()) {
      setSaving(true);
      const params = {...formValues};
      params.content = JSON.parse(formValues.content);
      if (!params.parent_id) {
        params.parent_id = null;
      }
      let result;
      if (isNew) {
        delete params.id;
        result = await api.create(params);
      } else {
        result = await api.update(formValues.id, params);
      }

      if (result) {
        notify(`The input schema has been saved`, 'success');

        close(true)
      } else {
        //@ts-ignore
        notify(`Unable to save schema: ${api.error ? api.error.name : ""}`, 'error');
      }
      setSaving(false);

    }
  }

  return (
    <Modal isOpen={isOpen} toggle={close} size="xlg" scrollable>
      <ModalHeader toggle={close}>
        {schema.id ? "Edit Schema" : "Create New Schema"}
      </ModalHeader>
      {schema.id > 0 && <div className="modal-subheader">{schema.name}</div>}
      <ModalBody>
      <LoadingBar name="modal" active={saving} message="Saving..." />

          <div className="form-section border">

            {!formIsValid
            && (
              <StandardAlert color="warning" className="my-2 alert-form-validation">
                Please correct the errors below before continuing
              </StandardAlert>
            )}



            <Row>
              <Col sm={6}>
                <FormGroup className="flex-shrink-1">
                  <Label for="method">Name</Label>
                  <Input
                    type="text"
                    bsSize="sm"
                    name="name"
                    value={formValues.name}
                    onChange={formApi.handleOnChange}
                    invalid={!!formErrors.name}
                  />
                  <FormFeedback>{formErrors.name}</FormFeedback>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col sm={6}>
                <FormGroup className="flex-shrink-1">
                  <Label for="method">Description</Label>
                  <Input
                    type="text"
                    bsSize="sm"
                    name="description"
                    value={formValues.description}
                    onChange={formApi.handleOnChange}
                    invalid={!!formErrors.description}
                  />
                  <FormFeedback>{formErrors.name}</FormFeedback>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col sm={6}>
                <FormGroup className="flex-shrink-1">
                  <Label for="method">Extends</Label>
                  <div><FormText>Optionally set a base schema. The current schema will inherit the properites of the base definition.</FormText></div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="flex-fill">
                      <EnhancedSelect
                        value={formValues.parent_id ? formValues.parent_id.toString() : ""}
                        options={parentChoices}
                        controlShouldRenderValue
                        isMulti={false}
                        isSearchable
                        onChange={handleSelectParent}
                        placeholder="None"
                        formatOptionLabel={InputSchemaOptionLabel}
                      />
                    </div>

                    {formValues.parent_id && <Button color="link" size="xs" className="ms-2 inline" onClick={() => handleViewParent(formValues.parent_id)}>View</Button>}
                  </div>



                </FormGroup>
              </Col>
            </Row>

            {!isNew && (
              <Row>
                <Col sm={6}>
                  <FormGroup className="flex-shrink-1">
                    <Label for="method">Extended By</Label>
                    <div>
                      {schema.input_schemas.length > 0 ? (
                        <>
                          {schema.input_schemas.map((child:InputSchemaType, i:number)=>(
                            <div key={i} className="mb-1">
                              {child.name}
                              <Button color="link" size="xs" className="ms-2 inline" onClick={()=>onView(child)}>View</Button>
                            </div>
                          ))}
                        </>
                      ) : (
                        <i>none</i>
                      )}
                    </div>

                  </FormGroup>
                </Col>
              </Row>
            )}



            <Row>
              <Col>
                <JsonEditorLoadable
                  label="Schema"
                  code={formValues.content}
                  onChange={editorChanged}
                  linting={true}
                  schema={CONTENT_SCHEMA}
                  isInvalid={!isValidSchema}
                />
              </Col>
            </Row>

          </div>


      </ModalBody>
      <ModalFooter>
        <div className="d-flex align-items-center justify-content-end mb-2">

          <div>
            <Button
              color="link"
              size="sm"
              className="me-1"
              onClick={handleCancel}
              disabled={saving}
            >
              Cancel
            </Button>
            <Button
              disabled={!formIsValid || !isValidSchema || saving}
              color="primary"
              className="me-2"
              name="save"
              size="sm"
              onClick={handleSave}
            >
              Save
            </Button>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default InputSchemaForm;


const CONTENT_SCHEMA = {
  "type": "object",
  "additionalProperties": true,
  "oneOf":[
    {"$ref": "#/definitions/property_def"}
  ],
  "definitions": {
    "property_def": {
      "type":"object",
      "properties":{
        "type": {
          "type": "string"
        },
        "pattern": {
          "type": "string"
        },
        "allow_unknown": {
          "type": "boolean"
        },
        "required": {
          "type": "array",
          "items": {
            "type": "string"
          }
        },
        "enum": {
          "type": "array",
          "items": {
            "type": "string"
          }
        },
        "items": {
          "title": "items",
          "anyOf": [{
            "$ref": "#"
          }, {
            "$ref": "#/definitions/schema_array"
          }],
          "default": true
        },
        "properties": {
          "title": "properties",
          "type": "object",
          "additionalProperties": {
            "$ref": "#"
          },
          "default": {}
        },
      },
      "required": ["type"]
    },
    "schema_array": {
      "title": "schemaArray",
      "type": "array",
      "minItems": 1,
      "items": {
        "$ref": "#"
      }
    },

  }
}
