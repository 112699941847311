import React, {FC} from 'react'
import {Button} from "reactstrap"
import {FontAwesomeIcon as FaIcon} from "@fortawesome/react-fontawesome";
import classnames from "classnames"


type LaneFilterNodeProps = {
  data: {
    filters?:string[]
  },
  onAdd?:(entity:string)=>void
}

export const LaneFilterNode:FC<LaneFilterNodeProps> = ({data}) => {
  const {filters} = data
  return (
    <div className={classnames("node filters")}>

      {filters && filters.length > 0 && (
        <div><FaIcon icon="filter" size="1x" /> <b>FILTERS:</b> {filters.join(',')}</div>
      )}

    </div>
  )
}
