import {ModalBody, ModalFooter} from "reactstrap";
import React from "react";

//Utilities that can optionally wrap content in Modal sections
export const FooterWrapper = ({isModal, children, className=""}) => (
  isModal
    ? <ModalFooter className={className}>{children}</ModalFooter>
    : <div className={className}>{children}</div>
)
export const BodyWrapper = ({isModal, children, className=""}) => (
  isModal
    ? <ModalBody className={className}>{children}</ModalBody>
    : <div className={className}>{children}</div>
)
