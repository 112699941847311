import React, { useMemo, useContext, useCallback } from 'react';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import {UncontrolledTooltip} from "reactstrap";
import RulesContext from '../../Providers/RulesContext';
import { IconData } from './constants';
// import usePermission from "../../Hooks/usePermission";

/*
 * Renders a row of icons that describe various rule states. Used in rules tree.
 */
const RuleIcons = ({ rule, ruleType="targeting", iconSet = [] }) => {

  const rulesContext = useContext(RulesContext);

  if (!rule) return (null);

  const handleIconClick = useCallback((e, tab) => {
    e.stopPropagation();
    rulesContext.api.setActiveTab(tab, rule);

  }, []);

  const ruleIcons = useMemo(() => {
    const showIcons = []
    //called different things in
    //In add mode, a temporary rule is inserted and may not have summary
    if (rule.summary) {
      iconSet.forEach((iconName) => {
        if (rule.summary.includes(iconName)) {
          showIcons.push(iconName);
        }

        //One icon for sale or match caps (routing rules)
        if (iconName === 'sale_match_caps'
          && rule.summary.includes('sale_caps')
          || rule.summary.includes('match_caps')
        ) {
              showIcons.push(iconName);
        }

      })
    }
    return showIcons;
  }, [rule.summary])

  const matchIsRequired = rule.summary && rule.summary.includes('match_is_required')

  return (
    <div className="rule-tree-icons">
      {ruleIcons.map((iconName, i) => (
          <RuleIcon
            key={i}
            rule={rule}
            ruleType={ruleType}
            name={iconName}
            show={true}
            iconData={IconData[iconName]}
            matchIsRequired={matchIsRequired}
            onClick={handleIconClick}
          />
      ))}

    </div>
  );
};

/*
 * Icon - shows the icon or null based on rule property
 * (When rule has match conditions and match_is_required, show a special composite icon)
 *
 */
const RuleIcon = ({
  rule, ruleType, name, show, iconData, matchIsRequired, onClick,
}) => (iconData && show ? (
  <>

    {name === "match_conditions" && matchIsRequired ? (
      <>
        <span
          className="fa-layers fa-fw"
          onClick={(e) => onClick(e, iconData.tab)}
          id={`tree-icon-${name}-${rule.id}`}
        >
          <FaIcon icon={iconData.icon} size="sm" className={name} />
          <FaIcon icon={"circle"}  size="sm" transform="right-8 up-5" color="white" />
          <FaIcon icon={"exclamation-circle"}  size="sm" transform="right-8 up-5" color="red" />
        </span>
        <UncontrolledTooltip target={`tree-icon-${name}-${rule.id}`}>
          Match Conditions (match is required for bid)
        </UncontrolledTooltip>
      </>

    ) : (
      <>
        <FaIcon
          id={`tree-icon-${name}-${rule.id}`}
          icon={iconData.icon}
          size="sm"
          className={name}
          onClick={(e) => onClick(e, (
            ruleType === 'routing' && iconData.routing_tab ? iconData.routing_tab : iconData.tab
          ))}
        />
        <UncontrolledTooltip target={`tree-icon-${name}-${rule.id}`}>
          {iconData.title}
        </UncontrolledTooltip>
      </>

    )}

  </>

) : (null)
);

RuleIcons.propTypes = {
  rule: PropTypes.object,
  product: PropTypes.string,
  iconSet: PropTypes.array,
};

export default RuleIcons;
