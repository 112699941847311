import React, {  useState, useEffect } from 'react';
import classnames from 'classnames';
import {Spinner} from "reactstrap";
import {Filter} from "../../../Components/Table/Filter";
import {ProductChoices} from "../../../Components/Table/FilterChoices";
import useApi from "../../../Hooks/useApi";
import {SourceOptionLabel} from "../../../Components/Form/Select/SourceOptionLabel";
import {ChannelOptionLabel} from "../../../Components/Form/Select/ChannelOptionLabel";

const TargetChoices = [
  { value: 'source', label: 'Source' },
  { value: 'channel', label: 'Channel' },
  { value: 'vendor', label: 'Vendor' },
  { value: 'order', label: 'Order' },
];

const Filters = ({filters, loading, onChange}) => {

  const { api } = useApi();
  const [loadingOrders, setLoadingOrders] = useState(false);
  const [choices, setChoices] = useState({});
  const targetFromFilters = () => {
    if (filters.account) {
      return "order"
    } else if (filters.channel) {
      return "channel"
    } else if (filters.vendor) {
      return "vendor"
    }
    return "source";
  }
  const [targetObject, setTargetObject] = useState(targetFromFilters());


  // onLoad, get choices
  useEffect(() => {

      const getChoices = async()=>{

        const requests = [
          {endpoint:'verticals',action:'list'},
          {endpoint:'vendors',action:'list'},
          {endpoint:'channels',action:'list', params:{options:true} },
          {endpoint:'accounts',action:'options'},
          {endpoint:'sources',action:'list', params:{options:true}}
        ];
        if (filters.account) {
          requests.push({endpoint:'orders',action:'list', params:{account_id:filters.account}})
        }
        const batches =  await api.batchGet(requests)
        if(batches && batches.length){
          const[verticals, vendors, channels, accounts, sources, orders] = batches;
          setChoices({
            target: TargetChoices,
            product: [{label:"Any", value:""}, ...ProductChoices],
            source: sources.map((s )=> {
              return {
                value:s.id,
                label:s.name,
                status:s.status,
                vertical:s.vertical,
                product:s.product
              }
            }),
            channel: channels.map( item => { return {value:item.id, label:item.name}}),
            vertical: [{label:"Any", value:""}, ...verticals.map( item => { return {value:item.id, label:item.display_name}})],
            vendor: vendors.map( item => { return {value:item.id, label:item.name}}),
            account: accounts.map( item => { return {value:item.id, label:item.name}}),
            order: orders ? orders.map( item => { return {value:item.id, label:item.name}}) : []
          })
        }
      };

      if(Object.keys(choices).length === 0) getChoices();

  }, []);

  // Adjust the target if parent changes the filters (example: back button)
  useEffect(() => {
    const target = targetFromFilters();
    if (target !== targetObject) {
      if (filters.account) {
        getAccountOrders(filters.account);
      }
      setTargetObject(target);
    }
  }, [filters])


  const handleChangeFilter = (param, value) => {
   // console.log("Filters.js:handleChangeFilter", param, value, typeof value );
    // target type vendor: can be combined with product and vertical filters
    // target type order: requires account and order filter
    // target type source and channel: no other filters
    let newFilters;
    const vendorFilterGroup = ['vendor','product', 'vertical']
    const orderFilterGroup = ['order','account']
    if (value) {
      if ((targetObject === "vendor" && vendorFilterGroup.includes(param))
        || (targetObject === "order" && orderFilterGroup.includes(param))
      ) {
        //target with multiple filters, allow based on targetObject
        newFilters = Object.entries(filters).reduce((acc, [key,val]) => {
          if (targetObject === "vendor" && vendorFilterGroup.includes(key) ) {
            acc[key] = val
          }else if (targetObject === "order" && orderFilterGroup.includes(key) ) {
            acc[key] = val
          }
          return acc
        }, {})
        newFilters[param] = value


      } else {
        newFilters = { [param]: value }
      }
      onChange(newFilters);
      if (param === 'account') {
        getAccountOrders(value);
      }
    } else {
      newFilters = { ...filters }
      delete newFilters[param];
      onChange(newFilters);
    }
  }

  // target choice is not part of URL params
  const handleChangeTarget = (param, target) => {
    setTargetObject(target);
  }

  //TODO wire up
  const handleClearFilters = () => {
    onChange({});
  }

  const getAccountOrders = async(account_id) => {
    if (account_id) {
      setLoadingOrders(true);
      const orders = await api.endpoints.orders.list({account_id});
      if (orders) {
        setChoices(prev => {
          return {...prev, order: orders.map((o)=> {
              return {value:o.id, label:o.name}
            })}
        })

        if (orders.length === 1 && !filters.order) {
          handleChangeFilter('order', orders[0].id)
        }
      }
      setLoadingOrders(false);
    }

  }

  return (
    <div className="d-flex position-relative">
      <div className={classnames(['loading-overlay', { show: loading }])} />
      <div className="d-flex">


        <div style={{width:'150px'}}>
          <Filter
            label="Map from"
            placeholder="Target"
            param="target"
            options={choices.target ?? []}
            value={targetObject}
            onChange={handleChangeTarget}
            isMulti={false}
            isClearable={false}
          />
        </div>

        {targetObject === "source" && (
          <div style={{width:'400px'}} className="min-250">
            <Filter
              label="&nbsp;"
              placeholder="Select source"
              param="source"
              options={choices.source ?? []}
              value={filters?.source || ""}
              onChange={handleChangeFilter}
              //getOptionValue={(item)=>item.id.toString()}
              isMulti={false}
              isClearable={false}
              formatOptionLabel={SourceOptionLabel}
            />
          </div>
        )}
        {targetObject === "channel" && (
          <div style={{width:'400px'}}  className="min-250">
            <Filter
              label="&nbsp;"
              placeholder="Select channel"
              param="channel"
              options={choices.channel ?? []}
              value={filters?.channel || ''}
              onChange={handleChangeFilter}
              isMulti={false}
              isClearable={false}
              formatOptionLabel={ChannelOptionLabel}
            />
          </div>
        )}
        {targetObject === "vendor" && (
          <>
            <div  style={{width:'300px'}} >
              <Filter
                label="&nbsp;"
                placeholder="Select vendor"
                param="vendor"
                options={choices.vendor ?? []}
                value={filters?.vendor || ''}
                onChange={handleChangeFilter}
                isMulti={false}
                isClearable={false}
                formatOptionLabel={ChannelOptionLabel}
              />
            </div>
            <div style={{width:'180px'}}>
              <Filter
                label="Product"
                placeholder="Any"
                param="product"
                options={choices.product || []}
                value={filters?.product || ""}
                onChange={handleChangeFilter}
                isMulti={false}
                isClearable={true}
              />
            </div>
            <div  style={{width:'220px'}}>
              <Filter
                label="Vertical"
                placeholder="Any"
                param="vertical"
                options={choices.vertical ?? []}
                value={filters.vertical || ""}
                onChange={handleChangeFilter}
                isMulti={false}
              />
            </div>
          </>


        )}

        {targetObject === "order" && (
          <>
            <div style={{width:'300px'}} className="min-250">
              <Filter
                label="Account"
                placeholder="Select account"
                param="account"
                options={choices.account ?? []}
                value={filters?.account || []}
                onChange={handleChangeFilter}
                isMulti={false}
                isClearable={false}
              />
            </div>


              <div style={{width:'300px'}} className="min-250">

                <Filter
                  label="Select order"
                  placeholder={filters.account ?  (choices.order?.length > 0 ? "Select an order" : "No orders found") : "Select an account"}
                  param="order"
                  options={choices.order ?? []}
                  value={filters?.order || []}
                  onChange={handleChangeFilter}
                  isMulti={false}
                  isClearable={false}
                  loading={loadingOrders}
                />

              </div>


          </>

        )}


{/*
        <div className="min-250">
          <Filter
            placeholder="Vertical"
            param="vertical.name"
            options={choices.vertical ?? []}
            value={filters['vertical.name'] || []}
            onChange={handleChangeFilter}
            isMulti={false}
          />
        </div>

        <div className="min-250">
          <Filter
            placeholder="Product"
            param="product"
            options={choices.product ?? []}
            value={filters['product'] || []}
            onChange={handleChangeFilter}
            isMulti={false}
          />
        </div>*/}

      </div>

    </div>
  );
};

export default Filters;
