import React, {useState} from 'react'
import {Button, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap"
import {FontAwesomeIcon as FaIcon} from "@fortawesome/react-fontawesome"
import {ChannelSources} from "../../Channels/Channel/ChannelSources"
import useApi from "../../../../Hooks/useApi"
import DynamicEnhancedSelect from "../../../../Components/Form/DynamicEnhancedSelect"
import {ChannelOptionLabel} from "../../../../Components/Form/Select/ChannelOptionLabel"
import {notify} from "@thedmsgroup/mastodon-ui-components";
import {ChannelSource} from "../../../../types";
import LoadingBar from "../../../../Layout/LoadingBar";
import EntityId from "../../../../Components/Table/EntityId";

/*
 * Modal dialog that lets you link the provided source to a selected channel,
 * via the channel-source connection (the link is saved by updating the channel)
 */
type LinkChannelSourcePanelProps = {
  source:any,
  isOpen:boolean,
  close:()=>void,
  size?:string,
  onSuccess?:(source?:any)=>void,
  scrollable?:boolean
}

export const LinkChannelSourceModal = ({
                                   source,
                                   isOpen,
                                   close,
                                   onSuccess,
                                   size='lg',
                                   scrollable=false,
                                 }:LinkChannelSourcePanelProps) => {

  if (!source) return null
  const {api} = useApi()
  const [selectedChannelId, setSelectedChannelId] = useState<string>('')
  const [channel, setChannel] = useState<any>()
  const [channelSources, setChannelSources] = useState<ChannelSource[]>([])
  const [isLoading, setIsLoading] = useState(false);


  const save = async() => {
    setIsLoading(true)

    const payload = {
      id:channel.id,
      name:channel.name,
      account_id: channel.account.id,
      vertical_id: channel.vertical.id,
      product:channel.product,
      sources: channelSources.map((src:any) => {
        return {
          source_id: src.id,
          channel_id: channel.id,
          status: src.eligibility.status,
          distribution: src.eligibility.distribution,
          fixed_distribution: src.eligibility.fixed_distribution,
        }
      })
    }


    const result = await api.endpoints.channels.update(payload)


    if (result) {
      notify(`The channel has been saved`, 'success')
      setIsLoading(false)
      onSuccess && onSuccess()
      close()
    } else {
      notify(`Unable to save channel: ${api.error ? api.error.name : ""}`, 'error');
    }
    setIsLoading(false)
  }

  const handleSelectChannel = (channelId:string) => {

    setSelectedChannelId(channelId);
    if (channelId) {
      loadChannel(channelId)
    } else {
      setSelectedChannelId('')
    }
  }

  const unselectChannel = () => {
    setSelectedChannelId('')
    setChannel(undefined)
    setChannelSources([])
  }


  const loadChannel = async(channelId:string) => {
    const result = await api.endpoints.channels.show(channelId);
    if(result){
      setChannel(result)
      setChannelSources(() => {
        const sources = result.sources
        if (!sources.find((s:any) => s.id.toString() === source.id)) {

          sources.push({
            id: source.id,
            name: source.name,
            status:source.status,
            eligibility: {
              status:"active",
              distribution:null,
              fixed_distribution:false,
              rule_id:0,
            }
          });
        }
        return sources
      })
    }
  }


  return (
    <Modal
      isOpen={isOpen}
      toggle={close}
      size={size}
      scrollable={scrollable}
    >

      <ModalHeader toggle={close} >
         Create Channel-Source Link
      </ModalHeader>
      <ModalBody style={{minHeight:'400px'}}>
        <LoadingBar name="modal" active={isLoading} />
        {source.id > 0 && !selectedChannelId && <h5>Source: <EntityId id={source.id}/> {source.name}</h5>}
        {!channel ? (
          <div>
            <p>Select a channel that will be eligible to use this source.</p>
            <FormGroup>
              {/*<Label>Select a Channel</Label>*/}
              <DynamicEnhancedSelect
                onChange={handleSelectChannel}
                value={selectedChannelId ?? ''}
                name="channel"
                endpoint="channels"
                params={{options:true, product:source.product, vertical:source.vertical.id}}
                labelProperty="name"
                className="w-100"
                placeholder="Select channel..."
                formatOptionLabel={ChannelOptionLabel}
                //invalid={!!formErrors.account_id}
                // invalidFeedback={formErrors.account_id}
                isClearable
                isSearchable
              />
            </FormGroup>
          </div>
        ) : (
          <>
            <div className="d-flex mb-2">
              <h5 className="mb-0">Channel: {channel.name}</h5>
              <Button color="link" size="xs" className="inline ms-2" onClick={unselectChannel} >change</Button>
            </div>

            <p>The source will be added to the selected channel. All of the channel&apos;s eligible sources
              {' '}are listed below. Click save to complete.</p>
            <ChannelSources
              channel={channel}
              sources={channelSources}
              onChange={setChannelSources}
              onOpenRules={() => {}}
            />

          </>

        )}

      </ModalBody>

      <ModalFooter>

          <div className="text-end">
            <Button
              onClick={close}
              color="link"
              size="sm"
            >
              Cancel
            </Button>

              <Button
                onClick={save}
                color="primary"
                size="sm"
                disabled={!channel}
              >
                Save
              </Button>
          </div>

      </ModalFooter>


    </Modal>
  )
}
